import React, { Component } from 'react';
import TitleContainer from '../../shared/components/titleContainer';
import { connect } from 'react-redux';
import * as wizardAction from '../../store/actions/index';
import * as layoutActions from '../../store/actions/index';
import * as historyAction from '../../store/actions/index';
import { urls, getText } from '../../shared/utility';
import Files from '../../shared/components/files';
import Loader from '../../shared/components/loader';
import { ThemeProvider } from 'react-bootstrap';

class UploadFiles extends Component {

    state = {
        attachReferenceToReportText: '',
        permittedFilesText: '',
        files: [],
        isFromHistory: null,
        isFutureReport: false,
        reportDate: null,
    }

    componentDidMount() {

        this.loadText()
        this.props.onShowWizardHeader();
        const isFromHistory = this.props.match.params.param === 'history'
        const location = this.props.location;
        if(location && location.state && location.state.reportDate)
            this.setState({
                isFutureReport: true,
                reportDate: location.state.reportDate
            })
        if (!isFromHistory) {
            this.initFilesFromStore();
        }
        else {
            const fileNames = ['fileName1', 'fileName2'];

            this.initHistoryFiles(fileNames)
        }
        this.setState({
            isFromHistory
        })
    }

    loadText = () => {
        this.setState({
            attachReferenceToReportText: getText(this.props.contentManagment.uploadFile, 'AttachReferenceToReport', this.props.gender),
            permittedFilesText: getText(this.props.contentManagment.uploadFile, 'permittedFiles', this.props.gender)
        })
    }

    initHistoryFiles = (fileNames) => {
        const files = [];


        fileNames.forEach(prop => {
            if (this.props.dateToUploadFiles[prop]) {
                files.push({ name: this.props.dateToUploadFiles[prop], cantRemove: true })
            }
        })

        if (this.props && this.props.filesToUpload) {
            this.props.filesToUpload.forEach((file, i) => {
                if (file && file.name && !(files.filter(e => e.name === file.name).length > 0)) {
                    files.push({ name: file && file.name })
                }
            })
        }

        const output = [...new Map(files.map(o => [o.name, o])).values()]

        this.setState({ files: output })
    }

    initFilesFromStore = () => {

        if (this.props.files && this.props.files.length) {
            this.setState({
                files: this.props.files
            })
        }
    }

    findNameOfFile = (isFromHistory) => {
        let fileName = null
        const a = ['fileName1', 'fileName2'];
        a.forEach((entry) => {
            if (this.props.dateToUploadFiles[entry] === isFromHistory) {
                fileName = entry
            }
        });

        return fileName;
    }

    updateFiles = (files, isFromHistory) => {

        let findName
        if (isFromHistory) {
            findName = this.findNameOfFile(isFromHistory)
            this.props.dateToUploadFiles[findName] = null
            this.props.onSetDateToUploadFiles(this.props.dateToUploadFiles)
        }

        this.setState({
            files: files
        })
    }

    
    uploadFiles = () => {
        // loader == true
        this.setState({
            isLoading: true
        })
        const files = this.state.files.filter(f => f.name);

        if (files.length || this.state.isFromHistory) {
            // if(files.length>0){
            //     this.props.onStoreFilesHistory(files);
            // }
            if (files.length === 0 && this.state.files === 0) {
                this.props.history.push(urls.historyPrat);
            }
            else if (this.state.isFromHistory) {
                this.props.onStoreFilesHistory(files);
                this.props.history.push(`${urls.updateDates}/history`);
            }
            else {
                this.props.onUploadFiles(files);
                if (this.state.isFutureReport)
                    this.props.history.push({
                        pathname: urls.updateDates,
                        state: { reportDate: this.state.reportDate } 
                    });
                else
                    this.props.history.push(urls.updateDates);
            }
        }
        if (!files.length && !this.state.isFromHistory) {
            this.props.onSaveSelection(this.props.fullSelection)
                .then(() => {
                    if(this.props.isSaveSelection && this.props.isEmergency)
                        this.props.history.push(urls.finish);
                    else if(this.state.isFutureReport)
                        this.props.history.push({
                            pathname: urls.futureReport,
                            state: { reportDate: this.state.reportDate } 
                        });
                    else
                        this.props.history.push(urls.hp);
                });
        }



    }

    render() {
        if (this.state.isLoading) {
            return <Loader />
        }
        let pageClass = "pageContainer upload uploadContainer";
        let uploadClass = "uploadContent";

        if (this.state.files.length) {
            uploadClass += ' hasFile';
            pageClass = pageClass.replace('uploadContainer', '');
        }

        return (
            <div className={pageClass}>
                <TitleContainer
                    iconPath={'img/uploadFiles.png'}
                    mainTitle={'העלאת מסמכים'}
                    subTitle={this.state.attachReferenceToReportText}
                    isShadow={this.state.files.length} />
                <div className={uploadClass}>
                    <Files
                        dateToUploadFiles={this.props.dateToUploadFiles}
                        isFromHistory={this.state.isFromHistory}
                        files={this.state.files}
                        cantUploadMoreFiles={this.state.isFromHistory ? (this.props.dateToUploadFiles && !this.props.dateToUploadFiles.canUploadMoreFiles) : !this.props.dateToUploadFilesWizard}
                        updateFiles={(files, isFromHistory) => this.updateFiles(files, isFromHistory)} />
                    <div className="fileError bold">{this.state.permittedFilesText}</div>
                </div>
                <div className="btnSendReportContainer">
                    <button type="button" className="btnGeneral send" onClick={this.uploadFiles}>
                        {(this.state.files.length || this.state.isFromHistory) ? 'המשך' : 'שליחת דיווח'}
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        filesToUpload: state.history.filesToUpload,
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        files: state.wizard.fullSelection.files,
        dateToUploadFilesWizard: state.wizard.canUploadMoreFiles,
        dateToUploadFiles: state.history.dateToUploadFiles,
        fullSelection: state.wizard.fullSelection,
        isSaveSelection: state.wizard.isSaveSelection
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onUploadFiles: (files) => dispatch(wizardAction.uploadFiles(files)),
        onStoreFilesHistory: (files) => dispatch(historyAction.storeFilesHistory(files)),
        onSaveSelection: (selection) => dispatch(wizardAction.storeSelection(selection)),
        onSetDateToUploadFiles: (user) => dispatch(historyAction.setDateToUploadFiles(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles);