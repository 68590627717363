import React, { Component } from 'react'
import { sortUsersByName } from '../../shared/utilityCommander'

class UserListCount extends Component {




    componentDidUpdate(prevProps) {
        if (this.props.users !== prevProps.users) {
            sortUsersByName(this.props.users)
        }
    }

    render() {
        const itemClass = this.props.page === 'history' ? 'item pointer' : 'item'
        const userList = this.props.users && this.props.users.map((user, index) => {
            return (
                <li className={itemClass} key={index} onClick={() => this.props.openHistoryModal(user)}>
                    <div className="index">{index + 1}</div>
                    <div className="name">
                        <span className="text">{user.firstName}</span>
                        <span className="text">{user.lastName}</span>
                    </div>
                    <div className="status">
                        <span className="text">{user.approvedMainName}</span>
                        <span className="text">{user.approvedSecondaryName}</span>
                    </div>
                </li>
            )
        })

        return (
            <ul className="userList">
                {userList}
            </ul>
        )
    }
}
export default UserListCount;