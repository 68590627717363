import React, { Component } from 'react';
import BtnsReport from './btnsReport';
import { stringDownloadFileChange } from '../../shared/utility';

class UsersGroup extends Component {

    toggleRowClick = (userMi, index) => {
        this.props.toggleRow(userMi)
        setTimeout(() => {
            this.collapseRow(this.refs['row' + index], this.refs['viewMore' + index]);
            this.scrollToView(this.refs['row' + index]);
        })
    }

    collapseRow = (element, viewMore) => {

        if (!element.classList.contains('commander__reports-row_error') &&
         !element.classList.contains('showMore')) {
            if (element.classList.contains('commander__reports-row_active')) {

                const children = viewMore.children
                let totalHeight = 0;

                for (let i = 0; i < children.length; i++) {
                    totalHeight += children[i].offsetHeight
                }
                viewMore.style.height = totalHeight + 'px'
            } else {
                viewMore.style.height = 0
            }
        }
    }


    scrollToView = (element) => {
        let ElPosition = element.getBoundingClientRect().top;
        let startPosition = window.pageYOffset;
        let ElHeight = element.offsetHeight;
        if (ElPosition > (window.innerHeight / 2)) {
            window.scrollTo({
                top: startPosition + ElHeight,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    onReportBtnClick = (user, reportType, index) => {
        if ((reportType === 'reported' && (user.reportedMainCode === null || user.reportedSecondaryCode === null))
            || (reportType === 'recommanded' && (user.recommandedMainCode === null || user.recommandedSecondaryCode === null))) return

        let updateUser = null;

        this.props.usersGroup.users.forEach((element) => {
            if (element.mi === user.mi) {

                if (reportType === 'reported') {
                    updateUser = {
                        ...element,
                        approvedMainCode: user.reportedMainCode,
                        approvedMainName: user.reportedMainName,
                        approvedSecondaryCode: user.reportedSecondaryCode,
                        approvedSecondaryName: user.reportedSecondaryName,
                        notReported: false,
                        reportedConflict: false,
                        isActiveRow: false,
                        editedAndNotSent: true,
                        groupCode: this.props.activeTabCode
                    }
                }
                if (reportType === 'recommanded') {
                    updateUser = {
                        ...element,
                        approvedMainCode: user.recommandedMainCode,
                        approvedMainName: user.recommandedMainName,
                        approvedSecondaryCode: user.recommandedSecondaryCode,
                        approvedSecondaryName: user.recommandedSecondaryName,
                        notReported: false,
                        reportedConflict: false,
                        isActiveRow: false,
                        editedAndNotSent: true,
                        groupCode: this.props.activeTabCode
                    }
                }
                this.props.updateOtherStatus(updateUser)
                if (user.isActiveRow) {
                    this.toggleRowClick(user.mi, index)
                }
                return;
            }
        });

    }

    renderFileRow = (fileId, fileName, canDownloadFile, isCommanderState) => {
        return (
            <div className="solger__text-row">
                <button
                    type="button"
                    className={`downloadFileBtn ${!canDownloadFile && ' disabled'}`}
                    onClick={() => canDownloadFile && this.props.downloadFile(fileId,fileName)}
                >
                    <img className="solger__action-icon" src={require('../../../src/assets/img/attach.png')} alt="צרף מסמך" />
                    <span className="fileName">{stringDownloadFileChange(fileName, canDownloadFile , 14, isCommanderState)}</span>
                </button>
            </div>
        )
    }

    render() {
        let userList = ''
        let userRowClasses = ''
        let userStatusTxt = ''
        let userStatusTxtError = ''
        let userStatusConflictIcon = ''
        let userStatusNotReportedIcon = ''
        let errorText = ''
        let emergencyReport = ''

        if (this.props.usersGroup) {
            let canActivateEmergencyPractice = this.props.canActivateEmergencyPractice;
            let isEmergencyActive= this.props.isEmergencyActive;
            let emergencyPracticeActive = this.props.isEmergencyPractice && canActivateEmergencyPractice;
            let showEmergencyReport = this.props.showEmergencyReport || emergencyPracticeActive;
            let emergencyReportClass = 'emergency-status';

            userList = this.props.usersGroup.users.map((user, index) => {
                userList = ''
                userRowClasses = 'commander__reports-row'
                userStatusTxt = 'solger__status-txt-wrap'
                userStatusTxtError = 'solger__status-txt-error display-none'
                userStatusConflictIcon = 'incompatible-reporting-icon display-none'
                userStatusNotReportedIcon = 'no-report-icon display-none'
                errorText = ''
                if (!this.props.usersGroup.isGroupDetermined &&
                    user.approvedMainCode === null &&
                    user.approvedSecondaryCode === null) {
                    if (user.notReported) {
                        userRowClasses += ' commander__reports-row_error'
                        userStatusTxt += ' display-none'
                        userStatusTxtError = 'solger__status-txt-error'
                        userStatusConflictIcon = 'incompatible-reporting-icon display-none'
                        userStatusNotReportedIcon = 'no-report-icon'
                        errorText = 'ללא דיווח'

                    } else if (user.reportedConflict) {
                        userRowClasses += ' commander__reports-row_error'
                        userStatusTxt = ' display-none'
                        userStatusTxtError = 'solger__status-txt-error'
                        // userStatusConflictIcon = 'incompatible-reporting-icon'
                        // userStatusNotReportedIcon = 'no-report-icon display-none'
                        // errorText = 'דיווח לא תואם'
                    }
                }
                let showMore = user.approvedMainCode || user.mainStatusDeterminedCode

                if(!showMore){
                    userRowClasses += ' showMore'
                }
                
                if (user.isActiveRow) {
                    userRowClasses += ' commander__reports-row_active'
                } else {
                    userRowClasses = userRowClasses.replace('commander__reports-row_active', '').slice();
                }
               
                if((canActivateEmergencyPractice && isEmergencyActive) || isEmergencyActive || canActivateEmergencyPractice && emergencyPracticeActive){
                    if(user.emergencyMainCode && user.emergencySecondaryCode) {
                        emergencyReport = user.emergencyMainName +" " + user.emergencySecondaryName +", "+ user.reportTimeUpdate
                    } else {
                        emergencyReport = 'לא קיים דיווח חירום'
                    }

                    if (emergencyPracticeActive)
                        emergencyReportClass += ' practice'
                }

                return (
                    <li className={userRowClasses} key={user.mi} ref={'row' + index}>
                        <div className="solger">
                            <div className="solger__status">
                                <button onClick={() => this.props.toggleFavorite(this.props.group.groupCode, user.mi)}>
                                    <i className={user.favorite ? 'star-yellow-icon': 'star-white-icon'}></i>
                                </button>
                                {user.createdToday && <div className="solger__status-new">חדש</div>}
                            </div>
                            <button className={'solger__inform ' + index} onClick={() => this.toggleRowClick(user.mi, index)}>
                                <div className="solger__name">
                                    <span>{user.firstName}</span>
                                    <span>{user.lastName}</span>
                                </div>
                                <div className="solger__status-txt">
                                    <div className={userStatusTxtError}>
                                        <i className={userStatusNotReportedIcon}></i>
                                        <i className={userStatusConflictIcon}></i>
                                        <span>{errorText}</span>
                                    </div>
                                    <p className={userStatusTxt}>
                                        <span>{user.mainStatusDeterminedName || user.approvedMainName}</span>
                                        <span>{user.secondaryStatusDeterminedName || user.approvedSecondaryName}</span>
                                    </p>

                                </div>
                                <div className="solger__action-icons">
                                    {(user.fileName1 || user.fileName2) && <img className="solger__action-icon" src={require('../../../src/assets/img/attach.png')} alt="צרף מסמך" />}
                                    {user.note && <img className="solger__action-icon" src={require('../../../src/assets/img/text.png')} alt="שלח הודעה" />}
                                </div>
                                <div className={emergencyReportClass}>
                                        <span>{emergencyReport}</span>
                                    </div>
                            </button>
                        </div>
                 
                        <div className="solger__view-more" ref={'viewMore' + index}>
                            <BtnsReport
                                mainScreenCommanderManagment={this.props.mainScreenCommanderManagment}
                                gender={this.props.gender}
                                user={user}
                                group={this.props.group}
                                isValidTimeToReport={this.props.isValidTimeToReport}
                                isCantReport={this.props.isCantReport}
                                isGroupDetermined={this.props.usersGroup.isGroupDetermined}
                                onReportBtnClick={(user, reportType) => this.onReportBtnClick(user, reportType, index)}
                                goToOtherStatus={(user) => this.props.goToOtherStatus(user)}
                            />
                            <div className="moreOption">
                                {

                                    user.note &&
                                    <div className="solger__text-row">
                                        <img className="solger__action-icon" src={require('../../../src/assets/img/text.png')} alt="הודעה" />
                                        <span>{`   ${user.note}    `} 
                                        {/* !this.props.group.showOnly || this.props.reportedData && !this.props.reportedData.mainStatusReported  */}
                                        {/* {console.log("finalMainCode:" ,  this.props.reportedData.finalMainCode ,"finalSecondaryCode:" ,this.props.reportedData.finalSecondaryCode)} */}
                                            { ( !((this.props && this.props.reportedData && this.props.reportedData.finalMainCode) &&  (this.props && this.props.reportedData && this.props.reportedData.finalSecondaryCode) ) && !this.props.group.showOnly )                        
                                                 && <button type="button" className="solger__text-link" onClick={() => this.props.openNoteModal(user)}>   עריכה   </button>}
                                        </span> 
                                    </div>
                                }

                                {user.fileID1 && this.renderFileRow(user.fileID1, user.fileName1, user.canDownloadFile1, user.isCommanderState)}
                                {user.fileID2 && this.renderFileRow(user.fileID2, user.fileName2, user.canDownloadFile2, user.isCommanderState)}

                                <div className="solger__icon-status-row">
                                    <button className="solger__icon-btn" disabled={user.userRemovedFromGroup} onClick={() => this.props.openRemoveUserModal(user, +this.props.group.groupCode)}>
                                        <i className={`solger__icon-btn_position ${user.userRemovedFromGroup ? 'removeIcon active' : 'removeIcon'}`}></i>
                                    </button>
                                    <button className="solger__icon-btn" onClick={() => this.props.goToCalendar(user)}>
                                        <i className="solger__icon-btn_position time-icon"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                )
            })
            return userList
        }
        else return null
    }
}


export default UsersGroup;
