import React, { Component, Fragment } from 'react';
import { collapseRow } from '../../shared/utilityCommander';

class emergencyReportsSummary extends Component{

    clickRow = (index, usersLength) => {
        if (usersLength) {
            collapseRow(this.refs['tr' + index])
        }
    }

    render() {
        let trClass = 'trRow';
        let hovaTotal = 0;
        let kevaTotal = 0;
        let tableBody;
        if (this.props.statistic.sectionsStatistics) {
            tableBody = this.props.statistic.sectionsStatistics.map((item, index) => {
                if(item.type !== 'membersOutBase' && item.type !== 'membersInBase'){
                    let hovaSum = 0;
                    let kevaSum = 0;
                    let title = 'נוכחים ביחידה'
                    item.membersStatistics.forEach(user => {
                        if (user.populationType === 'Hova') {
                            hovaSum++;
                            hovaTotal++;
                        }
                        if (user.populationType === 'Keva') {
                            kevaSum++;
                            kevaTotal++;
                        }
                    })

                    if (item.membersStatistics.length) trClass += ' pointer';
                    
                    if (item.type === 'membersWithoutReport') title = 'פרט ללא דיווח';
                    else{
                        title = item.type;
                    }

                    return (
                        <Fragment key={index}>
                            <tr className={trClass} onClick={() => this.clickRow(index, item.membersStatistics.length)} ref={`tr${index}`}>
                                <td className="col-4">{title}</td>
                                <td className="col-2">{hovaSum}</td>
                                <td className="col-2">{kevaSum}</td>
                                <td className="col-2">{item.membersStatistics.length}</td>
                                <td className="col-2 arrow"></td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    <div className="collapseList">
                                        <ul className="statisticUsersList">
                                            {
                                                item.membersStatistics.map((user, index) => {
                                                    return (
                                                        <li className="item" key={index}>{`${user.firstName} ${user.lastName}`}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </td>

                            </tr>
                        </Fragment>

                    )
                } 
            })
        }

        return (
            <>
                <div className="badgeNoteContainer">
                    {this.props.group.isSubmittedToday &&
                        <span className="badgeNote">
                            <img src="img/v.png" alt="" />
                            <span className="text">הדיווח הושלם</span>
                        </span>}
                </div>
                <table className="statisticTable" cellPadding="0" cellSpacing="0" id="table">
                    <thead>
                        <tr>
                            <th className="col-4"></th>
                            <th className="col-2">חובה</th>
                            <th className="col-2">קבע</th>
                            <th className="col-2">סה"כ</th>
                            <th className="col-2"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody}
                        <tr>
                            <td className="col-4">סה"כ חיילים בקבוצה</td>
                            <td className="col-2">{hovaTotal}</td>
                            <td className="col-2">{kevaTotal}</td>
                            <td className="col-2">{hovaTotal + kevaTotal}</td>
                            <td className="col-2"></td>
                        </tr>
                    </tbody>
                </table>

            </>
        )
    }
}

export default emergencyReportsSummary;