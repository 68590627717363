import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';



class WarningModal extends Component {

    state = {
        isModalShow: false
    }

    handleModalClose = () => {
        this.setState({
            isModalShow: false
        })
    }

    handleModalShow = () => {
        this.setState({
            isModalShow: true
        })
    }


    render() {
         let notificationsList = this.props.notifications.map((notification,index) => {
            return (
                <li key={index}>
                    <div className="text">{notification}</div>
                </li>
            )
        })

        return (
            <Modal className="mainModal" show={this.state.isModalShow} onHide={this.handleModalClose} centered>
                <div className="warningBox">
                    <img className="warningIcon" src="./img/warningIcon.png" alt="" />
                    <div className="title">שים לב</div>
                    <ul className="notificationsList">
                        {notificationsList}
                    </ul>
                    <button type="button" className="btnGeneral send" onClick={this.handleModalClose}>הבנתי</button>
                </div>
            </Modal>
        )
    }

}

export default WarningModal;