import React, { Component } from 'react'
import { connect } from 'react-redux'
import { urls } from '../../shared/utility';
import * as commanderActions from '../../store/actions/index';
import * as layoutActions from '../../store/actions/index';

class SendSuccsess extends Component {

    componentDidMount(){
        this.props.onHideWizardHeader();
    }

    redirectToCommanderPage = () => {
        this.props.onFetchCommander(this.props.groupCode)
            .then(() => {
                this.props.onSetActiveTab(this.props.selectionGroup.groupCode);
                if (this.props.filterList.length) {
                    this.props.onFilterUsers();
                }
            })
        //this.props.history.push(urls.commander)
        this.props.history.push({
            pathname: urls.commander,
            state: { prevUrl: null} 
          })
    }

    render() {
        return (
            <div className="commanerFinish">
                <div className="icon">
                    {/* <img src="img/sendSuccess.png" alt="" /> */}
                </div>
                <div className="text">
                    דיווח הנוכחות ל<span>{this.props.selectionGroup.groupName}</span><br />
                    נשלחו בהצלחה
                </div>
                <div className="btnSendReportContainer yellowBG">
                    <button type="button" onClick={this.redirectToCommanderPage} className="btnGeneral send">המשך</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectionGroup: state.commander.selectionGroup,
        filterList: state.commander.filterList,
        groupCode: state.commander.activeTabCode,

    }
}

const mapDispatchToProps = dispatch => {
    return{
        onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
        onFetchCommander: (gCode) => dispatch(commanderActions.fetchCommander(gCode)),
        onFilterUsers: () => dispatch(commanderActions.filterUsers()),
        onSetActiveTab: (groupCode) => dispatch(commanderActions.setActiveTab(groupCode)),
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SendSuccsess);
