import React, { Component } from 'react'
import { isInvalidFile, isInvalidSizeFile } from '../../shared/utility';

class Files extends Component {

    state = {
        isDisabled: this.props.cantUploadMoreFiles,
        errorFile: false,
        errorSizeFile: false,
    }

    findIfSavedFile = (fileName) => {
        let found = false
        found = this.props.dateToUploadFiles && this.props.dateToUploadFiles['fileName1'] === fileName
        if(!found){
            found = this.props.dateToUploadFiles && this.props.dateToUploadFiles['fileName2'] === fileName
        }
        return found
    }

    componentDidUpdate(prevProps) {       
        if ((this.props.files !== prevProps.files)) {

            if(!this.props.cantUploadMoreFiles){
                this.setState({
                    isDisabled: this.props.files.length === 2
                })
            }
            else{
                this.setState({
                    isDisabled: true
                })
            }     

        }
        // if(this.props.canUploadMoreFiles !== prevProps.canUploadMoreFiles){
        //     this.setState({
        //         isDisabled: !this.props.canUploadMoreFiles
        //     })
        // }
    }

    handleFile = (e) => {

        if (e.target.files && e.target.files[0] && isInvalidFile(e.target.files[0])) {
            this.setState({
                errorFile: true
            })
        }

        else if(isInvalidSizeFile(e.target.files[0])) {
            this.setState({
                errorSizeFile: true
            })
        }

        else if (e.target.files[0] && this.props.files.length < 3) {
            let newFiles = this.props.files.slice();
            newFiles.push(e.target.files[0]);
            this.setState({
                errorFile: false,
                errorSizeFile: false
            })
            if (this.props.files.length + 1 === 2) {
                this.setState({
                    isDisabled: true
                })
            }
            this.props.updateFiles(newFiles);
        }
        e.target.value = null;

    }


    deleteFile = (i) => {
        //file.name !== fileName 
        
        let newFiles = this.props.files.filter((file,index) => { return ( index!==i) })     

        let isFileFromHistory = this.props.files.length > 0 && this.props.files[i] && this.props.files[i].name
            
        if (this.props.files.length - 1 < 3) {
            this.setState({
                isDisabled: false
            })
        }
        if (this.props.files.length - 1 === 0) {
            this.setState({
                errorFile: false,
                errorSizeFile: false
            })
        }
        this.props.updateFiles(newFiles,this.findIfSavedFile(isFileFromHistory) ? isFileFromHistory : null);
    }

    render() {
        let uploadListContainer = "uploadListContainer";
        let uploadBtnClass = "uploadBtn";
        let fileErrorClass = "fileError";

        if (this.state.isDisabled || this.props.cantUploadMoreFiles) uploadBtnClass += ' disable'
        else uploadBtnClass.replace('disable', '');

        if (this.state.errorFile || this.state.errorSizeFile || this.props.cantUploadMoreFiles) fileErrorClass += ' show'
        else fileErrorClass.replace('show', '');

        return (
            <>
                <div className="uploadBtnContainer">
                    <label className={uploadBtnClass} htmlFor="uploadBtn">
                        <input id="uploadBtn" type="file"
                            accept=".jpg,.jpeg,.png,.tiff,.pdf,.docx"
                            onChange={e => this.handleFile(e)} disabled={this.state.isDisabled} />
                    </label>
                </div>

                <div className={uploadListContainer}>
                    <div className="inner">
                        <h2 className="subTitle">ניתן לצרף עד 2 מסמכים</h2>
                        <ul className="uploadList">
                            {
                                this.props.files.length ? this.props.files.map((file, index) => {
                                    return (
                                        <li className="item" key={index}>
                                            <img className="attachIcon" src="/img/attachIcon.png" alt="" />
                                            <div className="text">{file.name}</div>
                                            {/* !this.props.isFromHistory */}
                                            {file && ( (file.name && !file.cantRemove) || (file.cantRemove && file.cantRemove===false))  && <button type="button" className="xBtn"
                                                onClick={() => this.deleteFile(index)}></button>}
                                        </li>
                                    )
                                }) : null
                            }
                        </ul>
                    </div>
                </div>
         
                {this.state.errorFile  && <div className={fileErrorClass}>קובץ לא תקין</div>}
                {this.state.errorSizeFile  && <div className={fileErrorClass}>קובץ גדול מדי</div>}
                {this.props.cantUploadMoreFiles && <div className={fileErrorClass}>לא ניתן להעלות יותר מ-6 מסמכים ביום</div>}

            </>
        )
    }
}

export default Files;