import * as actionTypes from '../actions/actionTypes';

const initialState = {
    futureReports: [],
    loader: false,

}

const futureReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOADER_FUTURE:
            return {
                ...state,
                loader: true
            }
            
        case actionTypes.FETCH_FUTURE_REPORT_SUCCESS:
            return {
                ...state,
                futureReports: action.futureReport
            }
      
        case actionTypes.DELETE_FUTURE_REPORT_SUCCESS:
            return {
                ...state,
                futureReports: {...state.futureReports, days: state.futureReports.days.filter(report => report.date !== action.date)}
            }
        default:
            return state;
    }
}

export default futureReportReducer;