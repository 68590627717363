import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';



class ExplanationModal extends Component {

    state = {
        isModalShow: true,
    }

    handleModalClose = () => {
        this.setState({
            isModalShow: false
        })
    }

    handleModalShow = () => {
        this.setState({
            isModalShow: true
        })
    }


     render() {
        //   let notificationsList = this.props.notifications.map((notification,index) => {
        //      return (
        //          <li key={index}>
        //              <div className="text">{notification}</div>
        //          </li>
        //      )
        //     })
        let url = this.props.link;
        let isMobile = window.innerWidth < 768;
         return (
             <Modal className="explanationModal" show={this.state.isModalShow} onHide={this.handleModalClose} centered>
                  {/* <Modal className="mainModal" show={this.state.isModalShow} onHide={this.handleModalClose} centered></Modal> */}
                 <div className="warningBox">
                    <button type="button" className="xModal" onClick={this.handleModalClose}></button>
                    <div className="modal-header">
                        <img className="icon" src="./img/primarieIcon1.png" alt="" />
                        <div className="title"> {this.props.title} </div>
                    </div>
                    <div className="content"> {this.props.content} </div>
                    <div className="link">
                        <a
                            onClick={() => {
                                if (!isMobile || /android/i.test(navigator.userAgent)) {
                                    window.open(url, "_blank");
                                }
                                else {
                                    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                                        window.location.assign(url, "_system", 'location=yes')
                                    }
                                    else {
                                        window.webkit.messageHandlers.appJSBridge.postMessage({
                                            "actionType": "web",
                                            "link": url
                                        });
                                    }

                                }
                            }}
                        > {url} </a>
                    </div>
                    {/* <ul className="notificationsList">
                         {notificationsList}
                     </ul> */}
                    {/* <button type="button" className="btnGeneral send" onClick={this.handleModalClose}>הבנתי</button> */}
                 </div>
             </Modal>
         )
     }

}

export default ExplanationModal;