import * as actionTypes from '../actions/actionTypes';

const initialState = {
    isUserAuth: null,
    isCommanderAuth: false,
    error: null,
    connectionError: null,
    loader: false,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOADER_AUTH:
            return {
                ...state,
                loader: true
            }
        case actionTypes.AUTH_START:
            return {
                ...state,
                error: null,
                connectionError: null
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                error: action.error,
                isUserAuth: action.isUserAuth,
                isCommanderAuth: action.isCommanderAuth,
                connectionError: null,
            }
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                error: action.error,
                loader: false,
                connectionError: true
            }
        case actionTypes.GET_USER_AUTH_SUCCESS:
            return {
                ...state,
                isUserAuth: action.isUserAuth,
                isCommanderAuth: action.isCommanderAuth
            }
        case actionTypes.GET_USER_AUTH_FAIL:
            return {
                ...initialState
            }
        case actionTypes.LOGOUT_SUCCESS:
            return {
                isUserAuth: false,
                isCommanderAuth: false,
                error: null,
                connectionError: null,
                loader: false,
            }
    
        case actionTypes.RESET_STORE:
            return{
                isUserAuth: false,
                isCommanderAuth: false,
                error: null,
                connectionError: null,
                loader: false,
            }
        case actionTypes.LOGOUT_FAIL:
            return {
                loader: false,
            }
        case actionTypes.LOGOUT_COMMANDER:
            return {
                ...state,
                isCommanderAuth: false
            }
        default:
            return state
    }

}
export default authReducer;