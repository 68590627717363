import * as actionTypes from '../actions/actionTypes';


const initialState = {
    reportedData: {},
    gender:'',
    userName: "",
    timeToReport: "",
    primaries: [
        {
            code: null,
            name: "",
            secondaries: [
                {
                    code: null,
                    name: "",
                    hasFiles: null,
                    hasText: null,
                    priority: null
                }
            ]
        }
    ],
    primarySelection: null,
    fullSelection: {
        primaryCode: '',
        primaryText: '',
        icon: '',
        secondaryCode: '',
        secondaryText: '',
        note: '',
        files: [],
        startDate: null,
        endDate: null,        
        isEmergency: false,
        reportedDate: null
    },
    selection: {
        primaryCode: null,
        primaryText: null,
        icon: null,
        secondaryCode: null,
        secondaryText: null,
        note: null,
        files: [],
        startDate: null,
        endDate: null,
        isEmergency: false,
    },
    loader: false,
    isChangeReport: false,
    isSaveSelection: false,
    showExplanationPopUp: false,
    showEmergencyBtn: false,
    showEmergencyPractice: false,
    disableFutureReport: false,
    disableChangeabltBtn: false,
    disableCoronaIcon: false

}

const wizardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STORE:
            return {
                ...initialState
            }
        case actionTypes.GET_USER_AUTH_SUCCESS:
            return {
                ...state,
                loader: false,
            }
    
        case actionTypes.SHOW_LOADER_WIZARD:
            return {
                ...state,
                loader: true
            }
        case actionTypes.FETCH_REPORTED_DATA_SUCCESS:
            return {
                ...state,
                reportedData: action.reportedData,
                gender:action.reportedData.gender,
                loader: false
            }
        case actionTypes.FETCH_FUTURE_MODE_SUCCESS:
            return {
                ...state,
                disableFutureReport: action.futureReportMode
            }
        case actionTypes.SET_WIZARD:
            return {
                ...state,
                ...action.payload,
                loader: false,
            }
        case actionTypes.FETCH_WIZARD_FAILED:
            return {
                ...state,
                loader: false
            }
        case actionTypes.SET_PRIMARIE_SELECTION:
            return {
                ...state,
                primarySelection: action.primarySelection,
            }
        case actionTypes.SAVE_SELECTION:
            return {
                ...state,
                selection: {
                    ...state.selection,
                    primaryCode: action.selection.primaryCode || state.selection.primaryCode,
                    primaryText: action.selection.primaryText || state.selection.primaryText,
                    icon: action.selection.icon || state.selection.icon,
                    secondaryCode: action.selection.secondaryCode || state.selection.secondaryCode,
                    secondaryText: action.selection.secondaryText || state.selection.secondaryText,
                    note: action.selection.note || state.selection.note,
                    isEmergency: action.selection.isEmergency || state.selection.isEmergency
                },
                loader: false,
                isChangeReport: false,
                isSaveSelection: true
            }
        case actionTypes.SAVE_SELECTION_FAILED:
            return {
                ...state,
                isSaveSelection: false
            }
        case actionTypes.STORE_SELECTION_SUCCESS:
            return {
                ...state,
                loader: false
            }
        case actionTypes.CHANGE_REPORT:
            return {
                ...state,
                isChangeReport: action.bool
            }
        case actionTypes.STORE_FULL_SELECTION:
            return {
                ...state,
                fullSelection: action.fullSelection
            }
    
        case actionTypes.STORE_FILES:
            return {
                ...state,
                fullSelection: {
                    ...state.fullSelection,
                    files: action.files
                }
            }
        default:
            return state
    }
};

export default wizardReducer;