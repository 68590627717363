import React, { Component } from 'react';
import { withMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

import '../src/assets/styles/reset.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from '../src/core/layout';
import axios from 'axios'
import { withRouter } from "react-router";
import * as authAction from './store/actions/index';
import * as layoutAction from './store/actions/index';
import { connect } from 'react-redux';
import Loader from './shared/components/loader';
import { urls } from './shared/utility';
import '../src/assets/styles/styles.scss';
import '../src/assets/styles/styles768.scss';
import CacheBuster from './CacheBuster';
import { config } from './config';
import commander from './commander/containers/commander';
import { loginRequest } from './authConfig'


//import RotatePhone from './shared/components/rotatePhone';

class App extends Component {

	state = {

		// isLandscape: false
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		window.addEventListener('backbutton', function (e) { window.history.back(); })
		this.props.onLoadIsMobile();
		this.props.onGetContentManagment();

		// window.location.reload(true)

		// if (window.innerWidth < 768 && window.innerHeight < window.innerWidth) {
		//   this.setState({
		//     isLandscape: true
		//   })
		// }
		// window.onresize = (event) => {
		//   if (window.innerWidth < 768) {
		//     this.setState({
		//       isLandscape: this.applyOrientation()
		//     })
		//   } else {
		//     this.setState({
		//       isLandscape: false
		//     })
		//   }
		// }
	}


	componentWillUnmount() {
		window.removeEventListener('backbutton', function (e) { window.history.back(); })
	}

	componentDidUpdate(prevProps) {
		if (prevProps.msalContext
			&& prevProps.msalContext.inProgress !== InteractionStatus.None
			&& prevProps.msalContext.inProgress !== InteractionStatus.Login
			&& this.props.msalContext
			&& this.props.msalContext.inProgress === InteractionStatus.None
		) {
			this.getUser()
		}

		if (prevProps
			&& prevProps.contentManagment
			&& !this.props.contentManagment
		) {
			this.props.onGetContentManagment();
		}
	}

	getUser() {
		this.props.onGetUserAuth()
			.then(() => {
				if (!this.props.isUserAuthenticated && window.location.pathname !== urls.login) {
					this.props.history.push(urls.login);
				}
			})

	}

	applyOrientation = () => {
		return window.innerHeight < window.innerWidth
	}

	render() {
		const logout = () => {
			const msalContext = this.props.msalContext;

			this.props.onLogout(() => {
				const logoutRequest = {}
				const currentAccounts = msalContext.accounts;
				if (currentAccounts && currentAccounts.length > 0) {
					Object.assign(logoutRequest, {
						account: currentAccounts[0],
					});
				}

				return (logoutRequest && Object.keys(logoutRequest).length > 0 && Object.getPrototypeOf(logoutRequest) === Object.prototype) ?
					msalContext.instance.logout(logoutRequest) :
					new Promise((res, _) => res())
			})
				.then(() => {
					if (window.location.pathname !== urls.login) {
						this.props.history.push(urls.login);
					}
				})
		}

		axios.interceptors.request.use(req => {
			req.baseURL = config.baseUrl
			req.headers['Access-Control-Allow-Origin'] = '*';
			req.headers['crossDomain'] = true;
			req.headers['Pragma'] = 'no-cache';
			return req;
		}, error => {
			this.props.history.push(urls.serverError)
			return Promise.reject(error);
		});

		axios.interceptors.response.use(res => {
			//cookies expired
			if (res.status === 204) {
				logout()
			}
			return res;
		}, error => {
			// cookies expired commander
			if (error.response.status === 401) {
				if (error.response.headers.usertype === "commander") {
					this.props.onLogoutCommander()
					this.props.history.push(urls.loginCommander)
				} else {
					logout()
				}
			} else {
				if (this.props.isUserAuthenticated)
					this.props.history.push(urls.serverError)
			}
			return Promise.reject(error);
		});

		if (this.props.isUserAuthenticated === false || (this.props.isUserAuthenticated && this.props.contentManagment)) {

			return (
				<CacheBuster>
					{({ loading, isLatestVersion, refreshCacheAndReload }) => {
						if (loading) return <Loader />;
						if (!loading && !isLatestVersion) {
							refreshCacheAndReload();
						}

						return (
							<div className="mainApp">
								<Layout isUserAuthenticated={this.props.isUserAuthenticated} />
							</div>
						);
					}}
				</CacheBuster>
			);
		} else return <Loader />

	}
}

const mapStateToProps = state => {
	return {
		contentManagment: state.layout.contentManagment,
		reportedData: state.wizard.reportedData,
		isUserAuthenticated: state.auth.isUserAuth,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onGetUserAuth: () => dispatch(authAction.getUserAuth()),
		onGetContentManagment: () => dispatch(layoutAction.getContentManagment()),
		onLogout: (cb) => dispatch(authAction.logout(cb)),
		onLoadIsMobile: () => dispatch(layoutAction.loadIsMobile()),
		onLogoutCommander: () => dispatch(authAction.logoutCommander()),
		onResetCommander: () => dispatch(commander.actionTypes.RESET_STORE())
	}
}
global.sentToBackgroundTime = new Date(Date())
global.applicationStateChange = function (CurrentState) {

	if (CurrentState === 'foreground') {
		//check that 6 hours has passed from going to background
		var millisecondsPerHour = 1000 * 60 * 60 * 6;
		if (new Date(Date()) - global.sentToBackgroundTime > millisecondsPerHour) {
			global.sentToBackgroundTime = new Date(Date())
			window.location.reload()
		}
	}
	if (CurrentState === 'background') {
		global.sentToBackgroundTime = new Date(Date())
	}
};

const AppWithMsal = withMsal(App)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWithMsal));
