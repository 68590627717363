import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as commanderActions from '../../store/actions/index';
import * as layoutActions from '../../store/actions/index';
import SecondariesList from '../../shared/components/secondariesList';
import TabsPrimaries from '../../shared/components/tabsPrimaries';
import { urls, getText } from '../../shared/utility';
import Loader from '../../shared/components/loader';
import UsersGroup from '../components/usersGroup';

class OtherStatus extends Component {

    state = {
        pageType: '',
        selectedPrimaryCode: null,
        selectedPrimaryText: null,
        selectedSecondaryCode: null,
        selectedSecondaryText: null,
        selectedInValid: true,
        activeIndex: 0,
        note: null,
        selectMainStatusText: '',
        selectSecondaryStatusText: '',
        isLoading: false,
    }

    componentDidMount() {
        this.loadText()
        this.props.onShowWizardHeader();
        if (this.props.match.params.param === 'personalReported') {
            this.fetchAllStatuses()
            this.setState({
                pageType: 'personalReported'
            })
        }
        else if (this.props.match.params.param === 'commanderReported') {
            this.fetchAllStatuses()
            this.setState({
                pageType: 'commanderReported'
            })
        }
        else {
            this.setState({
                isLoading: true
            })
            this.props.onFetchOtherStatusesByMi(this.props.user, this.props.activeTabCode)
                .then(() => {
                    this.initStatuses(this.props.primaries)
                })
        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.selectedSecondaryCode !== null) {
            return {
                selectedInValid: false
            }
        }
        return null
    }

    loadText = () => {
        this.setState({
            selectMainStatusText: getText(this.props.contentManagment.selectStatusCommander, 'SelectMainStatus', this.props.gender),
            selectSecondaryStatusText: getText(this.props.contentManagment.selectStatusCommander, 'SelectSecondaryStatus', this.props.gender),
        })
    }

    fetchAllStatuses = () => {
        this.props.onGetAllStatuses()
            .then(() => {
                this.initStatuses(this.props.primaries)
            })
            .catch(err => {
                console.log(err)
            })
    }

    initStatuses = (primaries) => {
        primaries.sort((a, b) => (a.pratPrioritization > b.pratPrioritization) ? 1 : ((b.pratPrioritization > a.pratPrioritization) ? -1 : 0));
        this.setState({
            selectedPrimaryCode: primaries[0].statusCode,
            selectedPrimaryText: primaries[0].statusDescription,
            isLoading: false,
        })
    }

    onPrimaryClick = (primaryCode, primaryText, index) => {
        let now = Date.now()
        let timeLeft = new Date(this.props.endReport).getTime() - now
        if(timeLeft > 0){
            this.setState({
                selectedPrimaryCode: primaryCode,
                selectedPrimaryText: primaryText,
                selectedSecondaryCode: null,
                selectedSecondaryText: null,
                activeIndex: index,
                selectedInValid: true
            })
        }
        else{
            this.props.onSetFinishTimeToReport();
            this.props.history.push({
                pathname: urls.commander,
                state: { prevUrl: null }   
            })
    }

    }

    setSelectedScondary = (code, text) => {
        let now = Date.now()
        let timeLeft = new Date(this.props.endReport).getTime() - now
        if(timeLeft > 0){
            this.setState({
                selectedSecondaryCode: code,
                selectedSecondaryText: text
                
            })
        }
        else {
            this.props.onSetFinishTimeToReport();
            this.props.history.push({
                pathname: urls.commander,
                state: {prevUrl: null }   
            })
        }
    }

    setNote = (note) => {
        this.setState({
            note: note
        })
    }

    generateUser = () => {

        return {
            ...this.props.user,
            approvedMainCode: this.state.selectedPrimaryCode,
            approvedMainName: this.state.selectedPrimaryText,
            approvedSecondaryCode: this.state.selectedSecondaryCode,
            approvedSecondaryName: this.state.selectedSecondaryText,
            note: this.state.note,
            notReported: false,
            reportedConflict: false,
            isActiveRow: false,
            editedAndNotSent: true,
            groupCode: this.props.activeTabCode
        }
    }

    generateFilterStatus = () => {
        return {
            type: this.state.pageType,
            mainCode: this.state.selectedPrimaryCode,
            mainName: this.state.selectedPrimaryText,
            secondaryCode: this.state.selectedSecondaryCode,
            secondaryName: this.state.selectedSecondaryText,
        }
    }

    submitClick = () => {
        let now = Date.now()
        let timeLeft = new Date(this.props.endReport).getTime() - now
        if(timeLeft > 0){
            if (this.state.pageType === '') {
                const user = this.generateUser()

                if (!this.state.selectedInValid) {
                    this.setState({
                        isLoading: true
                    })
                    this.props.onUpdateOtherStatus(user)
                        .then(() => {
                            this.props.onCheckIsSubmittedToday(this.props.usersGroup.users)
                            if(this.props.isSubmittedToday && !this.props.isCommanderMustSendFullReport)
                                this.props.history.push(urls.confirmGroup)
                            else
                            this.props.history.push(urls.commander)
                        })
                }
            }
            else {
                const filterStatus = this.generateFilterStatus()
                if (!this.state.selectedInValid) {
                    this.props.onStoreFilters(filterStatus);
                    //this.props.history.push(urls.commander)
                    this.props.history.push({
                        pathname: urls.commander,
                        state: { prevUrl: null }
                    })
                }
            }
        }
        else{
            this.props.onSetFinishTimeToReport();
            this.props.history.push({
                pathname: urls.commander,
                state: {prevUrl: null }   
            })
        }
    }

    render() {

        let btnGeneralText = 'עדכון'
        let secondariesList = '';

        if (this.props.primaries) {
            if (this.props.primaries[this.state.activeIndex].secondaries) {
                secondariesList = <SecondariesList
                    isUpdateOtherStatus={this.props.isUpdateOtherStatus}
                    activeIndex={this.state.activeIndex}
                    secondaries={this.props.primaries[this.state.activeIndex].secondaries}
                    selectedSecondary={this.setSelectedScondary}
                    setNote={(note) => this.setNote(note)} />
            }
        }
        if (this.state.pageType !== '') {
            btnGeneralText = 'סינון'
        }
        if (this.state.isLoading) {
            return <Loader />
        }
        return (
            <div className="commander__main">
                <TabsPrimaries
                    selectMainStatusText={this.state.selectMainStatusText}
                    primaries={this.props.primaries}
                    userName={`${this.props.user.firstName} ${this.props.user.lastName}`}
                    activeIndex={this.state.activeIndex}
                    pageType={this.state.pageType}
                    onPrimaryClick={(statusCode, statusDescription, index) => this.onPrimaryClick(statusCode, statusDescription, index)} />

                <section className="commanderSecondariesSection">
                    <div className="container-fluid">
                        <div className="titleContainer">
                            <div className="subTitle">{this.state.selectSecondaryStatusText}</div>
                        </div>
                        {secondariesList}
                    </div>
                </section>
                <div className="btnSendReportContainer">
                    <button
                        type="button"
                        className="btnGeneral send"
                        disabled={this.state.selectedInValid}
                        onClick={() => this.submitClick()}>{btnGeneralText}</button>
                </div>
            </div>
        )


    }
}

const mapStateToProps = state => {
    return {
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        primaries: state.commander.otherStatus.primaries,
        user: state.commander.userToUpdateStatus,
        activeTabCode: state.commander.activeTabCode,
        isUpdateOtherStatus: state.commander.isUpdateOtherStatus,
        usersGroup: state.commander.usersGroup,
        isSubmittedToday: state.commander.isSubmittedToday,
        isCommanderMustSendFullReport: state.commander.isCommanderMustSendFullReport,
        endReport: state.commander.endReportTime,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchOtherStatusesByMi: (userToUpdate, groupCode) => dispatch(commanderActions.fetchOtherStatusesByMi(userToUpdate, groupCode)),
        onUpdateOtherStatus: (user) => dispatch(commanderActions.updateOtherStatus(user)),
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onStoreFilters: (filterStatus) => dispatch(commanderActions.storeFilters(filterStatus)),
        onGetAllStatuses: () => dispatch(commanderActions.getAllStatuses()),
        onCheckIsSubmittedToday: (users) => dispatch(commanderActions.checkIsSubmittedToday(users)),
        onSetFinishTimeToReport: () => dispatch(commanderActions.setIsValidTimeToReport())

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherStatus);