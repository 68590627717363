import React, { Component } from 'react';
import Timer from '../../shared/components/timer';
import { getTime, getText } from '../../shared/utility';

class MsgRow extends Component {

    state = {
        cantSendReportAfterText: '',
        cantSendReportTodayText: '',
        SendReport: '',
        TimeLeft: '',
        DeterminedReportsReceivedText: '',
        isValidTime: null,
        IsWeekendNachsalReportActive: false
    }

    componentDidMount() {
        this.loadText()
    }

    loadText = () => {
        this.setState({
            cantSendReportAfterText: getText(this.props.contentManagment.mainScreenCommander, 'CantSendReportAfter', this.props.gender),
            cantSendReportTodayText: getText(this.props.contentManagment.blockReportingAfterHour, 'CantSendReportToday', this.props.gender),
            SendReportText: getText(this.props.contentManagment.mainScreenCommander, 'SendReport', this.props.gender),
            TimeLeftText: getText(this.props.contentManagment.mainScreenCommander, 'TimeLeft', this.props.gender),
            DeterminedReportsReceivedText: getText(this.props.contentManagment.mainScreenCommander, 'DeterminedReportsReceived', this.props.gender),
            isValidTime: this.props.isValidTime,
            IsWeekendNachsalReportActive: this.props.IsWeekendNachsalReportActive
        })
    }

    goToFinish = () => {
        this.setState({
            isValidTime: false,
        })
    }

    render() {
        let msgClass = 'commander__send-report';
        let msgHtml = '';

        if (!this.state.IsWeekendNachsalReportActive && [5, 6].includes(new Date().getDay())) {
            msgHtml = ''
        } else if (this.props.isCantReport) {
            msgClass += ' error'
            if (!this.state.isValidTime) {
                msgHtml = this.state.cantSendReportAfterText
                msgHtml += " " + getTime(this.props.timeToReport)
            }
            else {
                msgHtml = this.state.cantSendReportTodayText//'לא ניתן לדווח ביום הנוכחי'
            }
        }
        else if (this.props.isGroupDetermined) {
            msgClass += ' final'
            msgHtml = (
                <span>
                    <img className="icon" src="/img/pin.png" alt="" />
                    {this.state.DeterminedReportsReceivedText}
                </span>)
        }
        else if (this.state.isValidTime && this.props.isCantReport) {
            msgHtml = (<span>{this.state.TimeLeftText} <Timer goToFinish={this.goToFinish} timeToReport={this.props.timeToReport} /> {this.state.SendReportText}</span>)
        }
        else {
            msgClass += ' error'
            msgHtml = (<span>{this.state.cantSendReportAfterText} <span>{getTime(this.props.timeToReport)}</span></span>)
        }
        return (
            <div className={msgClass}>
                {msgHtml}
            </div>
        )
    }
}
export default MsgRow;
