import React, { Component } from 'react';
import TitleContainer from '../../shared/components/titleContainer';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { he } from 'date-fns/esm/locale';
import * as actions from '../../store/actions/index';
import { urls, getText } from '../../shared/utility';

registerLocale("he", he);

class UpdateDates extends Component {

    state = {
        //ios: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
        selectDateRangeText: '',
        startDate: new Date(),
        endDate: new Date(),
        currentDate: '',
        startDateMobile: '',
        endDateMobile: '',
        isFromHistory: null,
        isErrorMsgShow: false,
        isReportSend: false,
        isFutureReport: false,
        reportDate: null
    };

    componentDidMount() {
        this.loadText()
        const isFromHistory = this.props.match.params.param === 'history'
        let isFutureReport = false;
        let endDate = new Date();
        const today = this.state.startDate;
        const dd = String(this.state.startDate.getDate()).padStart(2, '0');
        const mm = String(this.state.startDate.getMonth() + 1).padStart(2, '0');
        const yyyy = this.state.startDate.getFullYear();
        let endDD = dd;
        let endMM = mm;
        let endYYYY = yyyy;
        const location = this.props.location;
        let reportDate;
        if(location && location.state && location.state.reportDate){
            isFutureReport = true;
            endDate.setDate(today.getDate() + 30);
            endDD = String(endDate.getDate()).padStart(2, '0');
            endMM = String(endDate.getMonth() + 1).padStart(2, '0');
            endYYYY = endDate.getFullYear();
            reportDate = location.state.reportDate
        }   

        this.setState({
            currentDate: `${yyyy}-${mm}-${dd}`,
            startDateMobile: `${yyyy}-${mm}-${dd}`,
            endDateMobile: `${endYYYY}-${endMM}-${endDD}`,
            isFromHistory,
            endDate: endDate,
            isFutureReport: isFutureReport,
            reportDate: reportDate
        })
    }

    loadText = () => {
        this.setState({
            selectDateRangeText: getText(this.props.contentManagment.uploadFile, 'SelectDateRange', this.props.gender),
        })
    }

    handleStartChange = (date) => {
        this.setState({
            startDate: date
        });
    }

    handleEndChange = (date) => {
        this.setState({
            endDate: date
        });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    handleMobile = (e) => {

        if (!e.target.value || e.target.value === '') {
            return
        }

        const [year, month, day] = e.target.value.split('-')

        if (e.target.name === 'endDateMobile') {
            if (e.target.value <= new Date(this.state.startDateMobile)) {
                this.setState({
                    isErrorMsgShow: true
                });
            } else {
                this.setState({
                    [e.target.name]: `${year}-${month}-${day}`
                });
            }
        }
        this.setState({
            [e.target.name]: `${year}-${month}-${day}`
        })
    }

    getDates = () => {
        if (this.detectmob()) {

            const [sYear, sMonth, sDay] = this.state.startDateMobile.split(this.state.startDateMobile.includes('-') ? '-' : '/')

            const [eYear, eMonth, eDay] = this.state.endDateMobile.split(this.state.endDateMobile.includes('-') ? '-' : '/')

            return [new Date(sYear, sMonth - 1, sDay), new Date(eYear, eMonth - 1, eDay)]
        } else {
            return [this.state.startDate, this.state.endDate]
        }
    }

    createSelection = () => {
        const [startDate, endDate] = this.getDates()
        return {
            ...this.props.fullSelection,
            startDate,
            endDate
        }
    }

    submitReport = () => {
        let d = this.props.dateToUploadFilesAndDates === null ? new Date() : new Date(this.props.dateToUploadFilesAndDates.date)
        let selection;
        this.setState({
            isReportSend: true
        })
        if (this.state.isFromHistory) {
            const [startDate, endDate] = this.getDates()
            selection = {
                date: d,
                files: this.props.filesToUpload,
                startDate,
                endDate
            }

            this.props.onUpdateFilesAndDates(selection)
                .then(res => {
                    if (this.props.dateToUploadFilesAndDates && this.props.dateToUploadFilesAndDates.date) {
                        let d = new Date(this.props.dateToUploadFilesAndDates.date)
                        this.props.history.push({
                            pathname: urls.historyPrat,
                            state: { m: d.getMonth() + 1, y: d.getFullYear() }
                        })
                    }
                    else{
                        this.props.history.push(urls.historyPrat)
                    }

                    //console.log("d.getMonth() + 1, y: d.getFullYear()",this.props.dateToUploadFilesAndDates.date.getMonth() + 1,  this.props.dateToUploadFilesAndDates.date.getFullYear())
                    //this.props.history.push(urls.historyPrat);
                    // this.props.history.push({
                    //     pathname: urls.historyPrat,
                    //     state: { m: d.getMonth() + 1, y: d.getFullYear() }
                    // })
                })
                .catch(err => { })
            // this.props.onUpdateFilesAndDates(selection)
            //     .then(() => {

            //         console.log(" gggggg gggg ggg  gggggg ggggggg ", this.props.dateToUploadFilesAndDates.date.getMonth() + 1, this.props.dateToUploadFilesAndDates.date.getFullYear())
            //         // if(this.props.dateToUploadFilesAndDates && this.props.dateToUploadFilesAndDates.date){

            //         //     this.props.history.push({
            //         //         pathname: urls.historyPrat,
            //         //         state: { m : this.props.dateToUploadFilesAndDates.date.getMonth() + 1, y : this.props.dateToUploadFilesAndDates.date.getFullYear() } 
            //         //     })         
            //         // }
            //         // else{
            //         this.props.history.push(urls.historyPrat)
            //         // }     
            //     })
            //     .catch(err => { })
        } else {
            selection = this.createSelection();
            this.props.onSaveSelection(selection)
                .then(() => {
                    if (this.state.isFutureReport)
                        this.props.history.push({
                            pathname: urls.futureReport,
                            state: { reportDate: this.state.reportDate } 
                        });
                    else
                        this.props.history.push(urls.finish);
                });
        }

    }
    detectmob = () => {
        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {

        return (
            <div className="pageContainer">
                <TitleContainer
                    iconPath={'img/uploadFiles.png'}
                    mainTitle={'העלאת מסמכים'}
                    subTitle={'יש לצרף אסמכתא לדיווח זה'}
                    isShadow={true} />
                <div className="updateDate">
                    <div className="title">לאיזו תקופה המסמכים מתייחסים?</div>
                    <h2 className="subTitle">{this.state.selectDateRangeText}</h2>
                </div>
                <ul className="uploadList">
                    <li className="item">
                        <div className="text">החל מ</div>
                        {
                            this.detectmob() ?
                                <label className="formControlDate" htmlFor="fromDate">
                                    {/* value={ this.state.currentDate} */}
                                    <input type="date" id="fromDate" className="inputDate" value={!this.state.startDateMobile ? this.state.currentDate : this.state.startDateMobile} max={this.state.endDateMobile} name="startDateMobile" onChange={this.handleMobile} />
                                    <span className="text">{this.state.startDateMobile}</span>
                                </label>
                                : <DatePicker selected={this.state.startDate} onChange={this.handleStartChange} maxDate={new Date(this.state.endDate)} dateFormat="dd/MM/yyyy" onChangeRaw={this.handleDateChangeRaw} locale="he" />
                        }
                        <img className="dateIcon" src="/img/dateIcon.png" alt="" />
                    </li>

                    <li className="item">
                        <div className="text">עד</div>
                        {
                            this.detectmob() ?
                                <label className="formControlDate" htmlFor="endDate">
                                    <input type="date" id="endDate" className="inputDate" value={!this.state.endDateMobile ? this.state.currentDate : this.state.endDateMobile} min={this.state.startDateMobile} name="endDateMobile" onChange={this.handleMobile} />
                                    <span className="text">{this.state.endDateMobile}</span>
                                </label>
                                : <DatePicker selected={this.state.endDate} onChange={this.handleEndChange} minDate={this.state.startDate} dateFormat="dd/MM/yyyy" onChangeRaw={this.handleDateChangeRaw} locale="he" />
                        }
                        <img className="dateIcon" src="/img/dateIcon.png" alt="" />
                    </li>
                    {((this.detectmob() && this.state.startDateMobile && this.state.endDateMobile && new Date(this.state.startDateMobile) > new Date(this.state.endDateMobile))) && <li className="dateError">נא להכניס תאריך חוקי</li>}
                </ul>

                <div className="btnSendReportContainer">
                    <button type="button" className="btnGeneral send" onClick={this.submitReport} disabled={this.state.isReportSend}>
                        {this.state.isFromHistory ? 'עדכן' : 'שליחת דיווח'}
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        isMobile: state.layout.isMobile,
        fullSelection: state.wizard.fullSelection,
        filesToUpload: state.history.filesToUpload,
        dateToUploadFilesAndDates: state.history.dateToUploadFiles,
        activeDate: state.history.activeDate
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSaveSelection: (selection) => dispatch(actions.storeSelection(selection)),
        onUpdateFilesAndDates: (selection) => dispatch(actions.updateFilesAndDates(selection))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDates);
