import React, { Component } from 'react'
import TimeSlide from '../shared/components/timeSlide';
import Switcher from '../shared/components/switcher';
import { connect } from 'react-redux';
import SelectControl from '../shared/components/selectControl';
import * as layoutActions from '../store/actions/index';
import * as settingActions from '../store/actions/index';
import axios from 'axios';

class Settings extends Component {

    state = {
        attendanceTimePrat: "",
        attendanceTimeChoicePrat: null,
        timingPrat: null,
        conflictReportFromRedPrat: null,
        conflictReportFromCommanderPrat: null,
        //commander
        attendanceTimeCommander: "",
        timingCommander: null,
        attendanceTimeChoiceCommander: null,
        personalReportsFromMembersCommander: null,
        conflictReportFromRedCommander: null,
        conflictReportFromResponsibleCommander: null,
        errorText: "",
        firstLoad: true,
        isMobile: true,
    }

    componentDidMount() {
        this.setState({ isMobile: window.innerWidth < 768 })
        this.props.onShowWizardHeader();
        if (this.props.isCommanderAuth) {
            this.props.onFetchCommaderSetting()
                .then(res => {
                    this.setState({
                        ...res.data
                    })
                })
                .catch(err => {
                    this.setState({
                        errorText: "קיימת בעיה בשרת",
                    })

                })
        } else {
            this.props.onFetchSetting()
                .then(res => {
                    this.setState({
                        ...res.data
                    })
                })
                .catch(err => {
                    this.setState({
                        errorText: "קיימת בעיה בשרת",
                    })
                })
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data && prevState.firstLoad) {
            this.setState({
                ...this.props.data,
                firstLoad: false
            })
        }
    }

    switchChange = (choice, property) => {
        this.setState({
            [property]: choice
        }, () => {
            this.submit();
        })
    }

    changeSelect = (value, type) => {
        const prop = type === 'prat' ? 'timingPrat' : 'timingCommander'
        this.setState({
            [prop]: value
        }, () => {
            this.submit();
        })
    }

    timeHandle = (time, type) => {
        const prop = type === 'prat' ? 'attendanceTimePrat' : 'attendanceTimeCommander'
        this.setState({
            [prop]: time
        }, () => {
            this.submit();
        })

    }

    submit = () => {

        const url = `/api/settings/${this.props.isCommanderAuth ? 'updateSettingsCommander' : 'updateSettingsPrat'}`;
        axios.post(url, this.state)
            .then(res => {
                res ? this.setState({
                    ...this.state,
                    errorText: '',
                })
                    : this.setState({ errorText: 'שגיאה' })
            })
    }

    render() {

        return (
            <div className="setting">
                {this.props.isCommanderAuth &&
                    <section className="settingRowSection">
                        <div className="container-fluid">
                            <div className="settingRow">
                                <img className="icon" src="/img/settingsPratIcon.png" alt="" />
                                <h2 className="text">הגדרות אישיות</h2>
                            </div>
                        </div>
                    </section>}

                <section className="settingListSection">
                    <div className="container-fluid">
                        <div className="errorText">{this.state.errorText}</div>
                        <ul className="settingList">
                            <li>
                                <div className="item">
                                    <div className="title">שעת תזכורת</div>
                                    <div className="text">
                                        <p>
                                            מתי הכי נוח לך לדווח נוכחות?<br />
                                            קבע/י תזכורת לדיווח הנוכחות היומי.<br />
                                            התזכורת תפעל רק אם יש לדווח נוכחות באותו יום
                                        </p>
                                    </div>
                                    <div className="timeRow">
                                        <TimeSlide
                                            isMobile={this.state.isMobile}
                                            type={'prat'}
                                            time={this.state.attendanceTimePrat}
                                            timeHandle={(time, type) => this.timeHandle(time, type)}
                                        />
                                        <Switcher
                                            property={'attendanceTimeChoicePrat'}
                                            choice={this.props.data && this.state.attendanceTimeChoicePrat}
                                            switchChange={(choice, property) => this.switchChange(choice, property)} />
                                    </div>

                                </div>
                            </li>
                            <li>
                                <div className="selectRow">
                                    <div className="title">נודניק</div>
                                    <SelectControl
                                        isMobile={this.state.isMobile}
                                        type={'prat'}
                                        timing={this.state.timingPrat}
                                        changeSelect={(value, type) => this.changeSelect(value, type)} />
                                </div>
                            </li>
                            <li>
                                <div className="item">
                                    <div className="titleWrapper">
                                        <div className="title">הודעות אישיות מהמערכת</div>
                                        <div className="text">קבלת התרעה במקרה בו התקבלו הודעות מהמערכת</div>
                                    </div>

                                    <div className="slideRow">
                                        <div className="text">התקבל דיווח שונה ממשרד המשא"ן</div>
                                        <Switcher
                                            property={'conflictReportFromRedPrat'}
                                            choice={this.props.data && this.state.conflictReportFromRedPrat}
                                            switchChange={(choice, type) => this.switchChange(choice, type)} />
                                    </div>

                                    <div className="slideRow">
                                        <div className="text">התקבל דיווח שונה מהמפקד</div>
                                        <Switcher
                                            property={'conflictReportFromCommanderPrat'}
                                            choice={this.props.data && this.state.conflictReportFromCommanderPrat}
                                            switchChange={(choice, type) => this.switchChange(choice, type)} />
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                </section>

                {this.props.isCommanderAuth && <>
                    <section className="settingRowSection">
                        <div className="container-fluid">
                            <div className="settingRow">
                                <img className="icon" src="/img/settingsCommanderIcon.png" alt="" />
                                <h2 className="text">הגדרות מפקד/ת</h2>
                            </div>
                        </div>
                    </section>
                    <section className="settingListSection">
                        <div className="container-fluid">
                            <ul className="settingList">
                                <li>
                                    <div className="item">
                                        <div className="title">שעת תזכורת לכניסה לדו"ח סטטיסטי</div>
                                        <div className="text">
                                            <p>
                                                קבע/י תזכורת לכניסה לדו"ח הסטטיסטי עבור <br />
                                                הקבוצות שבאחריותך. התזכורת תפעל רק אם יש<br />
                                                לדווח נוכחות באותו יום
                                                </p>
                                        </div>
                                        <div className="timeRow">
                                            <TimeSlide
                                                isMobile={this.state.isMobile}
                                                type={'commader'}
                                                time={this.state.attendanceTimeCommander}
                                                timeHandle={(time, type) => this.timeHandle(time, type)}
                                            />
                                            <Switcher
                                                property={'attendanceTimeChoiceCommander'}
                                                choice={this.props.data && this.state.attendanceTimeChoiceCommander}
                                                switchChange={(choice, property) => this.switchChange(choice, property)} />
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="selectRow">
                                        <div className="title">נודניק</div>
                                        <SelectControl
                                            isMobile={this.state.isMobile}
                                            type={'commader'}
                                            timing={this.state.timingCommander}
                                            changeSelect={(value, type) => this.changeSelect(value, type)} />
                                    </div>
                                </li>

                                <li>
                                    <div className="item">
                                        <div className="titleWrapper">
                                            <div className="title">הודעות אחראי/ת מהמערכת</div>
                                            <div className="text">קבלת התרעה במקרה בו התקבלו הודעות מהמערכת</div>
                                        </div>

                                        <div className="slideRow">
                                            <div className="text">התקבלו דיווחים אישיים מכל המשתתפים בקבוצה</div>
                                            <Switcher
                                                property={'personalReportsFromMembersCommander'}
                                                choice={this.props.data && this.state.personalReportsFromMembersCommander}
                                                switchChange={(choice, type) => this.switchChange(choice, type)} />
                                        </div>

                                        <div className="slideRow">
                                            <div className="text">התקבל דיווח שונה ממשרד המשא"ן</div>
                                            <Switcher
                                                property={'conflictReportFromRedCommander'}
                                                choice={this.props.data && this.state.conflictReportFromRedCommander}
                                                switchChange={(choice, type) => this.switchChange(choice, type)} />
                                        </div>

                                        <div className="slideRow">
                                            <div className="text">התקבל  דיווח שונה ממפקד אחר</div>
                                            <Switcher
                                                property={'conflictReportFromResponsibleCommander'}
                                                choice={this.props.data && this.state.conflictReportFromResponsibleCommander}
                                                switchChange={(choice, type) => this.switchChange(choice, type)} />
                                        </div>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                </>}
            </div>
        )


    }
}

const mapStateToProps = state => {
    return {
        isCommanderAuth: state.auth.isCommanderAuth,
        data: state.setting.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onFetchSetting: () => dispatch(settingActions.fetchSetting()),
        onFetchCommaderSetting: () => dispatch(settingActions.fetchCommaderSetting()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);