import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';

class NameGroupModal extends Component {

    state = {
        isModalShow: false,
        groupName: '',
        formValid: true
    }

    componentDidUpdate(prevProps) {
        if (this.state.modalLoad && prevProps.groupName && prevProps.groupName !== this.state.groupName) {
            this.setState({
                groupName: prevProps.groupName,
                modalLoad: false
            })
        }
    }

    hideModal = () => {
        this.setState({
            isModalShow: false
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true,
            modalLoad: true
        })
    }

    keydown = (event) =>{
        const groupName = this.state.groupName;
        if(groupName.length > 10 && (event.keyCode === 8 || event.keyCode === 46)){
            const updateGrupeName = groupName.slice(0, -1); 
            this.setState({
                formValid: !!updateGrupeName && updateGrupeName.trim() !== '',
                groupName: updateGrupeName
            })
        }
    }

    changeHandler = (e) => {
        const value = e.target.value;
        if (value.length < 11) {
            this.setState({
                formValid: !!value && value.trim() !== '',
                groupName: value
            })
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        if (this.state.formValid) {
            this.props.submitGroupNameForm(this.state.groupName);
            this.hideModal();
        }
    }

    render() {
        return (
            <Modal className="mainModal" show={this.state.isModalShow} onHide={this.hideModal} centered>
                <div className="warningBox">
                    <button type="button" className="xModal" onClick={this.hideModal}></button>
                    <form className="editGroupNameForm" onSubmit={this.submitForm}>
                        <img className="editIcon" src="./img/editIcon.png" alt="" />
                        <div className="title">עדכון שם קבוצה</div>
                        <div className="formControlContainer">
                            <input onKeyDown={(event)=>this.keydown(event)} maxLength={10} minLength={1} className="formControl" type="text" onChange={this.changeHandler} value={this.state.groupName} />
                            {!this.state.formValid && <div className="errorMsg">שדה חובה</div>}
                        </div>
                        <button type="submit" className="btnGeneral send">אישור</button>
                    </form>

                </div>
            </Modal>
        )
    }
}

export default NameGroupModal;