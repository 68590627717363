import React from 'react'

const titleContainer = (props) => {
    const titleClass = props.isShadow ? 'titleContainer shadow' : 'titleContainer'
    let iconPath = props.iconPath && props.iconPath.replace(".png", "_w.png")
    return (
        <div className={titleClass}>
            <div className="container-fluid">
                {props.iconPath ? <figure className="icon">
                    <img src={`/${iconPath}`} alt="" />
                </figure> : null}
                {props.mainTitle ? <h1 className="mainTitle">{props.mainTitle}</h1> : null}
                {props.subTitle ? <h2 className="subTitle">{props.subTitle}</h2> : null}
            </div>

        </div>
    )
}

export default titleContainer;