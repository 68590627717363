import React, { Component } from 'react'
import HistoryModal from './historyModal';
import Calendar from 'react-calendar';
class CalendarPicker extends Component {

    state = {
        indexDay: null,
        isCalenadarHide: false
    }

    onChangeDay = (value) => {
        const indexDay = this.props.history.days.findIndex(day => (new Date(day.date).getDate()) === value.getDate())
        if (!(indexDay === -1)) {
            this.refs.historyModalRef.showModal()
            this.setState({ indexDay })
        }
    }

    tileClassName = (date) => {
        return this.renderDays(date, 'tileClassName')
    }

    tileContent = (date) => {
        return this.renderDays(date, 'tileContent')
    }

    renderDays = (date, fun) => {

        if (this.props.history.days) {
            for (const day of this.props.history.days) {
                const dayObj = new Date(day.date);
                if (date.getDate() !== dayObj.getDate() || (date.getMonth() + 1) !== dayObj.getMonth() + 1) {//
                    continue;
                }
                if (fun === 'tileClassName' && day.conflict) {
                    return 'conflict'
                }
                if (fun === 'tileContent') {
                    return this.renderDay(day)
                }
            }
        }

    }

    renderDay = (day) => {
        let cellClass = this.getClassName(day);
        return <div className={cellClass}>
            <div className="base"></div>
            <div className="status">{day.secondaryStatusDeterminedName}</div>
            <div className="badgesContainer">
                <div className="badges fileBadge">
                    <img src="/img/fileIcon.png" alt="" />
                </div>
                <div className="badges noteBadge">
                    <img src="/img/noteIcon.png" alt="" />
                </div>
            </div>
        </div>
    }

    getClassName = (day) => {
        let classNames = ['cell'];
        if (day.inBase) classNames.push('hasBase')
        else classNames.push('hasStatus')
        if (day.note) classNames.push('hasNote')
        if (day.fileName1 || day.fileName2) classNames.push('hasFile')
        return classNames.join(' ');
    }

    arrowClick = (value) => {
        this.setState({
            isCalenadarHide: true
        })
        setTimeout(() => {
            this.setState({
                isCalenadarHide: false
            })
        }, 500)
        this.props.fetchData(value.activeStartDate.getMonth() + 1, value.activeStartDate.getFullYear())

    }

    generateCalendarClass = () => {
        let classNames = ['calendar'];
        if (this.props.pageType === 'commander') classNames.push('commander');
        if (this.state.isCalenadarHide) classNames.push('isCalenadarHide')
        return classNames.join(' ');
    }

    render() {
        return (
            <>
                {
                    <div className={this.generateCalendarClass()}>
                        {this.props.pageType === 'commander' && <div className="titleName">היסטוריית דיווחים עבור {this.props.activeUser.firstName} {this.props.activeUser.lastName}</div>}
                        <Calendar
                            //activeStartDate={(new Date().getMonth() === this.props.previousMonth && new Date().getFullYear() === this.props.previousYear && this.props.previousMonth && this.props.previousYear) ? new Date(this.props.previousYear, 0, this.props.previousMonth) : new Date()}
                            maxDate={new Date()}
                            onClickDay={(value) => this.onChangeDay(value)}
                            value={this.props.previousMonth && this.props.previousYear && new Date().getMonth() === this.props.previousMonth && new Date().getFullYear() === this.props.previousYear ? this.state.date : new Date()}
                            locale="he-IL"
                            calendarType="hebrew"                            
                            tileContent={({ date }) => this.tileContent(date)}
                            tileClassName={({ date }) => this.tileClassName(date)}
                            onActiveStartDateChange={(value) => this.arrowClick(value)}
                            showNeighboringMonth={false}
                        />
                    </div>
                }

                <HistoryModal
                    ref="historyModalRef"
                    isCommanderState={false}
                    pageType={this.props.pageType}
                    days={this.props.history.days}
                    indexDay={this.state.indexDay}
                    setDateToUploadFiles={(user) => this.props.setDateToUploadFiles(user)}
                    contentManagment={this.props.contentManagment} />

            </>
        )
    }
}

export default CalendarPicker;