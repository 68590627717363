import React, { Component } from 'react'
import Calendar from 'react-calendar';
import FutureModal from './futureModal';
import { getDayName } from '../../shared/utility';
class CalendarFuturePicker extends Component {

    state = {
        indexDay: null,
        isCalenadarHide: false
    }

    onClickDay = (value) => {
        if (!this.props.future.isWeekendNachsalReportActive && (getDayName(value) === 'שישי' || getDayName(value) === 'שבת')) return
        const indexDay = this.props.future.days.findIndex(day => (new Date(day.date).getDate()) === value.getDate())
        if (!(indexDay === -1)) {
            this.refs.futureModalRef.showModal()
            this.setState({ indexDay })
        }
        else this.props.goToPrimaries(value);
    }

    tileClassName = (date) => {
        return this.renderDays(date, 'tileClassName')
    }

    tileContent = (date) => {
        return this.renderDays(date, 'tileContent')
    }

    renderDays = (date, fun) => {
        if (this.props.future && this.props.future.days) {
            for (const day of this.props.future.days) {
                const dayObj = new Date(day.date);
                if (date.getDate() !== dayObj.getDate() || (date.getMonth() + 1) !== dayObj.getMonth() + 1) {//
                    continue;
                }
                if (fun === 'tileContent') {
                    return this.renderDay(day)
                }
            }
        }

    }

    renderDay = (day) => {
        if(day){
            return <div className="hasReport">
                <img src="/img/futureCalender.png" alt="" />
            </div>
        }
    }


    arrowClick = (value) => {
        this.setState({
            isCalenadarHide: true
        })
        setTimeout(() => {
            this.setState({
                isCalenadarHide: false
            })
        }, 500)
        this.props.fetchData(value.activeStartDate.getMonth() + 1, value.activeStartDate.getFullYear())

    }

    generateCalendarClass = () => {
        let classNames = ['calendar'];
        if (this.props.pageType === 'commander') classNames.push('commander');
        if (this.state.isCalenadarHide) classNames.push('isCalenadarHide')
        return classNames.join(' ');
    }

    render() {
        let tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        let maxDate = new Date(tomorrow);
        maxDate.setDate(tomorrow.getDate() + 29)
        return (
            <>
                {
                    <div className={this.generateCalendarClass()}>
                        <div className="futureHeader">לתזמון דיווח עתידי נא לבחור תאריך.
                            <div className="futureTitle">ניתן לערוך או למחוק דיווחים שתוזמנו</div>
                        </div>
                        <Calendar
                            defaultActiveStartDate={(new Date().getMonth() === this.props.previousMonth && new Date().getFullYear() === this.props.previousYear && this.props.previousMonth && this.props.previousYear) ? new Date(this.props.previousYear, 0, this.props.previousMonth) : new Date()}
                            minDate={tomorrow}
                            maxDate={maxDate}
                            onClickDay={(value) => this.onClickDay(value)}
                            value={this.props.previousMonth && this.props.previousYear && new Date().getMonth() === this.props.previousMonth && new Date().getFullYear() === this.props.previousYear ? this.state.date : new Date()}
                            locale="he-IL"
                            calendarType="hebrew"
                            tileContent={({ date }) => this.tileContent(date)}
                            tileClassName={({ date }) => this.tileClassName(date)}
                            onActiveDateChange={(value) => this.arrowClick(value)}
                            tileDisabled={({ date }) => this.props.isDisableDay(date)}
                        />
                    </div>
                }
               
                <FutureModal
                    ref="futureModalRef"
                    days={this.props.future.days}
                    indexDay={this.state.indexDay}
                    contentManagment={this.props.contentManagment}
                    goToPrimaries={(date) => this.props.goToPrimaries(date)}
                    deleteReport={this.props.deleteReport}
                    gender={this.props.gender} />

            </>
        )
    }
}

export default CalendarFuturePicker;