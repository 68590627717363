import React, { Component } from 'react';
import { connect } from 'react-redux'
import TitleContainer from '../../shared/components/titleContainer';
import SecondariesList from '../../shared/components/secondariesList';
import Loader from '../../shared/components/loader'
import * as wizardAction from '../../store/actions/index';
import * as layoutActions from '../../store/actions/index';
import { urls, getText, calcDiffDays, getFullDate } from '../../shared/utility';


class Secondaries extends Component {

    state = {
        titleText: '',
        selectedSecondaryCode: null,
        selectedSecondaryText: null,
        note: '',
        btnSentReportDisabled: true,
        isFutureReport: false,
        reportDate: new Date()
    }

    componentDidMount() {
        this.setPage()
        this.props.onShowWizardHeader();
    }

    loadText = () => {
        this.setState({
            titleText: getText(this.props.contentManagment.selectSecondaryStatusPrat, 'title', this.props.gender),
        })
    }
    
    setPage = () => {
        const { location } = this.props
        //var isEmergency = this.state.isEmergency;
        if(location && location.state && location.state.reportDate){
            this.setState({
                isFutureReport: true,
                reportDate: location.state.reportDate,
                titleText: "איפה תהיו בתאריך היעד?"
            })
        }
        else this.loadText()
    }

    setSelectedScondary = (code, text) => {
        if (this.props.primarySelection.isEmergency){
            this.setState({
                selectedSecondaryCode: code,
                selectedSecondaryText: text,
                btnSentReportDisabled: false
            })
        }
        let now = Date.now()
        let endTime = new Date(this.props.endReport).getTime()
        if(endTime - now > 0 || this.props.primarySelection.isEmergency || this.state.isFutureReport){
            this.setState({
                selectedSecondaryCode: code,
                selectedSecondaryText: text,
                btnSentReportDisabled: false
            })
        }
        else{
            this.props.history.push(urls.finish)
        }
    }

    setNote = (note) => {
        this.setState({
            note: note
        })
    }

    sendReportHandler = () => {
        let now = Date.now()
        let endTime = new Date(this.props.endReport).getTime()
        if(endTime - now > 0 || this.props.primarySelection.isEmergency || this.state.isFutureReport){
            let selection = {
                primaryCode: this.props.primarySelection.statusCode,
                primaryText: this.props.primarySelection.statusDescription,
                icon: this.props.primarySelection.icon,
                secondaryCode: this.state.selectedSecondaryCode,
                secondaryText: this.state.selectedSecondaryText,
                note: this.state.note,
                isEmergency: this.props.primarySelection.isEmergency,
                isFutureReport: this.state.isFutureReport,
                reportDate: getFullDate(this.state.reportDate)
            }
            const selectedSecondary = this.props.primarySelection.secondaries.find(secondary => secondary.statusCode === this.state.selectedSecondaryCode)
            if (selectedSecondary.hasFiles) {
                this.props.onStoreFullSelection(selection);
                if (this.state.isFutureReport)
                    this.props.history.push({
                        pathname: urls.uploadFiles,
                        state: { reportDate: this.state.reportDate } 
                    });
                else this.props.history.push(urls.uploadFiles);
            } else {
                this.props.onSaveSelection(selection)
                    .then(() => {
                        if (this.state.isFutureReport){
                            this.props.history.push({
                                pathname: urls.futureReport,
                                state: { prevUrl: 'secondaries', reportDate: this.state.reportDate }   
                            })
                        }
                        else if(this.props.isSaveSelection && !this.props.primarySelection.isEmergency)
                            this.props.history.push(urls.finish);
                        else 
                            this.props.history.push(urls.hp);
                    });
            }
        }
        else{
            this.props.history.push(urls.finish)
        }

    }

    render() {
        let secondariesList = '';
        let subTitle = '';

        if (this.props.loader) {
            return <Loader />
        }
        if (this.props.primarySelection) {
            subTitle = this.state.isFutureReport? this.state.reportDate.toLocaleDateString('en-GB') : this.props.primarySelection.statusDescription;

            secondariesList = <SecondariesList
                isUpdateOtherStatus ={this.props.isUpdateOtherStatus}
                secondaries={this.props.primarySelection.secondaries}
                selectedSecondary={(code, text) => this.setSelectedScondary(code, text)}
                setNote={(note) => this.setNote(note)}
                activeIndex={this.props.primarySelection.statusCode}
                isFutureReport={this.state.isFutureReport}
                reportDays={calcDiffDays(this.state.reportDate)}
            />
        }

        return (
            <div className="pageContainer">
                <TitleContainer
                    iconPath={this.props.primarySelection.icon}
                    mainTitle={this.state.titleText}
                    subTitle={subTitle}
                    isShadow={true}
                />
                <section className="secondariesSection">
                    <div className="container-fluid">
                        {secondariesList}
                    </div>
                </section>
                <div className="btnSendReportContainer">
                    <button type="button" className="btnGeneral send" onClick={this.sendReportHandler} disabled={this.state.btnSentReportDisabled}>שליחת דיווח</button>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        contentManagment:state.layout.contentManagment,
        gender: state.wizard.gender,
        loader: state.wizard.loader,
        primarySelection: state.wizard.primarySelection,
        isUpdateOtherStatus: state.commander.isUpdateOtherStatus,
        isSaveSelection: state.wizard.isSaveSelection,
        endReport: state.wizard.timeToReport
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onStoreFullSelection: (fullSelection) => dispatch(wizardAction.storeFullSelection(fullSelection)),
        onSaveSelection: (selection) => dispatch(wizardAction.storeSelection(selection)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Secondaries);