import * as actionTypes from '../actions/actionTypes';
import { groupBy } from '../../shared/utilityCommander';

const initialState = {
    historyPrat: {},
    historyGroup: null,
    usersGroupHistory: null,
    usersHistoryByStatus: null,
    activeTabCode: null,
    activeDate: new Date(),
    activeUser: null,
    filesToUpload: null,
    dateToUploadFiles: null,
    loader: false,

}

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_LOADER_HISTORY:
            return {
                ...state,
                loader: true
            }
        case actionTypes.RESET_STORE:
            return {
                ...initialState
            }
            
        case actionTypes.FETCH_HISTORY_PRAT_SUCCESS:
            return {
                ...state,
                historyPrat: action.historyPrat
            }
        case actionTypes.FETCH_HISTORY_GROUP_SUCCEESS:
            return {
                ...state,
                historyGroup: action.historyGroup,
                usersGroupHistory: action.historyGroup.firstGroup.users,
                usersHistoryByStatus: groupBy(action.historyGroup.firstGroup.users),
                activeTabCode:  action.historyGroup.groupsCodeAndName[1].groupCode,
                loader: false,

            }
        case actionTypes.FETCH_HISTORY_TAB_GROUP_SUCCESS:
            console.log('loadertestSuccess')
            return {
                ...state,
                usersGroupHistory: action.users,
                usersHistoryByStatus: groupBy(action.users),
                activeTabCode: action.activeTabCode,
                loader: false,

            }
            case actionTypes.FETCH_HISTORY_TAB_GROUP_ERROR:
                console.log('loadertestError')
                return {
                    ...state,
                    usersGroupHistory: action.users,
                    usersHistoryByStatus: groupBy(action.users),
                    activeTabCode: action.activeTabCode,
                    loader: false,
    
                }
        case actionTypes.SET_PREV_DAY:
            const prevDate = new Date(state.activeDate.setDate(state.activeDate.getDate() - 1))
            return {
                ...state,
                activeDate: prevDate
            }
        case actionTypes.SET_NEXT_DAY:
            const nextDate = new Date(state.activeDate.setDate(state.activeDate.getDate() + 1))
            return {
                ...state,
                activeDate: nextDate
            }
        case actionTypes.SET_ACTIVE_USER:
            return {
                ...state,
                activeUser: action.user
            }
        case actionTypes.STORE_FILES_HISTORY:
            return {
                ...state,
                filesToUpload: action.files
            }

        case actionTypes.SET_DATE_TO_UPLOAD_FILES:
            return {
                ...state,
                dateToUploadFiles: action.date,
                filesToUpload: null
            }
        default:
            return state;
    }
}

export default historyReducer;