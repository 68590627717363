import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { Link } from 'react-router-dom'
import { urls, getText } from '../../shared/utility' 
class LoginCommander extends Component {


    state = {
        error: false,
        errorText: '',
        errorBtn: ''
    }

    componentDidMount() {
        this.props.clearStateCommander();
        if (this.props.isCommanderAuthenticated) {
            this.props.history.push(urls.hp);
        }
        else
            this.loginCommander();
        
        this.loadText();
    }

    loadText = () => {
        let errorTxt = getText(this.props.contentManagment.commanderLoginError, 'TextError', this.props.gender);
        let errorBtn = getText(this.props.contentManagment.commanderLoginError, 'BtnError', this.props.gender);
        this.setState({
            errorText: errorTxt? errorTxt : 'אין לך הרשאות מפקד',
            errorBtn: errorBtn? errorBtn : 'חזור להתחלה',
        })
    }

    componentDidUpdate(prevProps) {
        if ((this.props.serverErrorMsg !== prevProps.serverErrorMsg) && prevProps.serverErrorMsg===null) {
            this.setState({error: this.props.serverErrorMsg })
        }
    }

    loginCommander = () => {
        this.props.onAuth(this.props.isUserAuth)
            .then((res) => {
                if (res.isCommanderAuth) {
                    this.props.history.push('/commander');
                }
                else {
                    this.setState({error: true})
                }
            })
    }

    render() {
        return (
            <div>
                {this.state.error &&
                <section className="serverErrorSection">
                    <div className="circleBox">
                        <figure>
                            <img src="/img/b.png" alt="" />
                        </figure>
                        <div className="text">
                            <div>מצטערים,</div>
                            <div>{this.state.errorText}</div>
                        </div>
                    </div>
                    <div className="linkContainer">
                        <Link to="/" className="btnGeneral send">{this.state.errorBtn}</Link>
                    </div>
                    
                </section>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isCommanderAuthenticated: state.auth.isCommanderAuth,
        isUserAuth: state.auth.isUserAuth,
        serverErrorMsg: state.auth.error,
        contentManagment: state.layout.contentManagment
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearStateCommander:()=>dispatch(actions.clearStateCommander()),
        onAuth: (isUserAuth) => dispatch(actions.authCommander(isUserAuth))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCommander); 