import React from "react";
import { Route, Redirect } from "react-router-dom";

const LoginRoute = ({ component: Component, ...rest }) => {
	const handleRedirect = (props, rest) => {
		let o_response = <Component {...props} />;

		if (rest.isUserAuthenticated
			&& window.location.pathname.toLowerCase().substr(0, '/login'.length).toLowerCase() === '/login'
		) {
			o_response = <Redirect to="/home" />
		}

		return o_response;
	}

	const renderRoute = (props, rest) => {
		return handleRedirect(props, rest);
	};

	return <Route exact {...rest} render={props => renderRoute(props, rest)} />;
};
export default LoginRoute;
