import * as actionTypes from './actionTypes'
import axios from 'axios'


export const fetchFutureReports = (month, year) => {
    
    const futureParam = 
    {
        month: month,
        year: year
    }
    const url = `/api/Attendance/getFutureReport`
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.post(url,futureParam)
                .then(res => {
                    resolve();
                    dispatch(fetchFutureReportSuccess(res.data))
                })
                .catch(err => {
                    reject(err)
                    console.log(err)
                })
        })
    }
}

const fetchFutureReportSuccess = (futureReport) => {
    return {
        type: actionTypes.FETCH_FUTURE_REPORT_SUCCESS,
        futureReport
    }
}


export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER_FUTURE
    }
}


export const deleteFutureReports = (date) => {
    const url = `/api/Attendance/deleteFutureReport?dateToDelete=${date}`
    return dispatch => {
        axios.post(url)
            .then(res => {
                if(res.data)
                    dispatch(deleteFutureReportSuccess(res.data))
            })
            .catch(err => {
                console.log(err)
            })
    }
}

const deleteFutureReportSuccess = (date) => {
    return {
        type: actionTypes.DELETE_FUTURE_REPORT_SUCCESS,
        date
    }
}