import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as wizardAction from '../../store/actions/index';
import * as layoutActions from '../../store/actions/index';
import { getTime, urls, getText, isValidReportTime } from '../../shared/utility';
import ExplanationModal from '../../shared/components/explanationModal';
import BtnFutureReport from '../../shared/components/btnFutureReport';
import ChangeableBtn from '../../shared/components/ChangeableBtn';

class Finish extends Component {

    state = {
        cantSendReportAfterText: '',
        cantSendReportTodayText: '',
        commanderApprovePersonalText: '',
        determinedAndPersonalReportedApproveNotCommanderText: '',
        determinedAndPersonalReportedConflictNotCommanderText: '',
        determinedApproveCommanderText: '',
        determinedConflictCommanderText: '',
        commanderConflictPersonalText: '',
        determinedReportedText: '',
        personalReportSentText: '',
        reportCommanderText: '',
        reportStatusMsgText: '',
        reportStatusUrlText: '',
        firstEntry: false,
    }

    componentDidMount() {
        this.loadText()
        this.props.onHideWizardHeader();
        this.props.onFecthReportedData()
        if (Object.getOwnPropertyNames(this.props.reportedData).length === 0) {
            this.props.history.push(urls.hp);
        }
        const { location } = this.props
        if (location.state) {
            this.setState({ firstEntry: true });
        }
    }



    loadText = () => {
        this.setState({
            cantSendReportAfterText: getText(this.props.contentManagment.blockReportingAfterHour, 'CantSendReportAfter', this.props.gender),
            cantSendReportTodayText: getText(this.props.contentManagment.blockReportingAfterHour, 'CantSendReportToday', this.props.gender),
            commanderApprovePersonalText: getText(this.props.contentManagment.summaryScreen, 'commanderApprovePersonal', this.props.gender),
            determinedAndPersonalReportedApproveNotCommanderText: getText(this.props.contentManagment.summaryScreen, 'determinedAndPersonalReportedApproveNotCommander', this.props.gender),
            determinedAndPersonalReportedConflictNotCommanderText: getText(this.props.contentManagment.summaryScreen, 'determinedAndPersonalReportedConflictNotCommander', this.props.gender),
            determinedApproveCommanderText: getText(this.props.contentManagment.summaryScreen, 'determinedApproveCommander', this.props.gender),
            determinedConflictCommanderText: getText(this.props.contentManagment.summaryScreen, 'determinedConflictCommander', this.props.gender),
            commanderConflictPersonalText: getText(this.props.contentManagment.summaryScreen, 'commanderConflictPersonal', this.props.gender),
            determinedReportedText: getText(this.props.contentManagment.summaryScreen, 'determinedReported', this.props.gender),
            personalReportSentText: getText(this.props.contentManagment.summaryScreen, 'personalReportSent', this.props.gender),
            reportCommanderText: getText(this.props.contentManagment.summaryScreen, 'reportCommander', this.props.gender),
            reportStatusMsgText: getText(this.props.contentManagment.reportedStatus, 'statusMsg', this.props.gender),
            reportStatusUrlText: getText(this.props.contentManagment.reportedStatus, 'statusLink', this.props.gender)
        })
    }

    getCurrentTime = () => {
        const dateTime = new Date();
        const hours = ('0' + dateTime.getHours()).slice(-2);
        const minutes = ('0' + dateTime.getMinutes()).slice(-2);
        return `${hours}:${minutes}`;
    }

    setReportRow = (type, title, mainText, secondaryText) => {
        const classes = type === 'commander' ? 'reportRow commander' : 'reportRow personal';
        return (
            <div className={classes}>
                <span className="titleReported">{title}</span>
                <span className="txt">{mainText}</span>
                <span className="txt">{secondaryText}</span>
            </div>
        )
    }

    changeReport = () => {
        if (this.props.reportedData.endReport && isValidReportTime(this.props.reportedData)) {
            this.props.onChangeReport(true);
            this.props.history.push(urls.hp);
        }
    }

    goToEmergencyPrimaries = () => {
        this.props.history.push({
            pathname: urls.primaries,
            state: { prevUrl: "emergency" }
        });
    }

    goToCalendar = () => {
        this.props.history.push(urls.futureReport)
    }

    render() {
        let finishStatusText = this.state.personalReportSentText//'הדיווח שלך נשלח בהצלחה ב-'
        let finishStatusClasses = 'finishStatus succsess'
        let finishBoxClass = 'finishBox'
        let updateTimeText = ''
        let reporterTag = (
            <div className="reporterTag">דיווח אישי</div>)
        let icon = ''
        let bottomNote = ''
        let reportedMainText = ''
        let reportedSecondaryText = ''
        let reportedStatusMsg = ''
        let reportedStatusUrl = ''
        let reportedStatusLink = ''
        let changeReportBtn = ''
        let reportRowCommander = ''
        let reportRowPersonal = ''
        let finishView = ''
        let isMobile = window.innerWidth < 768
        let explanationPopUp = ''
        let emergencyReport = ''
        let emergencyBtn = ''
        let isEmergenctActive = this.props.showEmergencyPractice || this.props.showEmergencyBtn
        let emergencyIcon = <img className="icon" src='img/emergencyBtn.png' alt="" />
        let emergencyPracticeIcon = <img className="icon" src='img/emergencyPractice.png' alt="" />
        let changeableUrl = getText(this.props.contentManagment.changeableBtn, 'link', this.props.gender)
        let btnName = getText(this.props.contentManagment.changeableBtn, 'btnName', this.props.gender)

        let futureReportBtn = (
            <div className="futureReportBtn">
                <BtnFutureReport
                    className="finishReportBtn"
                    disableFutureReport={this.props.disableFutureReport}
                    fetchBtnMode={() => this.props.onFetchFutureMode()}
                />
            </div>
        )
        let coronaLink = (
            <ChangeableBtn
                className="finishReportBtn"
                disableChangeabltBtn={this.props.disableChangeabltBtn}
                disableCoronaIcon={this.props.disableCoronaIcon}
                btnText={btnName}
                url={changeableUrl}
                onAddClickToCounter={() => this.props.onAddClickToCounter()}
            />
        );

        if (this.props.reportedData.reported) {
            let reportedData = this.props.reportedData;
            updateTimeText = getTime(reportedData.updatedOn);
            icon = reportedData.icon;
            reportedMainText = reportedData.mainTextReported;
            reportedSecondaryText = reportedData.secondaryTextReported;

            //emergency report 

            if (isEmergenctActive && this.props.reportedData.emergencyMainStatus && this.props.reportedData.emergencySecondaryStatus) {
                emergencyReport = (
                    <div>
                        <div className="finishEmergency">
                            דיווח חירום נשלח בהצלחה ב
                            <span>{getTime(this.props.reportedData.emergencyUpdateOn)}</span>
                        </div>
                    </div>
                )
            }
            //final reported
            if (reportedData.finalMainCode && reportedData.finalSecondaryCode) {
                finishStatusText = this.state.determinedReportedText//'התקבל דיווח קובע ב-'
                reporterTag = (
                    <div className="reporterTag final">דיווח קובע</div>
                )
                reportedMainText = reportedData.mainStatusTextFinal;
                reportedSecondaryText = reportedData.secondaryStatusTextFinal;
                coronaLink = '';

                //personal reported
                if (reportedData.mainStatusReported && reportedData.secondaryStatusReported) {
                    finishBoxClass += ' duplicateReport'
                    reportRowPersonal = this.setReportRow('personal', 'דיווח אישי', reportedData.mainTextReported, reportedData.secondaryTextReported);

                    //conflict final reported and personal reported
                    if (reportedData.finalMainCode !== reportedData.mainStatusReported || reportedData.finalSecondaryCode !== reportedData.secondaryStatusReported) {
                        finishStatusClasses = 'finishStatus error';
                        finishStatusText = this.state.determinedAndPersonalReportedConflictNotCommanderText//'התקבל דיווח קובע שונה ב-';
                    }
                }

                //commander reported
                if (reportedData.approvedMainCode && reportedData.approvedSecondaryCode) {
                    finishBoxClass += ' duplicateReport'
                    reportRowCommander = this.setReportRow('commander', 'דיווח מפקד', reportedData.mainStatusTextApproved, reportedData.secondaryStatusTextApproved);

                    //conflict final reported and commander reported
                    if (reportedData.finalMainCode !== reportedData.approvedMainCode || reportedData.finalSecondaryCode !== reportedData.approvedSecondaryCode) {
                        finishStatusClasses = 'finishStatus error';
                        finishStatusText = this.state.determinedConflictCommanderText//'התקבל דיווח קובע שונה ב-';
                    }
                }

                coronaLink = '';

            }

            //commander report
            else if (reportedData.approvedMainCode && reportedData.approvedSecondaryCode) {

                reportedMainText = reportedData.mainStatusTextApproved;
                reportedSecondaryText = reportedData.secondaryStatusTextApproved;
                finishStatusText = this.state.reportCommanderText//'התקבל דיווח מפקד ב- '
                reporterTag = (
                    <div className="reporterTag commander">דיווח מפקד</div>
                )
                //personal reported
                if (reportedData.mainStatusReported && reportedData.secondaryStatusReported) {
                    finishBoxClass += ' duplicateReport'
                    reportRowPersonal = this.setReportRow('personal', 'דיווח אישי', reportedData.mainTextReported, reportedData.secondaryTextReported);
                    //conflict
                    if ((reportedData.approvedMainCode !== reportedData.mainStatusReported) || (reportedData.approvedSecondaryCode !== reportedData.secondaryStatusReported)) {
                        finishStatusClasses = 'finishStatus error';
                        finishStatusText = this.state.commanderConflictPersonalText//'התקבל דיווח מפקד שונה ב- '
                    } else {
                        //finishStatusClasses = 'finishStatus error';
                        finishStatusText = this.state.commanderApprovePersonalText//'התקבל דיווח מפקד זהה ב- '
                    }
                    coronaLink = ''
                }

            }

            //first login after reported
            else if (this.props.selection.primaryCode === null) {
                icon = reportedData.icon
                reportedMainText = reportedData.mainTextReported
                reportedSecondaryText = reportedData.secondaryTextReported
                updateTimeText = getTime(reportedData.updatedOn)

                if (reportedData.showStatusMsg) {
                    reportedStatusMsg = this.state.reportStatusMsgText;
                    reportedStatusUrl = this.state.reportStatusUrlText;
                }
            }
            //user change report
            else if (reportedData.finalMainCode === null && reportedData.approvedMainCode === null && !this.props.selection.isEmergency) {
                icon = this.props.selection.icon
                reportedMainText = this.props.selection.primaryText
                reportedSecondaryText = this.props.selection.secondaryText
                updateTimeText = this.getCurrentTime();

                if (reportedData.showStatusMsg) {
                    reportedStatusMsg = this.state.reportStatusMsgText;
                    reportedStatusUrl = this.state.reportStatusUrlText;
                }
            }

            // status link
            if (reportedStatusUrl) {
                let link = reportedStatusUrl.startsWith("http") ? reportedStatusUrl : "https://" + reportedStatusUrl
                reportedStatusLink = (
                    <a className="statusLink" onClick={() => {
                        if (!isMobile || /android/i.test(navigator.userAgent)) {
                            window.open(link, "_blank");
                        }
                        else {
                            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                                window.location.assign(link, "_system", 'location=yes')
                            }
                            else {
                                window.webkit.messageHandlers.appJSBridge.postMessage({
                                    "actionType": "web",
                                    "link": link
                                });
                            }

                        }
                    }}
                    > לחץ כאן</a>
                )
            }

            //final reported 
            if ((reportedData.finalMainCode && reportedData.finalSecondaryCode)) {
                bottomNote = (
                    <div className="bottomNote">לא ניתן לשנות דיווח לאחר קבלת דיווח קובע</div>)
            }//  commander reported
            else if ((reportedData.approvedMainCode && reportedData.approvedSecondaryCode)) {
                bottomNote = (
                    <div className="bottomNote">לא ניתן לשנות דיווח לאחר קבלת דיווח אחראי</div>)
            }   //personal reported
            else if (!isValidReportTime(reportedData.endReport)) {
                bottomNote = (
                    <div className="bottomNote">
                        לא ניתן לשנות דיווח לאחר השעה
                        <span> {getTime(reportedData.endReport)}</span>
                    </div>
                )
            }


        }
        //no reported
        else {
            //cantReport
            if (this.props.reportedData.cantReport) {
                if (this.props.showEmergencyBtn) {
                    emergencyBtn = <button type="button" className="emergencyLink" onClick={this.goToEmergencyPrimaries}>נכס"ל בחירום</button>
                }
                else if (this.props.showEmergencyPractice) {
                    emergencyBtn = <button type="button" className="emergencyLink practice" onClick={this.goToEmergencyPrimaries}>דיווח נכס"ל</button>
                }
                //over time to report
                if (!isValidReportTime(this.props.reportedData)) {
                    if (isEmergenctActive) {
                        if (this.props.showEmergencyBtn)
                            emergencyBtn = <button type="button" className="emergencyBtn finish" onClick={() => this.goToEmergencyPrimaries()}>{emergencyIcon} נכס"ל בחירום</button>
                        else if (this.props.showEmergencyPractice)
                            emergencyBtn = <button type="button" className="emergencyBtn practice finish" onClick={() => this.goToEmergencyPrimaries()}>{emergencyPracticeIcon}דיווח נכס"ל</button>

                        if (this.props.reportedData.emergencyMainStatus && this.props.reportedData.emergencySecondaryStatus) {
                            emergencyReport = (
                                <div>
                                    <div className="finishEmergency emergencySuccess">
                                        דיווח חירום נשלח בהצלחה ב
                                        <span>{getTime(this.props.reportedData.emergencyUpdateOn)}</span>
                                    </div>
                                </div>
                            )
                        }
                    }

                    finishView = (
                        <div>
                            {emergencyReport}
                            <div className="circleBox">
                                <figure>
                                    <img src="/img/banana.png" alt="" />
                                </figure>
                                <div className="text">
                                    <span>{this.state.cantSendReportAfterText}</span>
                                    <span> {getTime(this.props.reportedData.endReport)}</span>
                                </div>
                            </div>
                            {emergencyBtn}
                        </div>
                    )
                }
                else {
                    let lastReportText = !updateTimeText ? "" : (
                        <Fragment>
                            <div className={finishStatusClasses}>
                                <div className="icon "></div>
                                {finishStatusText}
                                <span>{updateTimeText}</span>
                            </div>
                        </Fragment>
                    )

                    let _emergencyUpdateOn = getTime(this.props.reportedData.emergencyUpdateOn)
                    let emergencyUpdateOnText = !_emergencyUpdateOn ? "" : (
                        <Fragment>
                            <div>
                                <div className="finishEmergency">
                                    דיווח חירום נשלח בהצלחה ב
                                    <span>{(_emergencyUpdateOn)}</span>
                                </div>
                            </div>
                        </Fragment>
                    )

                    finishView = (
                        <Fragment>
                            {lastReportText}
                            {emergencyUpdateOnText}
                            {emergencyBtn}
                            <div className="circleBox">
                                <figure>
                                    <img src="/img/tent.png" alt="" />
                                </figure>
                                <div className="text">
                                    <span>{this.state.cantSendReportTodayText}</span>
                                </div>

                            </div>
                        </Fragment>
                    )

                    futureReportBtn = '';
                }
                coronaLink = '';
            }
            //first time to report
            else if (this.props.selection) {
                icon = this.props.selection.icon
                reportedMainText = this.props.selection.primaryText
                reportedSecondaryText = this.props.selection.secondaryText
                updateTimeText = this.getCurrentTime();
            }

        }
        //changeReport
        if (isValidReportTime(this.props.reportedData) && (!this.props.reportedData.cantReport)
            && (!this.props.reportedData.approvedMainCode && !this.props.reportedData.approvedSecondaryCode)
            && (!this.props.reportedData.finalMainCode && !this.props.reportedData.finalSecondaryCode)) {
            changeReportBtn = (
                <button type="button" className="link" onClick={this.changeReport}>שינוי דיווח</button>
            )
        }

        if (this.props.reportedData.reported || this.props.selection.primaryCode && !this.props.reportedData.cantReport) {
            if (this.props.showEmergencyBtn)
                emergencyBtn = <button type="button" className="emergencyLink" onClick={this.goToEmergencyPrimaries}>נכס"ל בחירום</button>
            else if (this.props.showEmergencyPractice)
                emergencyBtn = <button type="button" className="emergencyLink practice" onClick={this.goToEmergencyPrimaries}>דיווח נכס"ל</button>
            finishView = (
                <Fragment>
                    <div className={finishStatusClasses}>
                        <div className="icon "></div>
                        {finishStatusText}
                        <span>{updateTimeText}</span>
                    </div>
                    {emergencyReport}
                    {emergencyBtn}
                    <div className={finishBoxClass}>
                        {reporterTag}
                        <div className="inner">
                            <figure className="icon">
                                <img src={`/${icon}`} alt="" />
                            </figure>
                            <div className="title">{reportedMainText}</div>
                            <div className="text">{reportedSecondaryText}</div>
                            {changeReportBtn}
                        </div>

                        <div className="statusMsg">
                            <div className="content">{reportedStatusMsg}</div>
                            {reportedStatusLink}
                        </div>

                        <div className="reportRowContainer">
                            {reportRowCommander}
                            {reportRowPersonal}
                        </div>
                    </div>
                </Fragment>
            )
        }

        if (this.state.firstEntry && this.props.showExplanationPopUp) {
            explanationPopUp = (<ExplanationModal
                title={getText(this.props.contentManagment.updates, 'explanationTitle', this.props.gender)}
                content={getText(this.props.contentManagment.updates, 'explanationContent', this.props.gender)}
                link={getText(this.props.contentManagment.updates, 'explanationLink', this.props.gender)} />)

        }

        return (
            <section className="finishSection">
                <div className="container-fluid">
                    <div className="finishContainer">
                        {explanationPopUp}
                        <div className="finishTitle">
                            שלום,
                            <span> {this.props.reportedData.firstName} </span>
                        </div>
                        {finishView}
                    </div>
                </div>

                {futureReportBtn}

                {coronaLink}

                {bottomNote}
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        selection: state.wizard.selection,
        reportedData: state.wizard.reportedData,
        showExplanationPopUp: state.wizard.showExplanationPopUp,
        showEmergencyBtn: state.wizard.showEmergencyBtn,
        showEmergencyPractice: state.wizard.showEmergencyPractice,
        disableFutureReport: state.wizard.disableFutureReport,
        disableChangeabltBtn: state.wizard.disableChangeabltBtn,
        disableCoronaIcon: state.wizard.disableCoronaIcon
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFecthReportedData: () => dispatch(wizardAction.fecthReportedData()),
        onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
        onChangeReport: (bool) => dispatch(wizardAction.changeReport(bool)),
        onFetchFutureMode: () => dispatch(wizardAction.fetchFutureMode()),
        onAddClickToCounter: () => wizardAction.addClickToCounter()
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Finish); 