import * as actionTypes from './actionTypes';
import axios from 'axios'


export const fetchSetting = () => {

    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.get('/api/settings/getPratSettings')
                .then(res => {
                    dispatch(fetchSettingSuccsess(res.data));
                    resolve(res);
                })
                .catch(error => {
                    dispatch(fetchSettingFailed());
                    reject(error);
                });
        })

    }
}

const fetchSettingSuccsess = (data) => {
    return {
        type: actionTypes.FETCH_SETTING_SUCCESS,
        data
    }
}

const fetchSettingFailed = (error) => {
    return {
        type: actionTypes.FETCH_SETTING_FAILED,
        error
    }
}

export const fetchCommaderSetting = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.get('/api/settings/getCommanderSettings')
            .then(res => {    
                dispatch(fetchSettingSuccsess(res.data));
                resolve(res);
            })
            .catch(error => {
                dispatch(fetchSettingFailed());
                reject(error);
            });
        })
    }
}
