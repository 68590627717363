import React, { Component } from 'react';
import BtnRadioRow from './btnRadioRow';
import WarningModal from './warningModal';

class SecondariesList extends Component {

    state = {
        activeIndex: '',
        notifications: []
    }

    componentDidUpdate(prevProps) {
        if (this.props.secondaries !== prevProps.secondaries) {
            this.closeNoteHandler();
        }
    }


    setSecondary = (code, selectedSecondary) => {
        
        this.props.selectedSecondary(code, selectedSecondary.statusDescription);
        if (!selectedSecondary.notifications) return;
        const notifications = Object.values(selectedSecondary.notifications)
        this.setState({
            notifications
        })
        if (notifications.every(notification => !notification || notification === '')) {
            this.refs.warningModal.handleModalClose()
        }
        else if (notifications.some(notification => notification !== null)) {
            this.refs.warningModal.handleModalShow()
        }

    }

    setNote = (note) => {
        this.props.setNote(note)
    }

    expandRowHadler = (e, index) => {
        e.stopPropagation();
        this.setState({
            activeIndex: index
        })
    }

    closeNoteHandler = (index) => {
        if (index !== this.state.activeIndex) {
            this.setState({
                activeIndex: null
            })
        }
    }

    render() {
        let activeClass = '';
        let newSecondaries = this.props.secondaries.map((sec) => {
            return {
                ...sec,
                statusCode: `${sec.statusCode}-${this.props.activeIndex}`
            }
        })

        let secondariesList = newSecondaries.map((secondary, index) => {
            activeClass = this.state.activeIndex === index ? 'btnRadioRow active' : 'btnRadioRow'
            if (this.props.isFutureReport && secondary.futureReportDays < this.props.reportDays)
                activeClass += " disabled"
            return (
                <BtnRadioRow
                    isUpdateOtherStatus={this.props.isUpdateOtherStatus}
                    secondaryItem={secondary}
                    index={index}
                    key={secondary.statusCode}
                    setSelectedSecondary={this.setSecondary}
                    setNoteFromUser={(note) => this.setNote(note)}
                    expandRow={(event, index) => this.expandRowHadler(event, index)}
                    closeNote={(index) => this.closeNoteHandler(index)}
                    rowClass={activeClass}
                    activeIndexPrimary={this.props.activeIndex}
                />
            )

        })

        return (
            <>
                <ul className="secondariesList">
                    {secondariesList}
                </ul>
                <WarningModal notifications={this.state.notifications} ref="warningModal" />
            </>
        )
    }
}
export default SecondariesList;