import * as actionTypes from '../actions/actionTypes';
const initialState = {
    isWizardHeader: false,
    isMobile: false,
    contentManagment: null,
}

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STORE:
            return {
                ...initialState
            }
            
        case actionTypes.SHOW_WIZARD_HEADER: {
            return { ...state, isWizardHeader: true }
        }
        case actionTypes.HIDE_WIZARD_HEADER: {
            return { ...state, isWizardHeader: false }
        }
        case actionTypes.CHECK_IS_MOBILE: {
            return {
                ...state,
                isMobile: action.isMobile
            }
        }
        case actionTypes.GET_CONTENT_MANAGMENT_SUCCESS:
            return {
                ...state,
                contentManagment: action.contentManagment
            }
        default:
            return state;
    }
}

export default layoutReducer;