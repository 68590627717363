import * as actionTypes from './actionTypes'
import axios from 'axios'
import { checkIfEmptyNameAndAddNameToGroup } from '../../shared/utilityCommander'


const initNotReportedUsers = (users) => {
    return users.map(user => {
        if (user.approvedMainName === null) {
            return {
                ...user,
                approvedMainName: 'ללא דיווח',
                approvedSecondaryName: ''
            }
        }
        return user
    })
}

export const fetchHistoryPrat = (month, year, mi, groupCode) => {
    
    const historyParam = mi ? {
        month: month,
        year: year,
        mi: mi,
        groupCode: groupCode
    } :
    {
        month: month,
        year: year
    }
    const url = `/api/Attendance/memberHistory`
    return dispatch => {
        axios.post(url,historyParam)
            .then(res => {
                dispatch(fetchHistoryPratSuccess(res.data))
            })
            .catch(err => {
                //console.log(err)
            })
    }
}

const fetchHistoryPratSuccess = (historyPrat) => {
    return {
        type: actionTypes.FETCH_HISTORY_PRAT_SUCCESS,
        historyPrat
    }
}

export const fetchHistoryGroup = () => {

    return dispatch => {
        axios.get('/api/attendance/GetGroupsToAuthHistory')
            .then(res => {
                const allGroups = {groupCode:'כל הקבוצות',groupName:'כל הקבוצות'}
                const updateData = {
                    ...res.data,
                    firstGroup: {
                        ...res.data.firstGroup,
                        users: initNotReportedUsers(res.data.firstGroup.users)
                    },
                    groupsCodeAndName: [allGroups, ...checkIfEmptyNameAndAddNameToGroup(res.data.groupsCodeAndName)]
                }
                dispatch(fetchHistoryGroupSuccess(updateData));
            })
            .catch(err => {
                //console.log(err)
            })
    }
}

const fetchHistoryGroupSuccess = (historyGroup) => {
    return {
        type: actionTypes.FETCH_HISTORY_GROUP_SUCCEESS,
        historyGroup
    }
}


export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER_HISTORY
    }
}


export const fetchHistoryTabGroup = (gCode, date) => {
    return dispatch => {
        dispatch(showLoader());
        const SearchData = {
            groupCode:gCode,
            day: date.getDate(),
            month: date.getMonth()+1,
            year: date.getFullYear(),
            
        }

        const url = gCode === 'כל הקבוצות' ? `/api/attendance/GetAllGroupUsers`
            : `/api/attendance/GetGroupUsersToAuthHistory`;
        axios.post(url,SearchData)
            .then(res => {
                const updateData = { ...res.data, users: initNotReportedUsers(res.data.users) }
                dispatch(fetchHistoryTabGroupSuccess(gCode, updateData));
            })
            .catch(err => {
                dispatch(fetchHistoryTabGroupFail(err));
           
                //console.log(err)
            })

    }
}

const fetchHistoryTabGroupSuccess = (gCode, data) => {
    return {
        type: actionTypes.FETCH_HISTORY_TAB_GROUP_SUCCESS,
        users: data.users,
        activeTabCode: gCode,
    }
}
const fetchHistoryTabGroupFail = (err) => {
    return {
        type: actionTypes.FETCH_HISTORY_TAB_GROUP_ERROR
        
    }
}

export const setPrevDay = (activeTab, activeDate) => {
    const prevDate = new Date(activeDate.setDate(activeDate.getDate() - 1))
    return dispatch => {
        return new Promise((resolve, reject) => {
            try {
                dispatch(fetchHistoryTabGroup(activeTab, prevDate))
                resolve()
                // return {
                //     type: actionTypes.SET_PREV_DAY
                // }
            }
            catch (e) {
                reject()
                //console.log(e);
            }
        })

    }

}

export const setNextDay = (activeTab, activeDate) => {
    const nextDate = new Date(activeDate.setDate(activeDate.getDate() + 1))
    return dispatch => {
        return new Promise((resolve, reject) => {
            try {
                dispatch(fetchHistoryTabGroup(activeTab, nextDate))
                resolve()
                // return {
                //     type: actionTypes.SET_NEXT_DAY
                // }
            }
            catch (e) {
                reject()
                //console.log(e);
            }
        })

    }
}

export const setActiveUser = (user) => {
    return {
        type: actionTypes.SET_ACTIVE_USER,
        user
    }
}

export const storeFilesHistory = (files) => {
    return {
        type: actionTypes.STORE_FILES_HISTORY,
        files: files
    }
}

export const setDateToUploadFiles = (date) => {
    return {
        type: actionTypes.SET_DATE_TO_UPLOAD_FILES,
        date
    }
}

export const updateFilesAndDates = (selection) => {
    const formCollection = new FormData();

    if (selection.hasOwnProperty('files')) {
        formCollection.append('File1',selection.files.length > 0 && selection.files[0]);
        formCollection.append('File2',selection.files.length > 0 && selection.files[1]);
        formCollection.append('Date', new Date(selection.date).toISOString())
        formCollection.append('StartDate', selection.startDate.toISOString())
        formCollection.append('EndDate', selection.endDate.toISOString())
    }

    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.post('api/File/UpdateFileToDailyStatus', formCollection)//
                .then(res => {
                    resolve(res.data);

                })
                .catch(error => {
                    reject(error);

                });
        })
    }

}

