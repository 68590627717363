import axios from 'axios'
import * as actionTypes from './actionTypes';

export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER_WIZARD
    }
}

export const fecthReportedData = () => {
    return dispatch => {
        dispatch(showLoader());
        return new Promise((resolve, reject) => {
            axios.get('/api/Attendance/GetReportedData')
                .then(res => {
                    dispatch(fecthUserReportedSuccsess(res.data));
                    resolve(res.data);
                })
                .catch(err => {
                    dispatch(fetchWizardFailed());
                    reject(err);
                })
        })

    }
}

export const fetchFutureMode = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.get('/api/Attendance/GetFutureReportMode')
                .then(res => {
                    dispatch(fetchFutureModeSuccsess(res.data));
                    resolve(res.data);
                })
                .catch(err => {
                    dispatch(fetchWizardFailed());
                    reject(err);
                })
        })

    }
}

export const setChangeReport = () => {
    return {
        type: actionTypes.SET_CHANGE_REPORT,
    }
}

export const fecthUserReportedSuccsess = (reportedData) => {

    return {
        type: actionTypes.FETCH_REPORTED_DATA_SUCCESS,
        reportedData: reportedData
    }
}

export const fetchFutureModeSuccsess = (futureReportMode) => {

    return {
        type: actionTypes.FETCH_FUTURE_MODE_SUCCESS,
        futureReportMode: futureReportMode
    }
}

export const setWizard = (payload) => {
    return {
        type: actionTypes.SET_WIZARD,
        payload: payload
    }
}

export const fetchWizardFailed = () => {
    return {
        type: actionTypes.FETCH_WIZARD_FAILED
    }
}

export const fetchWizard = () => {
    return dispatch => {
        dispatch(showLoader());
        axios.get('/api/Attendance/GetAllFilterStatuses')
            .then(res => {
                const primaries = res.data.primaries.filter(primarie => primarie.inBase === false)
                const basePrimarie = res.data.primaries.find(primarie => primarie.inBase === true)
                const data = {
                    userName: res.data.userName,
                    timeToReport: res.data.timeToReport,
                    primaries: primaries,
                    basePrimarie: basePrimarie,
                    canUploadMoreFiles: res.data.canUploadMoreFiles,
                    showExplanationPopUp: res.data.showExplanationPopUp,
                    showEmergencyBtn: res.data.showEmergencyBtn,
                    showEmergencyPractice: res.data.showEmergencyPractice,
                    disableFutureReport: res.data.disableFutureReport,
                    disableChangeabltBtn: res.data.disableChangeabltBtn,
                    disableCoronaIcon: res.data.disableCoronaIcon
                }
                dispatch(setWizard(data));
            })
            .catch(error => {
                dispatch(fetchWizardFailed());
            });
    }
}

export const setPrimarieSelection = (primary) => {
    return {
        type: actionTypes.SET_PRIMARIE_SELECTION,
        primarySelection: primary,
    }
}

export const saveSelection = (selection) => {
    return {
        type: actionTypes.SAVE_SELECTION,
        selection: selection
    }
}

export const saveSelectionFailed = () => {
    return {
        type: actionTypes.SAVE_SELECTION_FAILED
    }
}

export const storeSelectionSuccsess = () => {
    return {
        type: actionTypes.STORE_SELECTION_SUCCESS,
    }
}

export const storeSelection = (selection) => {
    const formCollection = new FormData();

    formCollection.append('MainCode', selection.primaryCode)
    formCollection.append('SecondaryCode', selection.secondaryCode)
    
    if (selection.hasOwnProperty('files')) {
        formCollection.append('File1', selection.files[0]);
        formCollection.append('File2', selection.files[1]);
        formCollection.append('StartDate', selection.startDate.toISOString())
        formCollection.append('EndDate', selection.endDate.toISOString())        
    }
    if (selection.hasOwnProperty('note')) {
        formCollection.append('Note', selection.note)
    }
    if(selection.isFutureReport){
        formCollection.append('FutureReportDate', selection.reportDate)
    }
    else {
        formCollection.append('IsEmergency', selection.isEmergency)
    }

    return dispatch => {
        dispatch(showLoader());
        return new Promise((resolve, reject) => {

            // ,  {headers : {
            //     'content-type': `multipart/form-data; boundary=${selectionToServer._boundary}`}               }
            if (!selection.isFutureReport) {
                axios.post('/api/Attendance/InsertPersonalReport', formCollection)//
                .then(res => {
                    if(res.data) {
                        dispatch(saveSelection(selection));
                        resolve(res.data);
                    }
                    else {
                        dispatch(saveSelectionFailed());
                        resolve(res.data);
                    }

                })
                .catch(error => {
                    dispatch(fetchWizardFailed());
                    reject(error);

                });
            }
            else {
                axios.post('/api/Attendance/InsertFutureReport', formCollection)//
                .then(res => {
                    dispatch(storeSelectionSuccsess(res.data));
                    resolve(res.data);
                })
                .catch(error => {
                    reject(error);

                });
            }

        })
    }
}

export const changeReport = (bool) => {
    return {
        type: actionTypes.CHANGE_REPORT,
        bool
    }
}

export const storeFullSelection = (fullSelection) => {
    return {
        type: actionTypes.STORE_FULL_SELECTION,
        fullSelection: fullSelection
    }
}

export const uploadFiles = (files) => {
    return {
        type: actionTypes.STORE_FILES,
        files: files
    }
}

export const addClickToCounter = () => {
    axios.get('/api/ButtonCounter/IncrementCounter');
}
