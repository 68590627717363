import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as historyAction from '../../store/actions/index';
import CalendarPicker from '../../shared/components/CalendarPicker';
import * as layoutActions from '../../store/actions/index';
import {urls, getText} from '../../shared/utility';
import { withRouter } from "react-router";

class HistoryPrat extends Component {

    state = {
        pageType: '',
        groupCode: ''
    }

    componentDidMount() { 
        const { location } = this.props
        
        

        if (this.props.match.params.param === 'user') {
            this.props.onShowWizardHeader();
            
            const fromHistoryGroups = location && location.state && location.state.fromHistoryGroups
            let groupCode = (fromHistoryGroups===true) ? this.props.activeTabCodeHistory : this.props.activeTabCode

            if(this.props.activeTabCodeHistory==="כל הקבוצות" || this.props.activeTabCode==="כל הקבוצות"){
                groupCode = this.props.activeUser.groupCode
            }

            this.props.onFetchHistoryPrat((new Date().getMonth() + 1), new Date().getFullYear(), this.props.activeUser.mi, groupCode);
 
            this.setState({
                pageType: 'commander',
                groupCode: groupCode
            })
        } else {

            const previousYear = location && location.state && location.state.y
            const previousMonth = location && location.state && location.state.m
            this.props.onHideWizardHeader();
            const currentDate = new Date();
            (previousYear && previousMonth)?this.props.onFetchHistoryPrat(previousMonth,previousYear):
            this.props.onFetchHistoryPrat((currentDate.getMonth() + 1), currentDate.getFullYear())
            this.setState({
                pageType: 'prat',
                groupCode: this.props.groupCode
            })
        }
    }

    setDateToUploadFiles = (user) => {
        this.props.onSetDateToUploadFiles(user)
        this.props.history.push(`${urls.uploadFiles}/history`)
    }

    render() {
        const { location } = this.props
        return (
            <div className="historyPrat">
                <div className="container-fluid">
                    <CalendarPicker
                        previousYear={location && location.state && location.state.y}
                        previousMonth={location && location.state && location.state.m}
                        history={this.props.historyPrat}
                        fetchData={(month, year) => (this.state.pageType && this.state.pageType!=='commander') ?
                         this.props.onFetchHistoryPrat(month, year)
                         : this.props.onFetchHistoryPrat(month, year, this.props.activeUser.mi, this.state.groupCode)}
                        pageType={this.state.pageType}
                        activeUser={this.props.activeUser}
                        setDateToUploadFiles={(user) => this.setDateToUploadFiles(user)}
                        contentManagment={this.props.contentManagment} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeTabCode: state.commander.activeTabCode ,
        activeTabCodeHistory: state.history.activeTabCode ,
        historyPrat: state.history.historyPrat,
        activeDate: state.history.activeDate,
        activeUser: state.history.activeUser,
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onFetchHistoryPrat: (month, year, mi, groupCode) => dispatch(historyAction.fetchHistoryPrat(month, year, mi, groupCode)),
        onSetDateToUploadFiles: (user) => dispatch(historyAction.setDateToUploadFiles(user)),
    }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryPrat)); 