import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as layoutActions from '../../store/actions/index';
import * as commanderActions from '../../store/actions/index';
import TitleContainer from '../../shared/components/titleContainer'
import { urls } from '../../shared/utility';

class FilterByStatus extends Component {

    state = {
        statusCategories: [
            { key: 'favorite', value: 'מועדף', isChecked: false },
            { key: 'createdToday', value: 'חדש', isChecked: false },
            { key: 'notReported', value: 'ללא דיווח אישי', isChecked: false },
            { key: 'reportedConflict', value: 'דיווח אישי לא תואם למומלץ', isChecked: false },
            { key: 'compatibleReported', value: 'דיווח אישי תואם למומלץ', isChecked: false }
        ],
    }

    componentDidMount() {
        this.props.onShowWizardHeader();
        //init filter from store
        if (this.props.filterList) {
            const statusList = this.props.filterList.filter(x => x.type === 'status');
            statusList.forEach(x => {
                let statusCategories = this.state.statusCategories.map(status => {
                    if (status.key === x.mainCode) {
                        return {
                            ...status,
                            isChecked: true
                        }
                    }
                    else {
                        return status;
                    }
                })

                this.setState({
                    statusCategories
                })
            })
        }
    }

    handleChange = (e) => {
        let statusCategories = this.state.statusCategories.slice();
        statusCategories.forEach(category => {
            if (category.key === e.target.name)
                category.isChecked = e.target.checked
        })
        this.setState({
            statusCategories
        })
    }

    filterUsers = () => {
        
        const checkedCategory = this.state.statusCategories.filter(category => category.isChecked)
            .map(category => {
                return {
                    type: 'status',
                    mainCode: category.key,
                    value: category.value
                }
            })
        this.props.onStoreFilters(checkedCategory);
        //this.props.history.push(urls.commander)
        this.props.history.push({
            pathname: urls.commander,
            state: { prevUrl: null} 
          })
    }

    render() {
        let filterBy = 'statusCategories'
        let categories = this.state[filterBy].map(category => {
            return (
                <li className="item" key={category.key}>
                    <div className="checkboxContainer">
                        <label className="checkboxBtn" htmlFor={category.key}>
                            <input
                                id={category.key}
                                className="inputCheckbox"
                                name={category.key}
                                type="checkbox"
                                checked={category.isChecked}
                                onChange={this.handleChange}
                            />
                            <span className="checkboxPic"></span>
                            <span className="checkboxText">{category.value}</span>
                        </label>
                    </div>
                </li>
            )
        })

        return (
            <div className="commander__main">
                <TitleContainer
                    iconPath={''}
                    mainTitle={`סינון לפי סטטוס`}
                    subTitle={''}
                    isShadow={true} />
                <div className="container-fluid">
                    <ul className="filterList">
                        {categories}
                    </ul>
                </div>
                <div className="btnSendReportContainer">
                    <button
                        type="button"
                        className="btnGeneral send"
                        onClick={this.filterUsers}>סנן</button>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        usersGroup: state.commander.usersGroup,
        filterList: state.commander.filterList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onStoreFilters: (checkedCategory) => dispatch(commanderActions.storeFilters(checkedCategory)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterByStatus);
