import React, { Component } from 'react'
import TimeField from 'react-simple-timefield';
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
class TimeSlide extends Component {

    state = {
        time: '',
    };

    componentDidUpdate(prevProps) {
        if (this.props.time !== prevProps.time) {
            this.setState({
                time: this.props.time
            })
        }
    }

    debounceDesk = debounce((time) => {
        this.setState({
            time
        }, () => {
            this.props.timeHandle(time, this.props.type)
        });
    }, 500);

    timeHandle = (time) => {
        this.debounceDesk(time)
    }

    debounceMobile = debounce((hours, minutes, name) => {
        this.setState({
            [name]: `${hours}:${minutes}`
        }, () => {
            this.props.timeHandle(this.state.time, this.props.type)
        })
    })

    timeHandleMobile = (e) => {  
        if (e.target.value === '') {
            this.debounceMobile('00', '00', e.target.name)
        }
        else {
            const [hours, minutes] = e.target.value.split(':')
            this.debounceMobile(hours, minutes, e.target.name)
        }
    }

    render() {
        return (
            <>
                {
                    this.props.isMobile ?
                        <label className="timeField" htmlFor={this.props.type}>
                            <input type="time" id={this.props.type} className="inputDate" value={this.state.time} name="time" onChange={this.timeHandleMobile} />
                            <span>{this.state.time}</span>
                        </label>
                        : <TimeField
                            className="timeField"
                            value={this.state.time}
                            onChange={this.timeHandle}
                            colon=":" />
                }
            </>

        )
    }
}
export default TimeSlide;