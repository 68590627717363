import 'react-app-polyfill/ie11';
import 'babel-polyfill'; // explorer support
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import './index.css';
import App from './App';
import wizardReducer from './store/reducers/wizard';
import authReducer from './store/reducers/auth';
import commanderReducer from './store/reducers/commander'
import layoutReducer from './store/reducers/layout';
import settingReducer from './store/reducers/setting';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { config } from './config';
import historyReducer from './store/reducers/history';
import { msalConfig } from "./authConfig";
import futureReportReducer from './store/reducers/future';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const rootPersistConfig = {
//     key: 'groupCode',
//     storage,
//     whitelist: ['wizard']
// }

const wizardPersistConfig = {
    key: 'wizard',
    storage,
    whitelist: ['gender', 'primaries', 'basePrimarie', 'canUploadMoreFiles', 'primarySelection', 'isChangeReport', 'fullSelection']
}
const layoutPersistConfig = {
    key: 'layout',
    storage,
    whitelist: ['isWizardHeader']
}
const commanderPersistConfig = {
    key: 'commander',
    storage,
    whitelist: ['userToUpdateStatus', 'activeTabName', 'activeTabCode', 'usersGroup', 'selectionGroup', 'filterList']
}

const historyPersistConfig = {
    key: 'history',
    storage,
    whitelist: ['activeUser', 'dateToUploadFiles','filesToUpload','activeTabCode']
}

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['rememberMe']
}

// const futurePersistConfig = {
//     key: 'future',
//     storage,
//     whitelist: ['activeUser', 'dateToUploadFiles','filesToUpload','activeTabCode']
// }

const appReducer = combineReducers(
    {
        wizard: persistReducer(wizardPersistConfig, wizardReducer),
        //auth: authReducer,
        auth: persistReducer(authPersistConfig, authReducer),
        commander: persistReducer(commanderPersistConfig, commanderReducer),
        layout: persistReducer(layoutPersistConfig, layoutReducer),
        history: persistReducer(historyPersistConfig, historyReducer),
        setting: settingReducer,
        future: futureReportReducer,
        //future: persistReducer(futurePersistConfig, futureReportReducer)
    });

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        state = undefined
    }
    return appReducer(state, action)
}

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
    if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

msalInstance.enableAccountStorageEvents();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store)

const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter basename={config.baseUrl}>
                <MsalProvider instance={msalInstance}>
                    <App/>
                </MsalProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
