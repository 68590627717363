import axios from 'axios'
import * as actionTypes from './actionTypes';
import { initCheckUsersReports, sortUsers, checkValidationGroup, checkIfEmptyNameAndAddNameToGroup, checkShowOnlyGroup, checkDeterminedGroup } from '../../shared/utilityCommander'


export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER_COMMANDER
    }
}

export const setIsValidTimeToReport = () => {
    return {
        type: actionTypes.SET_VALID_TIME_TO_REPORT
    }
}

export const fetchCommander = (gCode) => {
    return dispatch => {
        dispatch(showLoader());
        return new Promise((resolve, reject) => {
            const url = gCode === 'כל הקבוצות' ? `/api/attendance/GetGroups?groupcode`
            : `/api/attendance/GetGroups?groupcode=${gCode}`;
            axios.get(url)
                .then(res => {
                    const allGroups = { groupAuthIndication: 1, groupCode: 'כל הקבוצות', groupName: 'כל הקבוצות', isSubmittedToday: false, showOnly: false }
                    let usersGroup = initUsersGroup(res.data.firstGroup, res.data.isCommanderMustSendFullReport);
                    usersGroup.isWeekendNachsalReportActive = res.data.isWeekendNachsalReportActive;
                    const updateRes = {
                        ...res.data,
                        groupsCodeAndAuthIndication: [...checkIfEmptyNameAndAddNameToGroup(checkShowOnlyGroup(res.data.groupsCodeAndAuthIndication)), allGroups],
                        usersGroup: usersGroup,
                    }
                    resolve();
                    dispatch(fetchCommanderSuccess(updateRes))
                })
                .catch(err => {
                    reject(err)
                    dispatch(fetchCommanderFailed(err))
                })
        })

    }
}

export const fetchCommanderFailed = (err) => {
    return {
        type: actionTypes.FETCH_COMMANDER_FAILED
    }
}

export const fetchCommanderSuccess = (updateRes) => {
    return {
        type: actionTypes.FETCH_COMMANDER_SUCCESS,
        payload: updateRes,
    }
}

export const postSelection = (selection, groupName) => {
    const selectionRel = {
        ...selection,
        groupName
    }
    return dispatch => {
        dispatch(showLoader());
        return new Promise((resolve, reject) => {
            axios.post('/api/attendance/sendgroup', selection)
                .then(res => {
                    dispatch(saveCommanderSelection(selectionRel));
                    resolve();
                })
                .catch(error => {
                    dispatch(fetchCommanderFailed());
                    reject(error);
                });
        })

    }
}

export const saveCommanderSelection = (selection) => {
    return {
        type: actionTypes.SAVE_COMMANDER_SELECTION,
        selectionGroup: selection
    }
}

export const fetchOtherStatusesByMi = (user, groupCode) => {

    return dispatch => {
        dispatch(showLoader());
        return new Promise((resolve, reject) => {
            const StatusCommander = {
                pratMi: user.mi,
                groupCode: groupCode


            }
            axios.post(`/api/Attendance/GetStatusesForCommander`, StatusCommander)
                .then(res => {
                    dispatch(fetchOtherStatusesByMiSuccess(res.data));
                    resolve(res.data)
                })
                .catch(err => {
                    dispatch(fetchCommanderFailed(err))
                    reject(err)
                })
        })

    }
}

export const fetchOtherStatusesByMiSuccess = (otherStatus) => {
    return {
        type: actionTypes.FETCH_OTHER_STATUSES_SUCCESS,
        otherStatus: otherStatus
    }
}

export const userToUpdate = (user) => {
    return {
        type: actionTypes.USER_TO_UPDATE_STATUS,
        userToUpdateStatus: user
    }
}

export const updateOtherStatus = (user, isCommanderMustSendFullReport) => {
    const userToServer = {
        mi: user.mi,
        mainStatusCode: user.approvedMainCode,
        secondaryStatusCode: user.approvedSecondaryCode,
        groupCode: user.groupCode,
        note: user.note
    }
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(showLoader());
            if(isCommanderMustSendFullReport){
                axios.post('/api/Attendance/updateMemberStatus', userToServer)
                    .then(res => {
                        const updateFromOtherPage = true;
                        dispatch(updateOtherStatusSuccess(user, updateFromOtherPage));
                        resolve(res)
                    })
                    .catch(err => {
                        dispatch(fetchCommanderFailed(err));
                        reject(err)
                    })
            }else{
                axios.post('/api/Attendance/updateAndSendPrat', userToServer)
                .then(res => {
                    const updateFromOtherPage = true;
                    dispatch(updateOtherStatusSuccess(user, updateFromOtherPage));
                    resolve(res)
                })
                .catch(err => {
                    dispatch(fetchCommanderFailed(err));
                    reject(err)
                })
            }
        })
    }
}

export const checkIsSubmittedToday = users => {
    if (users === undefined || users.length === 0) return false;
    let IsSubmittedToday = true;
    users.forEach(e => {
        if (!e.approvedMainCode || !e.approvedSecondaryCode) {
            IsSubmittedToday = false;
        }
    });
    return {
        type: actionTypes.CHECK_IS_SUBMITTED_TODAY_SUCCESS,
        isSubmittedToday: IsSubmittedToday
    }
};

export const updateOtherStatusSuccess = (user) => {
    return {
        type: actionTypes.UPDATE_OTHER_STATUS_SUCCESS,
        user: user
    }
}

export const toggleUser = (mi) => {
    return {
        type: actionTypes.TOGGLE_USER,
        mi: mi
    }
}

export const fetchTabGroup = (gCode, isCommanderMustSendFullReport) => {
    return dispatch => {
        dispatch(showLoader());
        const url = gCode === 'כל הקבוצות' ? `/api/Attendance/getAllGroupsStatistics`
            : `/api/attendance/GetGroupUsers?groupcode=${gCode}`;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(res => {
                    let usersGroup = (gCode === 'כל הקבוצות') ? { users: null, isGroupValid: false, isGroupDetermined: false } : initUsersGroup(res.data, isCommanderMustSendFullReport)
                    resolve()
                    dispatch(fetchTabGroupSuccess(usersGroup, gCode))

                })
                .catch(err => {
                    reject(err)
                    dispatch(fetchCommanderFailed(err))
                })
        })

    }
}

export const fetchTabGroupSuccess = (usersGroup, gCode) => {
    return {
        type: actionTypes.FETCH_TAB_GROUP_SUCCESS,
        usersGroup: usersGroup,
        activeTabCode: gCode,
    }
}

const initUsersGroup = (data, isCommanderMustSendFullReport) => {
    let usersGroup = initCheckUsersReports(data)
    return usersGroup = {
        ...usersGroup,
        users: sortUsers(usersGroup.users),
        isGroupValid: checkValidationGroup(usersGroup.users, isCommanderMustSendFullReport),
        isGroupDetermined: checkDeterminedGroup(usersGroup.users),
        isEmergencyPractice: data.isEmergencyPractice,
        canActivateEmergencyPractice: data.canActivateEmergencyPractice,
        isEmergencyActive: data.isEmergencyActive,
        showEmergencyReport: data.showEmergencyReport
    }
}

export const searchUser = (value) => {
    return {
        type: actionTypes.SEARCH_USER,
        value
    }
}

export const storeFilters = (filters) => {
    return {
        type: actionTypes.STORE_FILTERS,
        filters
    }
}

export const filterUsers = () => {
    return {
        type: actionTypes.FILTER_USERS,
    }
}

export const removeFilter = (mainCode, secondaryCode, pageType) => {
    return {
        type: actionTypes.REMOVE_FILTER,
        mainCode,
        secondaryCode,
        pageType
    }
}

export const removeAllFilters = () => {
    return {
        type: actionTypes.REMOVE_ALL_FILTERS,
    }
}

export const fetchStatistic = (groupCode) => {
    const url = groupCode === 'כל הקבוצות' ? `/api/Attendance/getAllGroupsStatistics`
        : `/api/Attendance/GetGroupStatistics?groupCode=${groupCode}`;
    return dispatch => {
        axios.get(url)
            .then((res) => {
                dispatch(fetchStatisticSuccess(res.data));
            })
            .catch((err) => {
                dispatch(fetchCommanderFailed());
            })
    }

}

export const fetchStatisticSuccess = (res) => {
    return {
        type: actionTypes.FETCH_STATISTIC_SUCCESS,
        statistic: res
    }
}

export const toggleFavorite = (groupCode, userMi) => {
    return dispatch => {
        axios.post(`/api/Attendance/UpdateFavoriteByCommander?groupCode=${groupCode}&userMI=${userMi}`)
            .then((res) => {
                dispatch(toggleFavoriteSuccess(res.data, userMi));
            })
            .catch((err) => {
                dispatch(fetchCommanderFailed());
            })
    }
}

const toggleFavoriteSuccess = (res, userMi) => {
    return {
        type: actionTypes.TOGGLE_FAVORITE,
        res: res,
        userMi: userMi
    }
}


export const fetchGroupsAuthIndication = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/attendance/getGroupsAuthIndication`)
                .then(res => {
                    dispatch(fetchGroupsAuthIndicationSuccess(res.data))
                    resolve()
                })
                .catch(err => {
                    dispatch(fetchCommanderFailed(err))
                    reject()
                })
        })
    }
}

export const fetchGroupsAuthIndicationSuccess = (data) => {
    return {
        type: actionTypes.FETCH_GROUPS_AUTH_INDICATION_SUCCESS,
        groupsCodeAndAuthIndication: data.groupsCodeAndAuthIndication
    }
}

export const removeUserFromGroup = (user, groupCode) => {
    return dispatch => {
        const removeSoldier = {
            pratMi: user.mi,
            groupcode: groupCode

        }
        axios.post(`/api/attendance/removeSoldierFromGroup`, removeSoldier)
            .then(res => {
                dispatch(removeUserFromGroupSuccess(res.data, user))
            })
            .catch(err => {
                dispatch(fetchCommanderFailed(err))
            })
    }
}

const removeUserFromGroupSuccess = (data, user) => {
    return {
        type: actionTypes.REMOVE_USER_FROM_GROUP_SUCCESS,
        userMi: user.mi
    }
}

export const getAllStatuses = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.get(`/api/Attendance/GetAllStatusesCommander`)
                .then(res => {
                    dispatch(getAllStatusesSuccess(res.data))
                    resolve()
                })
                .catch(err => {
                    dispatch(fetchCommanderFailed(err))
                    reject()
                })
        })

    }
}

const getAllStatusesSuccess = (statuses) => {
    return {
        type: actionTypes.GET_ALL_STATUSES_SUCCESS,
        statuses
    }
}

export const submitGroupNameForm = (newGroupName, groupCode) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios.post(`/api/attendance/ChangeGroupNameByCommander?groupcode=${groupCode}&newName=${newGroupName}`)
                .then(res => {
                    dispatch(submitGroupNameFormSuccess(newGroupName, groupCode))
                    resolve()
                })
                .catch(err => {
                    dispatch(fetchCommanderFailed(err))
                    reject()
                })
        })
    }
}

const submitGroupNameFormSuccess = (newGroupName, groupCode) => {
    return {
        type: actionTypes.SUBMIT_GROUP_NAME_FORM_SUCESS,
        newGroupName,
        groupCode
    }
}

export const updateNote = (userMi, note, groupCode) => {
    return dispatch => {
        axios.post(`/api/Attendance/UpdateNote`, { mi: userMi, note, groupCode })
            .then(res => {
                dispatch(updateNoteSuccess(res.data, userMi, note))
            })
            .catch(err => {
                dispatch(updateNoteFailed(err))
            })
    }
}

const updateNoteSuccess = (res, userMi, note) => {
    return {
        type: actionTypes.UPDATE_NOTE_SUCCESS,
        res,
        userMi,
        note
    }
}

const updateNoteFailed = (res) => {
    return {
        type: actionTypes.UPDATE_NOTE_FAILED,
        res
    }
}

export const changeEmergencyPracticeMode = (groupCode, emergencyMode) => {
  return dispatch => {
       axios.post(`/api/Attendance/changeEmergencyPracticeMode?groupcode=${groupCode}&emergencyMode=${emergencyMode}`)
            .then(res => {
                dispatch(changeEmergencyPracticeModeSuccess(res.data))
            })
            .catch(err => {
                dispatch(changeEmergencyPracticeModeFailed(err))
            })
    }
}

const changeEmergencyPracticeModeSuccess = (res) => {
    return {
        type: actionTypes.CHANGE_EMERGENCY_PRCATICE_MODE_SUCCESS,
        res
    }
}

const changeEmergencyPracticeModeFailed = (res) => {
    return {
        type: actionTypes.CHANGE_EMERGENCY_PRCATICE_MODE_FAILED,
        res
    }
}


export const setActiveTab = (groupCode) => {
    return {
        type: actionTypes.SET_ACTIVE_TAB,
        groupCode
    }
}

export const clearStateCommander = () => {
    return {
        type: actionTypes.CLEAR_STATE_COMMANDER
    }
}
