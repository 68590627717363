import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as commanderActions from '../../store/actions/index';
import * as layoutActions from '../../store/actions/index';
import * as historyActions from '../../store/actions/index';
import Loader from '../../shared/components/loader';
import UsersGroup from '../components/usersGroup';
import MsgRow from '../components/msgRow';
import KeyPanel from '../components/keyPanel';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { urls, downloadFile } from '../../shared/utility';
import StatisticList from '../components/statisticList';
import NoteModal from '../../shared/components/noteModal';
import RemoveUserModal from '../components/removeUserModal';
import NameGroupModal from '../components/nameGroupModal';
import { withRouter } from "react-router";
import EmergencyReportsSummary from '../components/emergencyReportsSummary';
import { updateOtherStatusSuccess } from '../../store/actions/commander';


class Commander extends Component {

    state = {
        valueGroupSubtabs: (this.props && this.props.activeTabName !== "כל הקבוצות") ? "דיווחי קבוצה" : "סיכום סטטיסטי" && "סיכום דיווח חירום",
        isNoteModalOpen: false,
        userToUpdateNote: null,
        disabledPressTab: false,
        userToRemove: null,
        groupNameToChange: null,
        groupCodeToChange: null,
        emergencyPracticeMode: this.props.isEmergencyPractice,
        showEmergencyReport: this.props.showEmergencyReport,
        IsWeekendNachsalReportActive: this.props.IsWeekendNachsalReportActive
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.location !== this.props.location) {
    //         //   this.setState({ prevPath: this.props.location })
    //         console.log(this.props.location)
    //     }
    // }

    // componentDidUpdate() {
    //     if(this.state.emergencyPracticeMode !== this.props.isEmergencyPractice)
    //         this.setState({
    //             emergencyPracticeMode: this.props.isEmergencyPractice
    //         })
    // }

    componentDidMount() {
        const { location } = this.props

        if (!this.state.groupNameToChange && !this.state.groupCodeToChange) {
            this.setState({
                groupNameToChange: this.props.activeTabName,
                groupCodeToChange: this.props.activeTabCode,
                disabledPressTab: true
            })
        }
        this.initLongPressTab()
        this.props.onHideWizardHeader();

        
        const previousPath = location && location.state && location.state.prevUrl

        if(previousPath!==null && previousPath && this.props.activeTabCode && this.props.isUpdated ) {
            this.props.onFetchTab(this.props.activeTabCode, this.props.isCommanderMustSendFullReport);
        }

        if (!this.props.isUpdated) {
            this.props.onFetchCommander(this.props.activeTabCode)
                .then(_ => {
                    this.filter();
                    if (this.props.activeTabName !== "כל הקבוצות") {
                        this.setState({
                            valueGroupSubtabs: "דיווחי קבוצה",
                        })
                    }
                    if(this.props.isEmergencyPractice)
                    this.setState({
                        emergencyPracticeMode: this.props.isEmergencyPractice
                    })
                    if(this.props.showEmergencyReport != this.state.showEmergencyReport)
                    this.setState({
                        showEmergencyReport: this.props.showEmergencyReport
                    })
                })
        }
        else {
            this.filter();
        }
    }

    filter = () => {
        if (this.props.filterList && this.props.filterList.length) {
            this.props.onFilterUsers()
        }
    }

    preesTab = (e) => {
        this.setState({
            disabledPressTab: false
        })
        /* the code below create the edit group name pop up*/
        // this.buttonPressTimer = setTimeout(() => {
        //     this.setState({
        //         groupNameToChange: e.target.innerText,
        //         groupCodeToChange: e.target.dataset.rbEventKey,
        //         disabledPressTab: true
        //     }, () => {
        //         if (this.state.groupCodeToChange !== "כל הקבוצות") {
        //             this.refs.nameGroupModal.showModal();
        //         }
        //     })
        // }, 1000);
    }

    liveTab = () => clearTimeout(this.buttonPressTimer);

    initLongPressTab = () => {
        this.buttonPressTab = setTimeout(() => {
            const navItemList = document.querySelectorAll('.mainTabs .nav-item')
            for (const navItem of navItemList) {
                navItem.addEventListener('mousedown', (e) => this.preesTab(e))
                navItem.addEventListener('touchstart', (e) => this.preesTab(e))
                navItem.addEventListener('mouseup', this.liveTab)
                navItem.addEventListener('touchend', this.liveTab)
            }
        }, 1000)
    }

    componentWillUnmount = () => {
        clearTimeout(this.buttonPressTab); 
    }

    handleTabChange = async (key) => {
        this.initLongPressTab()
        if (!this.state.disabledPressTab) {
            this.props.onRemoveAllFilters();
           await this.props.onFetchTab(key, this.props.isCommanderMustSendFullReport);
            if (key === "כל הקבוצות") {
                this.props.onFetchStatistic("כל הקבוצות")
                this.setState({ valueGroupSubtabs: 'סיכום סטטיסטי' && 'סיכום דיווח חירום', });
            }
            else {
                this.setState({ 
                    valueGroupSubtabs: "דיווחי קבוצה", 
                    emergencyPracticeMode: this.props.isEmergencyPractice, 
                    showEmergencyReport: this.props.showEmergencyReport
                });
            }
        }
    };

    updateState = () => {this.setState({ emergencyPracticeMode: this.props.isEmergencyPractice, showEmergencyReport: this.props.showEmergencyReport})}

    handleChangeSubtabs = (key, groupCode) => {
        if (key === 'סיכום סטטיסטי' || key === 'סיכום דיווח חירום') {
            this.props.onFetchStatistic(groupCode);
        }
        this.setState({ valueGroupSubtabs: key });
    };

    submitGroupReport = () => {
        if (this.props.usersGroup.isGroupValid) {
            this.props.history.push(urls.confirmGroup);
        }
    }

    goToOtherStatus = (userToUpdate) => {
        this.props.onUserToUpdate(userToUpdate);
        this.props.history.push(urls.otherStatus);
    }

    onSearch = (value) => {
        this.props.onSearchUser(value);
    }

    goToFiterPage = () => {
        this.props.history.push(urls.filters);
    }

    openNoteModal = (user) => {
        this.setState({
            isNoteModalOpen: true,
            userToUpdateNote: user
        })
    }

    closeNoteModal = () => {
        this.setState({
            isNoteModalOpen: false
        })
    }

    openRemoveUserModal = (user) => {
        this.setState({
            userToRemove: user
        }, () => {
            this.refs.removeUserModal.showModal()
        })
    }

    goToCalendar = (user) => {
        this.props.onSetActiveUser(user)
        this.props.history.push(`${urls.historyPrat}/user`)
    }

    updateUserStatus = (user, isCommanderMustSendFullReport) => {
         this.props.onUpdateOtherStatus(user, isCommanderMustSendFullReport)
         .then(() => {
            this.props.onCheckIsSubmittedToday(this.props.usersGroup.users)
            if (this.props.isSubmittedToday && !isCommanderMustSendFullReport)
                this.goToConfirmGroup()
         })
    }

    goToConfirmGroup = () => {
        this.props.history.push(urls.confirmGroup)
    }

    changeEmergencyPracticeMode = async (groupCode) => {
       await this.props.onChangeEmergencyPracticeMode(groupCode, this.state.emergencyPracticeMode)
        this.setState({
             emergencyPracticeMode: !this.state.emergencyPracticeMode,
             showEmergencyReport: true
        })
    }

    render() {
        let groupList = '';
        let btnSendReport = '';
        let showEmergencyReports = '';

        const isValidTimeToReportIncludingWeekend = function (isValidTimeToReport, isWeekendNachsalReportActive) {
            return isWeekendNachsalReportActive || ![5, 6].includes(new Date().getDay()) ? isValidTimeToReport : false;
        }

        if (this.props.groupsCodeAndAuthIndication) {

            groupList = this.props.groupsCodeAndAuthIndication.map((group, index) => {
                setTimeout(() => {
                    const tabs = document.querySelector('.mainTabs');
                    if (tabs) {
                        const tabsArray = Array.from(tabs.children);
                        if (group.showOnly)
                            tabsArray[index].classList.add('showOnly');
                        if (group.isSubmittedToday) {
                            tabsArray[index].classList.add('submitted');
                        }
                    }
                }, 0)

                if (this.props.isValidTimeToReport && !this.props.isGroupDetermined && this.props.activeTabCode != 'כל הקבוצות' && this.props.isCommanderMustSendFullReport) {
                    btnSendReport = (
                        <div className="btnSendReportContainer">
                            <button
                                type="button"
                                className="btnGeneral send"
                                disabled={!this.props.usersGroup.isGroupValid}
                                onClick={this.submitGroupReport}>שליחת דיווח {this.props.activeTabName} </button>
                        </div>
                    )
                }

                if(this.props.isEmergencyActive || this.props.isEmergencyPractice){
                    showEmergencyReports = (
                        <Tab eventKey="סיכום דיווח חירום" title="דיווח חירום">
                                <div className="contentTab">
                                    <div className="container-fluid">
                                        <EmergencyReportsSummary statistic={this.props.statistic} group={group} primaries={this.props.primaries}/>
                                    </div>
                                </div>
                            </Tab>
                    )
                }

                return (

                    <Tab eventKey={group.groupCode} title={group.groupName} key={index}>
                        <Tabs className="subTabs" activeKey={this.state.valueGroupSubtabs} onSelect={key => this.handleChangeSubtabs(key, group.groupCode)}>
                            {this.props.activeTabName !== "כל הקבוצות" && <Tab eventKey="דיווחי קבוצה" title="דיווחי קבוצה">
                                <div className="contentTab">
                                    <KeyPanel
                                        mainScreenCommanderManagment={this.props.contentManagment.mainScreenCommander}
                                        gender={this.props.gender}
                                        usersLength={this.props.usersGroup.users.length}
                                        showOnly={group.showOnly}
                                        onSearch={this.onSearch}
                                        goToFiterPage={this.goToFiterPage}
                                        filterListLength={this.props.filterList.length}
                                        canActivateEmergencyPractice={this.props.canActivateEmergencyPractice}
                                        isEmergencyActive={this.props.isEmergencyActive}
                                        isEmergencyPractice={this.state.emergencyPracticeMode}
                                        groupCode={group.groupCode}
                                        changeEmergencyPracticeMode={()=>this.changeEmergencyPracticeMode(this.props.activeTabCode, this.state.emergencyPracticeMode)} />
                                    <div className="container-fluid">


                                        <ul>
                                            <UsersGroup
                                                mainScreenCommanderManagment={this.props.contentManagment.mainScreenCommander}
                                                gender={this.props.gender}
                                                group={group}
                                                usersGroup={this.props.usersGroup}
                                                reportedData={this.props.reportedData}
                                                isValidTimeToReport={isValidTimeToReportIncludingWeekend(this.props.isValidTimeToReport,this.props.usersGroup.isWeekendNachsalReportActive)}
                                                isCantReport={this.props.isCantReport}
                                                updateOtherStatus={(updateUser) => this.updateUserStatus(updateUser, this.props.isCommanderMustSendFullReport)}
                                                toggleRow={(mi) => this.props.onToggleUser(mi)}
                                                goToOtherStatus={(userToUpdate) => this.goToOtherStatus(userToUpdate)}
                                                activeTabCode={this.props.activeTabCode}
                                                openNoteModal={(user) => this.openNoteModal(user)}
                                                openRemoveUserModal={(user, groupCode) => this.openRemoveUserModal(user, groupCode)}
                                                toggleFavorite={(groupCode, userMi) => this.props.onToggleFavorite(groupCode, userMi)}
                                                goToCalendar={(user) => this.goToCalendar(user)}
                                                downloadFile={(fileId,fileName) => downloadFile(fileId,fileName)}
                                                canActivateEmergencyPractice={this.props.canActivateEmergencyPractice}
                                                isEmergencyActive={this.props.isEmergencyActive}
                                                isEmergencyPractice={this.state.emergencyPracticeMode}
                                                showEmergencyReport={this.state.showEmergencyReport} />
                                        </ul>

                                    </div>
                                </div>
                            </Tab>
                            }
                            <Tab eventKey="סיכום סטטיסטי" title="דיווחים ללא אישור מפקד">
                                <div className="contentTab">
                                    <div className="container-fluid">
                                        <StatisticList statistic={this.props.statistic} group={group} />
                                    </div>
                                </div>
                            </Tab>
                            
                            
                            {showEmergencyReports}
                        </Tabs>

                        {!group.showOnly ? btnSendReport : null}

                    </Tab>
                )

            })

        }


        if (this.props.loader) {
            return <Loader />
        }
        if (this.props.isCommanderAuthenticated) {
            if (this.props.usersGroup) {

                return (

                    <div className="commander__main">
                        <MsgRow
                            contentManagment={this.props.contentManagment}
                            gender={this.props.gender}
                            timeToReport={this.props.endReportTime}
                            isValidTime={this.props.isValidTimeToReport}
                            isGroupDetermined={this.props.isGroupDetermined}
                            isCantReport={this.props.isCantReport}
                            IsWeekendNachsalReportActive={this.props.IsWeekendNachsalReportActive}
                        />
                        <div className="mainTabsContainer">
                            <Tabs
                                activeKey={this.props.activeTabCode}
                                onSelect={key => this.handleTabChange(key)}
                                className="mainTabs">
                                {groupList}
                            </Tabs>
                        </div>
                        <NameGroupModal
                            ref="nameGroupModal"
                            groupName={this.state.groupNameToChange}
                            submitGroupNameForm={(newGroupName) => this.props.onSubmitGroupNameForm(newGroupName, this.state.groupCodeToChange)} />
                        <RemoveUserModal
                            user={this.state.userToRemove}
                            ref="removeUserModal"
                            removeUser={() => this.props.onRemoveUserFromGroup(this.state.userToRemove, this.props.activeTabCode)} />
                        <NoteModal
                            user={this.state.userToUpdateNote}
                            isNoteModalOpen={this.state.isNoteModalOpen}
                            closeNoteModal={this.closeNoteModal}
                            updateOtherStatus={(updateUser) => this.props.onUserToUpdate(updateUser)}
                            updateNote={(userMi, note, activeTabCode) => this.props.onUpdateNote(userMi, note, this.props.activeTabCode)} />
                    </div>
                )
            }
            else {
                return null
            }
        } else {
            this.props.history.push('/');
            return null
        }


    }
}

const mapStateToProps = state => {
    return {
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        primaries: state.wizard.primaries,
        isCommanderAuthenticated: state.auth.isCommanderAuth,
        groupsCodeAndAuthIndication: state.commander.groupsCodeAndAuthIndication,
        usersGroup: state.commander.usersGroup,
        reportedData: state.wizard.reportedData,
        loader: state.commander.loader,
        activeTabName: state.commander.activeTabName,
        activeTabCode: state.commander.activeTabCode,
        endReportTime: state.commander.endReportTime,
        isValidTimeToReport: state.commander.isValidTimeToReport,
        isGroupDetermined: state.commander.usersGroup.isGroupDetermined,
        isCantReport: state.commander.cantReport,
        filterList: state.commander.filterList,
        statistic: state.commander.statistic,
        isCommanderMustSendFullReport: state.commander.isCommanderMustSendFullReport,
        isSubmittedToday: state.commander.isSubmittedToday,
        isUpdateOtherStatus: state.commander.isUpdateOtherStatus,
        isUpdated: state.commander.isUpdated,
        canActivateEmergencyPractice: state.commander.canActivateEmergencyPractice,
        isEmergencyActive: state.commander.isEmergencyActive,
        isEmergencyPractice: state.commander.usersGroup.isEmergencyPractice,
        showEmergencyReport: state.commander.usersGroup.showEmergencyReport,
        IsWeekendNachsalReportActive: state.commander.isWeekendNachsalReportActive
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onRemoveAllFilters: () => dispatch(commanderActions.removeAllFilters()),
        onFetchCommander: (gCode) => dispatch(commanderActions.fetchCommander(gCode)),
        onUpdateOtherStatus: (updateUser, isCommanderMustSendFullReport) => dispatch(commanderActions.updateOtherStatus(updateUser, isCommanderMustSendFullReport)),
        onToggleUser: (mi) => dispatch(commanderActions.toggleUser(mi)),
        onUserToUpdate: (userToUpdate) => dispatch(commanderActions.userToUpdate(userToUpdate)),
        onFetchTab: (gCode, isCommanderMustSendFullReport) => dispatch(commanderActions.fetchTabGroup(gCode, isCommanderMustSendFullReport)),
        onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
        onSearchUser: (value) => dispatch(commanderActions.searchUser(value)),
        onFilterUsers: () => dispatch(commanderActions.filterUsers()),
        onFetchStatistic: (groupCode) => dispatch(commanderActions.fetchStatistic(groupCode)),
        onToggleFavorite: (groupCode, userMi) => dispatch(commanderActions.toggleFavorite(groupCode, userMi)),
        onRemoveUserFromGroup: (user, groupCode) => dispatch(commanderActions.removeUserFromGroup(user, groupCode)),
        onSubmitGroupNameForm: (newGroupName, groupCode) => dispatch(commanderActions.submitGroupNameForm(newGroupName, groupCode)),
        onSetActiveUser: (user) => dispatch(historyActions.setActiveUser(user)),
        onUpdateNote: (userMi, note, activeTabCode) => dispatch(commanderActions.updateNote(userMi, note, activeTabCode)),
        onCheckIsSubmittedToday: (users) => dispatch(commanderActions.checkIsSubmittedToday(users)),
        onChangeEmergencyPracticeMode: (groupCode, emergencyMode) => dispatch(commanderActions.changeEmergencyPracticeMode(groupCode, emergencyMode))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Commander));

