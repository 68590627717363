import React, { Component } from "react";
import WarningModal from "./warningModal";
import { urls } from "../utility";

class BtnFutureReport extends Component {
    notification = ["השירות אינו פעיל"]

  goToFutureReport = () => {
    var r = this.refs.warningModal;
    this.props.fetchBtnMode().then((data) => {
      if (data)
        r.handleModalShow();
      else
          window.location = urls.futureReport;
    });
  };

  render() {
    return (
      <div>
        {!this.props.disableFutureReport && (
          <button
            type="button"
            disabled={this.props.disableFutureReport}
            className={this.props.className}
            onClick={() => this.goToFutureReport()}
          >
            <img className="icon" src="img/futureReport.png" alt="" />
            דיווחים עתידיים
          </button>
        )}

        <WarningModal
          notifications={this.notification}
          ref="warningModal"
        />
      </div>
    );
  }
}

export default BtnFutureReport;
