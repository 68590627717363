import React, { Component, Fragment } from 'react'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { collapseRow } from '../../shared/utilityCommander';
import UserListCount from '../../commander/components/userListCount'
import { connect } from 'react-redux';
import * as historyActions from '../../store/actions/index';
import { getFullDate, getDayName } from '../../shared/utility';
import { sortUsersByName } from '../../shared/utilityCommander';
import HistoryModal from '../../shared/components/historyModal';
import { urls } from '../../shared/utility';
import * as layoutActions from '../../store/actions/index';
import * as commanderActions from '../../store/actions/index';
import NameGroupModal from '../components/nameGroupModal';
import Loader from '../../shared/components/loader';


class HistoryGroups extends Component {

    state = {
        valueGroupSubtabs: 'לפי סטטוס',
        usersByStatus: [],
        date: new Date(),
        isHistoryModalOpen: false,
        userModal: null,
        isDisabledNextDayBtn: true,
        groupNameToChange: null,
        groupCodeToChange:null
    }

    componentDidMount() {
        this.props.onHideWizardHeader();
        

        this.props.onFetchHistoryGroup()
        
        this.initLongPressTab();
        this.checkDisableNext()
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.state.groupNameToChange != null && this.state.groupCodeToChange != null && ( this.state.groupNameToChange !== prevState.groupNameToChange && prevState.groupCodeToChange === this.state.groupCodeToChange)) {
    //         const found = this.props.historyGroup.groupsCodeAndName.findIndex(element => element.groupCode  === this.state.groupCodeToChange);
    //         if(found){
    //            this.props.historyGroup.groupsCodeAndName[found].groupName = this.state.groupNameToChange
    //         }
    //       
            
    //     }
    //     console.log(this.state.groupNameToChange , this.state.groupCodeToChange)
    // }

    static getDerivedStateFromProps(props, state) {
        if (props.usersByStatus !== state.usersByStatus && props.usersHistoryByStatus && props.usersGroupHistory) {
            const mazeva = { key: 'מצבה רישומית', users: props.usersGroupHistory }
            sortUsersByName(mazeva.users)
            let usersByStatus = Object.keys(props.usersHistoryByStatus).map(key => ({ key, users: sortUsersByName(props.usersHistoryByStatus[key]) }))
            usersByStatus.push(mazeva)
            return {
                usersByStatus
            };
        }

        return null;
    }

    preesTab = (e) => {
        this.setState({
            disabledPressTab: false
        })
        /* the code below create the edit group name pop up*/
        // this.buttonPressTimer = setTimeout(() => {
        //     this.setState({
        //         groupNameToChange: e.target.innerText,
        //         groupCodeToChange: e.target.dataset.rbEventKey,
        //         disabledPressTab: true
        //     }, () => {
        //         if(this.state.groupCodeToChange !== "כל הקבוצות") {
        //             this.refs.nameGroupModal.showModal();
        //         }            
        //     })
        // }, 500);
    }

    liveTab = () => {
        clearTimeout(this.buttonPressTimer);
    }

    initLongPressTab = () => {
        setTimeout(() => {
            const navItemList = document.querySelectorAll('.mainTabs .nav-item')
            for (const navItem of navItemList) {
                navItem.addEventListener('mousedown', (e) => this.preesTab(e))
                navItem.addEventListener('touchstart', (e) => this.preesTab(e))
                navItem.addEventListener('mouseup', this.liveTab)
                navItem.addEventListener('touchend', this.liveTab)
            }
        }, 1000)
    }

    handleTabChange = (key) => {
        this.props.onFetchHistoryTab(key, this.props.activeDate);
        this.setState({ valueGroupSubtabs: "לפי סטטוס" });
    };

    handleSubtabsChange = (key) => {
        this.setState({
            valueGroupSubtabs: key
        });
    };

    clickRow = (indexGroup, index, usersLength) => {
        if (usersLength) {
            collapseRow(this.refs[`tr${indexGroup}${index}`])
        }
    }

    resetCollapse = () => {
        document.querySelectorAll('.statisticTable .trRow').forEach(element => {
            let collapseList = element.nextSibling.querySelectorAll('.collapseList')[0];
            collapseList.style.height = 0
            element.classList.remove("open");
            collapseList.classList.remove('open');
        });
    }

    getPrevDay = (activeTab, activeDate) => {
        
        this.props.onSetPrevDay(activeTab, activeDate)
            .then(() => {
                this.checkDisableNext()
            })
            this.resetCollapse()
    }

    getNextDay = (activeTab, activeDate) => {
        
        this.props.onSetNextDay(activeTab, activeDate)
            .then(() => {
                
                if (new Date(this.props.activeDate.toDateString()).getTime() >= (new Date(new Date().toDateString()).getTime())) {
                
                    this.setState({
                        isDisabledNextDayBtn: true
                    })
                }
            })
            this.resetCollapse()
    }

    checkDisableNext = () =>{
        if (new Date(this.props.activeDate.toDateString()).getTime() < (new Date(new Date().toDateString()).getTime())) {
            this.setState({
                isDisabledNextDayBtn: false
            })
        }
    }

    openHistoryModal = (user) => {
        this.refs.historyModalRef.showModal()
        this.setState({
            userModal: user
        })
    }

    goToCalendar = (user) => {
        this.props.onSetActiveUser(user)
        this.props.history.push(`${urls.historyPrat}/user`)
        this.props.history.push({
            pathname: `${urls.historyPrat}/user`,
            state: { fromHistoryGroups: true} 
          })
    }

    render() {
        let groupList = '';
        let tableBody = '';
        let trClass = 'trRow';
        if (this.props.historyGroup) {
            groupList = this.props.historyGroup.groupsCodeAndName.map((group, indexGroup) => {

                tableBody = this.state.usersByStatus.map((status, index) => {
                    if (status.users.length) trClass += ' pointer';

                    return (
                        <Fragment key={index}>
                            <tr className={trClass} onClick={() => this.clickRow(indexGroup, index, status.users.length)} ref={`tr${indexGroup}${index}`}>
                                <td className="col-7">{status.key}</td>
                                <td className="col-3">{status.users.length}</td>
                                <td className="col-2 arrow"></td>
                            </tr>
                            <tr>
                                <td colSpan="5">
                                    <div className="collapseList">
                                        <ul className="statisticUsersList">
                                            {
                                                status.users.map((user, index) => {
                                                    return (
                                                        <li className="item" key={index} onClick={() => this.openHistoryModal(user)}>
                                                            <div className="userWrapper">
                                                                <div className="name">{`${user.firstName} ${user.lastName}`}</div>
                                                                <div className="status">{`${user.approvedMainName} ${user.approvedSecondaryName && ','} ${user.approvedSecondaryName}`}</div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </td>

                            </tr>
                        </Fragment>
                    )
                })
                return (
                    <Tab eventKey={group.groupCode} title={group.groupName} key={group.groupCode}>

                        <Tabs className="subTabs" activeKey={this.state.valueGroupSubtabs} onSelect={key => this.handleSubtabsChange(key)}>
                            <Tab eventKey="לפי סטטוס" title="לפי סטטוס">
                                <div className="contentTab">
                                    <div className="container-fluid">
                                        <table className="statisticTable history" cellPadding="0" cellSpacing="0" id="table">
                                            <thead>
                                                <tr>
                                                    <th className="col-7">סטטוס</th>
                                                    <th className="col-3">סה"כ</th>
                                                    <th className="col-2"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableBody}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="לפי שם" title="לפי שם" className="historyGroupfilterByName">
                                <div className="contentTab">
                                    <div className="container-fluid">
                                        <UserListCount users={this.props.usersGroupHistory} page={'history'} openHistoryModal={(user) => this.openHistoryModal(user)} />
                                        <div className="bottomLine">
                                            <div className="container-fluid">
                                                <div className="inner">
                                                    <div className="text">מצבה רישומית</div>
                                                    <div className="count">{this.props.usersGroupHistory ? this.props.usersGroupHistory.length : null}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>


                    </Tab>

                )
            })
        }

        if (this.props.loader) {
            console.log('loadertest')
            return <Loader />
        }
        return (
            <div className="historyCommander">
                <div className="historyCommanderTop">
                    <div className="container-fluid">
                        <h1 className="historyMainTitle">סיכום יומי</h1>
                        <div className="btnHistoryContainer">
                            <button type="button" className="btnHistory prev" disabled={!this.props.historyGroup} onClick={() => this.getPrevDay(this.props.activeTabCode, this.props.activeDate)}></button>
                            <div className="daySlide">
                                <span>יום {getDayName(this.props.activeDate)}</span>
                                <span>{getFullDate(this.props.activeDate)}</span>
                                <img src="/img/dateHistoryIcon.png" alt="" />
                            </div>
                            <button type="button" className="btnHistory next" disabled={this.state.isDisabledNextDayBtn} onClick={() => this.getNextDay(this.props.activeTabCode, this.props.activeDate)}></button>
                        </div>
                    </div>
                </div>

                <div className="historyTab">
                    <Tabs
                        activeKey={this.props.activeTabCode}
                        onSelect={key => this.handleTabChange(key)}
                        className="mainTabs">
                        {groupList}
                    </Tabs>
                </div>

                <NameGroupModal
                    ref="nameGroupModal"
                    groupName={this.state.groupNameToChange}
                    submitGroupNameForm={(newGroupName) => 
                            this.props.onSubmitGroupNameForm(newGroupName, this.state.groupCodeToChange).then(()=>{
                            this.props.onFetchHistoryGroup()
                        })
                    } />
                <HistoryModal
                    ref="historyModalRef"
                    isCommanderState={true}
                    user={this.state.userModal}
                    indexDay={null}
                    date={this.props.activeDate}
                    goToCalendar={(user) => this.goToCalendar(user)}
                    contentManagment={this.props.contentManagment} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        historyGroup: state.history.historyGroup,
        usersGroupHistory: state.history.usersGroupHistory,
        usersHistoryByStatus: state.history.usersHistoryByStatus,
        activeTabCode: state.history.activeTabCode,
        activeDate: state.history.activeDate,
        groupsCodeAndAuthIndication: state.commander.groupsCodeAndAuthIndication,
        contentManagment: state.layout.contentManagment,
        loader: state.history.loader,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
        onFetchHistoryGroup: () => dispatch(historyActions.fetchHistoryGroup()),
        // onGetAllGroupUsers: (date) => dispatch(historyActions.getAllGroupUsers(date)),
        onFetchHistoryTab: (gCode, date) => dispatch(historyActions.fetchHistoryTabGroup(gCode, date)),
        onSetPrevDay: (activeTab, activeDate) => dispatch(historyActions.setPrevDay(activeTab, activeDate)),
        onSetNextDay: (activeTab, activeDate) => dispatch(historyActions.setNextDay(activeTab, activeDate)),
        onSetActiveUser: (user) => dispatch(historyActions.setActiveUser(user)),
        onSubmitGroupNameForm: (newGroupName, groupCode) => dispatch(commanderActions.submitGroupNameForm(newGroupName, groupCode)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HistoryGroups);