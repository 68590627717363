import React, { Component } from 'react'
import TitleContainer from '../../shared/components/titleContainer'
import { connect } from 'react-redux'
import * as layoutActions from '../../store/actions/index';
import * as wizardAction from '../../store/actions/index';
import { urls, getText, calcDiffDays } from '../../shared/utility'

class Primaries extends Component {

    state = {
        titleText: '',
        isEmergency: false,
        isFutureReport: false,
        reportDate: new Date()
    }

    componentDidMount() {
        this.loadText()
        this.props.onShowWizardHeader();
        this.SetPage();
        //this.props.onChangeReport(!this.state.isEmergency);

    }

    SetPage = () => {
        const { location } = this.props
        var isEmergency = this.state.isEmergency;
        var isFutureReport = this.state.isFutureReport;
        if(location && location.state){
                if (location.state.prevUrl && location.state.prevUrl === "emergency") {
                isEmergency = true;
                this.setState({
                    isEmergency: true,
                    titleText: "דיווח חירום"
                })
                console.log(this.state);
                }
                else if (location.state.reportDate){
                    this.setState({
                        isFutureReport: true,
                        reportDate: location.state.reportDate,
                        titleText: "איפה תהיו בתאריך היעד?"
                    })
                }
        }
        this.props.onChangeReport(!isEmergency && isFutureReport);  
    }

    loadText = () => {
        this.setState({
            titleText: getText(this.props.contentManagment.selectMainStatusPrat, 'title', this.props.gender),
        })
    }

    selectPrimary(code) {
        let now = Date.now()
        let endTime = (new Date(this.props.endReport)).getTime()
        if(endTime - now > 0 || this.state.isEmergency || this.state.isFutureReport){
            let allPrimaries = this.props.primaries;
            if (this.state.isFutureReport)
                allPrimaries = allPrimaries.concat(this.props.basePrimarie);
            const selectedPrimary = allPrimaries.find(primary => primary.statusCode === code);
                this.props.onSetPrimarieSelection(selectedPrimary);
                if (this.state.isFutureReport)
                    this.props.history.push({
                        pathname: urls.secondaries,
                        state: { reportDate: this.state.reportDate}});
                else this.props.history.push(urls.secondaries)
        }
        else{
            this.props.history.push(urls.finish)
        }
  
    }

    render() {
        let primariesList = null;
        let filteredList = null;
        let futureDays =  calcDiffDays(this.state.reportDate);
        let subTitle = this.state.isFutureReport ? this.state.reportDate.toLocaleDateString('en-GB') : ''

        if (this.props.primaries) {
            if (this.state.isEmergency){
                filteredList = this.props.primaries.filter(a => a.isEmergency);
            }
            else{
                filteredList = this.props.primaries.filter(a => !a.isEmergency);
                if (this.state.isFutureReport){
                    filteredList = this.props.primaries.concat(this.props.basePrimarie)
                    filteredList = filteredList.map(a =>  {
                        var filtered = a.secondaries.filter(b => b.futureReportDays >= futureDays);
                        if (filtered.length == 0) 
                            return {...a, disabled: true};
                        return {...a, disabled: false}
                    })
                }
                filteredList.sort((a, b) => (a.pratPrioritization > b.pratPrioritization) ? 1 : ((b.pratPrioritization > a.pratPrioritization) ? -1 : 0));
            }
            primariesList = filteredList.map((primary, index) => {
                var className = "primariesItemContainer"; 
                if (primary.disabled)
                    className += " disabled";
                return (
                    <li className={className} key={index}>
                     {/* style={{ 'animationDelay': `${index * 200}ms` }} */}
                        <div className="primariesItem" onClick={() => { this.selectPrimary(primary.statusCode); }}>
                            <div className="iconContainer">
                                <img src={`/${primary.icon}`} alt="" />
                            </div>
                            <div className="title">{primary.statusDescription}</div>
                        </div>
                    </li>
                )
            });
        }

        if (filteredList.length > 0) {
            return (
                <div>
                    <TitleContainer
                        iconPath={''}
                        mainTitle={this.state.titleText}
                        subTitle={subTitle}
                        isShadow={false} />
                    <section className="primariesSection">
                        <div className="container-fluid">
                            <ul className="primariesList">
                                {primariesList}
                            </ul>
                        </div>
                    </section>
                </div>
            )
        } else if (this.state.isEmergency) {
            return (
                <div>
                    <TitleContainer
                        iconPath={''}
                        mainTitle={this.state.titleText}
                        subTitle={''}
                        isShadow={false} />
                    <section className="primariesSection">
                        <div className="container-fluid">
                            <div className="welcomeTitle">אין סטאטוסים בחירום</div>
                        </div>
                    </section>
                </div>
            )
        } else {
            return null
        }

    }
}

const mapStateToProps = state => {
    return {
        contentManagment:state.layout.contentManagment,
        gender: state.wizard.gender,
        primaries: state.wizard.primaries,
        basePrimarie: state.wizard.basePrimarie,
        endReport: state.wizard.timeToReport,
        // loader: state.wizard.loader
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onSetPrimarieSelection: (primary) => dispatch(wizardAction.setPrimarieSelection(primary)),
        onChangeReport: (bool) => dispatch(wizardAction.changeReport(bool)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Primaries);