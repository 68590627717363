export const initCheckUsersReports = usersGroupData => {

    const users = usersGroupData.users.map(user => {

        let commanderReported = (user.approvedMainCode !== null || user.approvedSecondaryCode !== null)

        let notReported = false

        let compatibleReported = false

        let userReported = (user.reportedMainCode === null || user.reportedSecondaryCode === null)

        let reportedConflict = ((user.reportedMainCode !== null && user.recommandedMainCode !== null && (user.reportedMainCode !== user.recommandedMainCode)) || (user.reportedSecondaryCode !== null && user.recommandedSecondaryCode !== null && user.reportedSecondaryCode !== user.recommandedSecondaryCode))

        if (commanderReported) {
            notReported = false
        }
        if (userReported) {
            notReported = true
        }
        
        if (!reportedConflict && user.reportedMainCode !== null && user.recommandedMainCode !== null  && user.reportedSecondaryCode !== null && user.recommandedSecondaryCode !== null ) {
            compatibleReported = true
        }

        return {
            ...user,
            reportedConflict: reportedConflict,
            notReported: notReported,
            isActiveRow: false,
            compatibleReported: compatibleReported,
            commanderReported: commanderReported
        };

        // //commander reported
        // if (user.approvedMainCode !== null || user.approvedSecondaryCode !== null) {
        //     console.log("commander reported")
        //     return {
        //         ...user,
        //         reportedConflict: false,
        //         notReported: false,
        //         isActiveRow: false,
        //         compatibleReported: false
        //     };
        // }
        // //user not reported
        // if(
        //     user.reportedMainCode === null ||
        //     user.reportedSecondaryCode === null
        // ) {
        //     console.log("user not reported")
        //     return {
        //         ...user,
        //         notReported: true,
        //         reportedConflict: false,
        //         isActiveRow: false,
        //         compatibleReported: false
        //     };
        // }
        // //user conflict
        // if (
        //     // user.recommandedMainCode && user.recommandedSecondaryCode &&
        //     ( (user.reportedMainCode !== null && user.recommandedMainCode !== null && (user.reportedMainCode !== user.recommandedMainCode)) || (user.reportedSecondaryCode !== null && user.recommandedSecondaryCode !== null && user.reportedSecondaryCode !== user.recommandedSecondaryCode))
        // ) {
        //     console.log("user conflict")
        //     return {
        //         ...user,
        //         //notReported: false,
        //         reportedConflict: true,
        //         isActiveRow: false,
        //         compatibleReported: false
        //     };
        // }
        // else {
        //     console.log("not user conflict")
        //     return {
        //         ...user,
        //         reportedConflict: false,
        //         //notReported: false,
        //         isActiveRow: false,
        //         compatibleReported: true,
        //         approvedMainCode: user.reportedMainCode,
        //         approvedMainName: user.reportedMainName,
        //         approvedSecondaryCode: user.reportedSecondaryCode,
        //         approvedSecondaryName: user.reportedSecondaryName
        //     };
        // }
    });
    return {
        users: users
    };
};

export const sortUsers = usersArray => {
    return usersArray.sort((a, b) => {
        if (a.favorite < b.favorite) return 1;
        if (a.favorite > b.favorite) return -1;

        if (a.createdToday < b.createdToday) return 1;
        if (a.createdToday > b.createdToday) return -1;

        if ( a.commanderReported < b.commanderReported) return -1;
        if (a.commanderReported > b.commanderReported) return 1;
       
        if ( a.notReported < b.notReported) return 1;
        if ( a.notReported > b.notReported) return -1;

        if ( a.reportedConflict < b.reportedConflict) return 1;
        if ( a.reportedConflict > b.reportedConflict) return -1;

        

        // if ( a.commanderReported < b.commanderReported) return 1;
        // if (a.commanderReported > b.commanderReported) return -1;

        
        
        
        
        
        
        // if ( a.reportedConflict < b.reportedConflict) return 1;
        // if ( a.reportedConflict > b.reportedConflict) return -1;
        
        // if ( a.notReported < b.notReported) return 1;
        // if ( a.notReported > b.notReported) return -1;



        // if (a.favorite < b.favorite) return 1;
        // if (a.favorite > b.favorite) return -1;

        // if (a.createdToday < b.createdToday) return 1;
        // if (a.createdToday > b.createdToday) return -1;
        
        // if ( a.commanderReported < b.commanderReported) return 1;
        // if (a.commanderReported > b.commanderReported) return -1;
        
        // if ( a.reportedConflict < b.reportedConflict) return 1;
        // if ( a.reportedConflict > b.reportedConflict) return -1;
        
        // if ( a.notReported < b.notReported) return 1;
        // if ( a.notReported > b.notReported) return -1;


        
        return 0
    })
}

export const checkValidationGroup = (users, isCommanderMustSendFullReport) => {
    if (users === undefined || users.length === 0) return false;
    return isCommanderMustSendFullReport ? users.every(user => user.editedAndNotSent || user.userRemovedFromGroup) : users.some(user => user.editedAndNotSent)
};


export const checkDeterminedGroup = users => {
    if (users === undefined || users.length === 0) return false;
    let isDetermined = false;
    users.forEach(e => {
        if (e.isDetermined) {
            isDetermined = true;
        }
    });
    return isDetermined;
};

export const checkIfEmptyNameAndAddNameToGroup = (groups) => {
    return groups.map(group => {
        if (!group.groupName || group.groupName==="") {
            return {
                ...group,
                groupName:  "קבוצה "  + group.groupCode 
            }
            
        } else {
            return {
                ...group,
            }
        }
    })
}

export const checkShowOnlyGroup = (groups) => {
    return groups.map(group => {
        if (group.groupAuthIndication === 3 || group.groupAuthIndication === 4) {
            return {
                ...group,
                showOnly: true
            }
        } else {
            return {
                ...group,
                showOnly: false
            }
        }
    })
    
}

export const filterUsers = (filterListData, tempUsers) => {
    let users = [];
    let filteredUsers = [];
    let filteredUsersStatus = [];
    let filteredUsersPersonal = [];
    let filteredUsersCommander = [];
    let hasStatus = false
    let hasPersonal = false
    let hasCommander = false

    if (!filterListData.length) return tempUsers;
    filterListData.forEach(item => {
        if (item.type === 'status') {
            filteredUsers = tempUsers.filter(user => user[item.mainCode])
            filteredUsersStatus = [...filteredUsersStatus, ...filteredUsers]
            hasStatus = true
        }
        if (item.type === 'personalReported') {
            filteredUsers = tempUsers.filter(user => {
                return user.reportedMainCode === item.mainCode && user.reportedSecondaryCode === item.secondaryCode
            })
            filteredUsersPersonal = [...filteredUsersPersonal, ...filteredUsers]
            hasPersonal = true
        }
        if (item.type === 'commanderReported') {
            filteredUsers = tempUsers.filter(user => {
                return user.approvedMainCode === item.mainCode && user.approvedSecondaryCode === item.secondaryCode
            })
            filteredUsersCommander = [...filteredUsersCommander, ...filteredUsers]
            hasCommander = true
        }
        //users = [...users, ...filteredUsers]
        //users = removeDuplicates(users, 'mi')


    })
    if (!hasStatus) {
        filteredUsersStatus = tempUsers
    }
    if (!hasPersonal) {
        filteredUsersPersonal = tempUsers
    }
    if (!hasCommander) {
        filteredUsersCommander = tempUsers
    }
    users = mergeLists(filteredUsersCommander, filteredUsersPersonal, filteredUsersStatus)

    return users
}

function mergeLists() {
    let temp = null
    for (var i = 0; i < arguments.length; i++) {
        let arg = arguments[i]
        temp = temp ? temp.filter(x => arg.indexOf(x) > -1) : arg
    }
    return temp;
}


export const removeDuplicates = (myArr, main, secondary, type) => {
    return myArr.filter((obj, index, arr) => {
        return index === arr.findIndex((item) => item[main] === obj[main] && item[secondary] === obj[secondary] && item[type] === obj[type])
        
    });
}

export const sortStatisticUsers = (statistic) => {
    let sectionsStatistics = statistic.sectionsStatistics.map(group => {
        let membersStatistics = sortUsersByName(group.membersStatistics);

        return {
            ...group,
            membersStatistics
        }
    })
    return {
        ...statistic,
        sectionsStatistics
    }
}

export const collapseRow = (row) => {
    let collapseList = row.nextSibling.querySelectorAll('.collapseList')[0];
    let listUsersHeight = row.nextSibling.querySelectorAll('.statisticUsersList')[0].getBoundingClientRect().height;
    if (collapseList.classList.contains('open')) collapseList.style.height = 0 + 'px';
    else collapseList.style.height = listUsersHeight + 'px';
    row.classList.toggle('open');
    collapseList.classList.toggle('open');
}

export const sortUsersByName = (users) => {
    return users.sort((a, b) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        if (a.lastName < b.lastName) return -1;
        if (a.lastName > b.lastName) return 1;
        return 0
    })
}

export const groupBy = (array) =>
    array.reduce((r, a) => {
        r[a.approvedMainName] = [...r[a.approvedMainName] || [], a];
        return r;
    }, {});