import React, { Component } from 'react';
import { connect } from 'react-redux';
import CalendarFuturePicker from '../../shared/components/CalendarFuturePicker';
import * as layoutActions from '../../store/actions/index';
import { urls, calcDiffDays, getFullDate } from '../../shared/utility';
import { withRouter } from "react-router";
import * as futureAction from '../../store/actions/index';
import FutureModal from '../../shared/components/futureModal';

class FutureReport extends Component {

	state = {
		date: new Date(),
		indexDay: null
	}

	componentDidMount() {
		const { location } = this.props

		const previousYear = location && location.state && location.state.y
		const previousMonth = location && location.state && location.state.m
		this.props.onShowWizardHeader();
		const currentDate = new Date();
		(previousYear && previousMonth) ?
			this.props.onFetchFutureReports(previousMonth, previousYear) :
			this.props.onFetchFutureReports((currentDate.getMonth() + 1), currentDate.getFullYear())
				.then(_ => {
					if (location.state && location.state.reportDate && this.props.futureReport && this.props.futureReport.days) {
						let index = this.props.futureReport.days.findIndex(a => getFullDate(a.date) === getFullDate(location.state.reportDate));
						if (index !== -1) {
							this.refs.futureModalRef.showModal()
							this.setState({
								date: new Date(location.state.reportDate),
								indexDay: index
							})
						}
					}
				});
	}

	goToPrimaries = (date) => {
		this.props.history.push({
			pathname: urls.primaries,
			state: { reportDate: date }
		});
	}

	disabledDay = (date) => {
		let _day = date.getDay();
		let _isWeekendReportActive = this.props.futureReport.isWeekendNachsalReportActive;
		return (!_isWeekendReportActive && (_day === 5 || _day === 6))
			|| this.props.primaries.filter(a => a.secondaries.some(b => b.futureReportDays >= calcDiffDays(date))).length === 0;
	}


	render() {
		const { location } = this.props
		return (
			<>
				<div className="historyPrat">
					<div className="container-fluid">
						<CalendarFuturePicker
							previousYear={location && location.state && location.state.y}
							previousMonth={location && location.state && location.state.m}
							fetchData={(month, year) => this.props.onFetchFutureReports(month, year)}
							future={this.props.futureReport}
							goToPrimaries={(date) => this.goToPrimaries(date)}
							activeUser={this.props.activeUser}
							contentManagment={this.props.contentManagment}
							deleteReport={(date) => this.props.onDeleteFutureReports(date)}
							isDisableDay={(date) => this.disabledDay(date)}
							gender={this.props.gender} />
					</div>
				</div>


				<FutureModal
					ref="futureModalRef"
					days={this.props.futureReport.days}
					indexDay={this.state.indexDay}
					successReport={true}
					contentManagment={this.props.contentManagment}
					goToPrimaries={(date) => this.goToPrimaries(date)}
					gender={this.props.gender}
				/>
			</>
		)
	}
}

const mapStateToProps = state => {
	return {
		activeTabCode: state.commander.activeTabCode,
		activeTabCodeHistory: state.history.activeTabCode,
		futureReport: state.future.futureReports,
		activeDate: state.history.activeDate,
		activeUser: state.history.activeUser,
		contentManagment: state.layout.contentManagment,
		gender: state.wizard.gender,
		primaries: state.wizard.primaries,
	};
}

const mapDispatchToProps = dispatch => {
	return {
		onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
		onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
		onFetchFutureReports: (month, year) => dispatch(futureAction.fetchFutureReports(month, year)),
		onDeleteFutureReports: (date) => dispatch(futureAction.deleteFutureReports(date))
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FutureReport)); 