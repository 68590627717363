
import React, { Component } from 'react';
import { getText } from '../../shared/utility';
class BtnsReport extends Component {

    state = {
        PersonalReportText: '',
        RecommendReportText: '',
        ReportAnotherStatusText: '',
    }

    componentDidMount() {
        this.loadText()
    }

    loadText = () => {
        this.setState({
            PersonalReportText: getText(this.props.mainScreenCommanderManagment, 'PersonalReport', this.props.gender),
            RecommendReportText: getText(this.props.mainScreenCommanderManagment, 'RecommendReport', this.props.gender),
            ReportAnotherStatusText: getText(this.props.mainScreenCommanderManagment, 'ReportAnotherStatus', this.props.gender),
        })
    }

    checkDisabled = () => {
        return !this.props.isValidTimeToReport || this.props.group.showOnly || this.props.isGroupDetermined || this.props.isCantReport;
    }

    render() {
        let btnPersonalRadioClass = 'solger__radio-btn ';
        let btnRecommandedRadioClass = 'solger__radio-btn ';
        let btnOtherStatus = 'solger__radio-btn '
        let futureIcon='';
        if (this.props.user.notReported) {
            btnPersonalRadioClass += ' error'
        }
        if (this.checkDisabled()) {
            btnPersonalRadioClass += ' readOnly'
            btnRecommandedRadioClass += ' readOnly'
            btnOtherStatus += ' readOnly'
        }
        if (this.props.user.approvedMainCode !== null && this.props.user.approvedMainCode === this.props.user.reportedMainCode) {
            btnRecommandedRadioClass = btnRecommandedRadioClass.replace('active', '').slice();
            btnPersonalRadioClass += ' active';
        }
        if (this.props.user.approvedMainCode !== null && this.props.user.approvedMainCode === this.props.user.recommandedMainCode) {
            btnPersonalRadioClass = btnPersonalRadioClass.replace('active', '').slice();
            btnRecommandedRadioClass += ' active';
        }
        if (this.props.user.isFutureReport) 
            futureIcon = <img className="futureIcon" src="/img/futureCommander.png" alt="" />
        return (
            <fieldset className="solger__radio-btn-group">
                <legend className="visually-hidden"></legend> {/* write this text fo accessibility */}
                <div className="solger__radio-btn-group_wrap">
                    <button
                        type="button"
                        className={btnPersonalRadioClass}
                        onClick={() => this.props.onReportBtnClick(this.props.user, 'reported')}
                        disabled={this.checkDisabled()}>
                        <span className="solger__radio-btn_header">{this.state.PersonalReportText}</span>
                        <span>{this.props.user.reportedMainName}</span>
                        <span>{this.props.user.reportedSecondaryName}</span>
                        <span>{futureIcon}</span>
                    </button>
                    <button
                        type="button"
                        className={btnRecommandedRadioClass}
                        onClick={() => this.props.onReportBtnClick(this.props.user, 'recommanded')}
                        disabled={this.checkDisabled()}>
                        <span className="solger__radio-btn_header">{this.state.RecommendReportText}</span>
                        <span>{this.props.user.recommandedMainName}</span>
                        <span>{this.props.user.recommandedSecondaryName}</span>
                    </button>
                    <button
                        type="button"
                        className={btnOtherStatus}
                        onClick={() => this.props.goToOtherStatus(this.props.user)}
                        disabled={this.checkDisabled()}>
                        {
                            this.props.isGroupDetermined ?
                                (<><span className="solger__radio-btn_header">דיווח אחראי</span>
                                    <span>{this.props.user.approvedMainName}</span>
                                    <span>{this.props.user.approvedSecondaryName}</span></>)
                                :
                                <span className="solger__radio-btn_header">{this.state.ReportAnotherStatusText}</span>
                        }
                    </button>
                </div>
            </fieldset>
        )
    }
}
export default BtnsReport;

