import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as layoutActions from '../../store/actions/index';
import * as commanderActions from '../../store/actions/index';
import TitleContainer from '../../shared/components/titleContainer'
import Loader from '../../shared/components/loader';
import { urls } from '../../shared/utility';
import { Link } from 'react-router-dom';
import UserListCount from '../../commander/components/userListCount'

class ConfirmGroup extends Component {

  state = {
    confirmUsers: [],
    loader: false
  }

  componentDidMount() {
    this.props.onShowWizardHeader();
    this.setState({
      confirmUsers: this.props.usersGroup.users
    })
  }

  filterConfirmUsers = () => {
    return this.props.usersGroup.users.filter(user => user.editedAndNotSent)
  }

  submitGroupReport = () => {

    if (this.props.usersGroup.isGroupValid) {

      const usersReport = this.state.confirmUsers.map(user => {
        return {
          mi: user.mi,
          approvedMainCode: user.approvedMainCode,
          approvedSecondaryCode: user.approvedSecondaryCode
        }
      })
      const dataToSubmit = {
        groupCode: this.props.activeTabCode,
        usersReport: usersReport
      }
      this.setState({
        loader: true
      })
      this.props.onPostSelection(dataToSubmit, this.props.activeTabName)
        .then(() => {
          this.props.onFetchGroupsAuthIndication().then(() => {
            this.setState({ loader: false })
            this.props.history.push(urls.sendSuccsess)
          })
            .catch(err => { })
        })
    }
  }

  redirectToCommanderPage = () => {
    this.props.onFetchCommander(this.props.activeTabCode)
    this.props.history.push({
        pathname: urls.commander,
        state: { prevUrl: null} 
      })
}

  render() {

    if (this.state.loader) {
      return <Loader />
    }
    if(this.props.isCommanderMustSendFullReport){
      return (
        <div className="commander__main">
          <TitleContainer
            mainTitle={`האם ברצונך לשלוח דיווחים עבור  ${this.props.activeTabName}?`}
            isShadow={true} />

          <div className="container-fluid">
            {this.props.usersGroup.users ? <UserListCount users={this.state.confirmUsers} /> : null}
          </div>

          <div className="btnSendReportContainer">
            <button
              type="button"
              className="btnGeneral send"
              onClick={this.submitGroupReport}>שליחת דיווחי קבוצה</button>
            <Link to={urls.commander} className="cancelBtn underline">ביטול</Link>
          </div>
        </div>
      )
    }
    else {
      return(
        <div className="commander__main">
        <TitleContainer
          mainTitle={`דיווחי הנוכחות על ${this.props.activeTabName} הם:`}
          isShadow={true} />

        <div className="container-fluid">
          {this.props.usersGroup.users ? <UserListCount users={this.state.confirmUsers} /> : null}
        </div>

        <div className="btnSendReportContainer">
          <button
            type="button"
            className="btnGeneral send"
            onClick={this.redirectToCommanderPage}> המשך </button>
        </div>
      </div>
      )
    }

  }
}

const mapStateToProps = state => {
  return {
    usersGroup: state.commander.usersGroup,
    activeTabName: state.commander.activeTabName,
    activeTabCode: state.commander.activeTabCode,
    isCommanderMustSendFullReport: state.commander.isCommanderMustSendFullReport
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
    onPostSelection: (selection, groupName) => dispatch(commanderActions.postSelection(selection, groupName)),
    onFetchGroupsAuthIndication: () => dispatch(commanderActions.fetchGroupsAuthIndication()),
    onFetchCommander: (gCode) => dispatch(commanderActions.fetchCommander(gCode)),

  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGroup);