import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const renderRoute = (props, rest) => {
    return rest.isUserAuthenticated ? (
      <Component {...props} />
    ) : (
       <Redirect to="/login" /> 
    );
  };

  return <Route exact {...rest} render={props => renderRoute(props, rest)} />;
};
export default ProtectedRoute;
