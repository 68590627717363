import axios from 'axios'
//----/ ployfills
if (typeof Object.assign != 'function') {
    Object.defineProperty(Object, "assign", {
        value: function assign(i_target, varArgs) {
            // 'use strict';
            if (i_target == null) {
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var _dest = Object(i_target);

            for (var i = 1; i < arguments.length; i++) {
                var _nextSource = arguments[i];

                if (_nextSource != null) {
                    for (var nextKey in _nextSource) {
                        if (Object.prototype.hasOwnProperty.call(_nextSource, nextKey)) {
                            _dest[nextKey] = _nextSource[nextKey];
                        }
                    }
                }
            }
            return _dest;
        },
        writable: true,
        configurable: true
    });
}

//----/ AIDLib
//var AIDLib = AIDLib || {};

var Android = window.Android || null;

export const AIDLib = {
    MobileBridgeManager: (function () {
        var m_instance,
            MobileBridgeHandler = (function () {
                var m_settings = null,
                    m_mobileBridge = null,
                    m_currDeviceType = null,
                    m_browserData = null,
                    m_activeCallback = null;

                function MobileBridgeHandler() {
                    setDefultSettings();
                }

                MobileBridgeHandler.prototype.GetDeviceType = function () {
                    return m_currDeviceType;
                }

                MobileBridgeHandler.prototype.GetBrowserData = function () {
                    return m_browserData;
                }

                MobileBridgeHandler.prototype.SetSettings = function (i_settings) {
                    m_settings = Object.assign(i_settings, m_settings);
                }

                MobileBridgeHandler.prototype.GetTokenFromMobileBridgeAndSubmitAsync = function (i_currBridgeCallback, tz) {
                    //TODO: once bridge is active then validate methods!!! change accordingly
                    //also, when checking for activeCallback allways reset it back to null
                    m_settings.userId = tz;
                    m_activeCallback = (typeof i_currBridgeCallback === "function") ? m_activeCallback = i_currBridgeCallback : null;

                    var o_data = { "actionType": "setNotificationToken", "callbackName": m_settings.callbackMap.ToTokenReciever }

                    if (m_currDeviceType === "ios") {
                        window.webkit.messageHandlers.appJSBridge.postMessage(o_data)
                    } else if (m_currDeviceType === "android") {
                        Android.InitCallback(JSON.stringify(o_data));
                    }

                    //m_mobileBridge.GetToken(m_settings.callbackMap.ToTokenReciever);
                }

                MobileBridgeHandler.prototype.SubmitUserData = function (i_token) {
                    //returnd token must be a string!! so please follow the returned object type.

                    var _isValidData = validateUserCredentialsToSend(i_token);

                    if (!_isValidData) console.log("bad data was given to send.");
                    else {
                        var _dataToSend = getUserCredentialsDataToSend(i_token),
                            _callback = m_activeCallback ? Object.assign({}, m_activeCallback) : null;

                        m_activeCallback = null;

                        var url = m_settings.server.saveToken;
                        var model = _dataToSend;

                        //console.log("_dataToSend = " + _dataToSend);

                        axios.post(url, model).then(res => {
                           // console.log(res);
                            //if (typeof _callback === "function") _callback(i_response);
                        })
                            .catch(err => {
                             //   console.log(err);
                            })
                        // $.post("pathToSend", o_data, function(i_response){
                        //   if (typeof i_callback === "function"){
                        //         i_callback(i_response);
                        //   }
                        // });

                        // // $.post(m_settings.server.saveToken, _dataToSend, function(i_response){
                        // //     if (typeof _callback === "function") _callback(i_response);
                        // // });
                    }
                }

                function getUserCredentialsDataToSend(i_token) {
                    return {
                        userId: m_settings.userId,
                        token: i_token
                    };
                }

                function validateUserCredentialsToSend(i_userCredentialsToValidate) {
                    var o_response = false;

                    if (typeof i_userCredentialsToValidate === "string") {
                        o_response = true;
                    }

                    return o_response;
                }

                function setDefultSettings() {
                    setMobileBridgeData();
                    setBrowserData();
                    m_settings = {
                        userId: "",
                        server: {
                            saveToken: '/api/Notification/SaveToken',//"serverSaveTokenMethodFullPath",//link to server 
                        },
                        callbackMap: {
                            ToTokenReciever: "AIDLib.MobileBridgeManager.RecieveDataFromDevice.SubmitUserData"//function to call back from mobile
                        },

                    }
                };

                function setBrowserData() {
                    m_browserData = (function () {
                        var ua = navigator.userAgent,
                            tem,
                            plat = /Mobile/i.test(ua) ? 'mobile' : 'desktop',
                            dev = 'unknown',
                            nav = 'web-browser',
                            mobileDevicesList = [/Android/i, /iPhone|iPad|iPod/i, /Opera Mini/i, /IEMobile/i, /BlackBerry/i],
                            M = ua.match(/(chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*(\d+)/i) || [],
                            _fullVersionMatch = ua.match(/(chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*(\d+[^\s;]+)/i) || [],
                            _fullVersion = _fullVersionMatch.length ? _fullVersionMatch[2] : null,
                            _isEdge = (ua.indexOf('Edge') > -1 && M[1] === "Chrome") ? true : false,
                            _osDetails = null;

                        if (plat === 'desktop') {
                            dev = /windows/i.test(ua) ? 'windows' : /macintosh/i.test(ua) ? 'mac' : /linux/i.test(ua) ? 'linux' : null;
                            _osDetails = (dev === 'windows') ? navigator.userAgent.match(/(NT[^;]*)/) : (dev === 'mac') ? navigator.userAgent.match(/(OS[^)]*)/) : (dev === 'linux') ? navigator.userAgent.match(/(Linux[^);]*)/) : [];

                        } else {

                            for (var k = 0; k < mobileDevicesList.length; k++) {
                                if (mobileDevicesList[k].test(ua)) {
                                    dev = mobileDevicesList[k].toString().split('/')[1];
                                    break;
                                }
                            }
                            ///////////
                            if ((dev === 'Android' && typeof Android != 'undefined') ||
                                (dev.indexOf('iPhone') > -1 && window.webkit && typeof window.webkit.messageHandlers !== 'undefined' && typeof window.webkit.messageHandlers.appJSBridge !== 'undefined')) {

                                nav = 'application';
                            }
                        }

                        _osDetails = (_osDetails && _osDetails.length) ? _osDetails[0] : null;

                        if (/trident/i.test(M[1])) {
                            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                            return { name: 'IE', version: (tem[1] || ''), plateform: plat, device: dev, navigator: nav, fullVersion: 'trident-' + _fullVersion, osDetails: _osDetails };
                        } else if (_isEdge) {
                            tem = ua.match(/\bEdge\/(\d+)/) || [];
                            return { name: 'Edge', version: (tem[1] || ''), plateform: plat, device: dev, navigator: nav, fullVersion: _fullVersion, osDetails: _osDetails };
                        } else if (!_isEdge && M[1] === 'Chrome') {
                            tem = ua.match(/\bOPR\/(\d+)/) || [];
                            if (tem !== null && tem.length) {
                                return { name: 'Opera', version: tem[1], plateform: plat, device: dev, navigator: nav, fullVersion: _fullVersion, osDetails: _osDetails };
                            }
                        }

                        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

                        if ((tem = ua.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);

                        return {
                            name: (M[0] === "Safari" && ua.match(/CriOS/i)) ? "Chrome" : M[0],
                            version: M[1],
                            plateform: plat,
                            device: dev,
                            navigator: nav,
                            fullVersion: _fullVersion,
                            osDetails: _osDetails
                        };
                    })();
                }

                function setMobileBridgeData() {

                    // TODO: validate bridge object names so call will run properly
                    // also, once bridge is active then use ->

                    if (typeof Android !== "undefined" && Android != null) {
                        m_currDeviceType = "android";
                        m_mobileBridge = Android;
                    } else if (window.webkit && typeof window.webkit.messageHandlers !== "undefined" && typeof window.webkit.messageHandlers.appJSBridge !== "undefined") {
                        m_currDeviceType = "ios";
                        m_mobileBridge = window.webkit.messageHandlers.appJSBridge;
                    }


                    // // m_currDeviceType = "ios";
                    // // m_mobileBridge = {
                    // //     GetToken: "stamToken"
                    // // };
                }

                return MobileBridgeHandler;
            })();

        function createInstance() {
            if (!m_instance) {
                m_instance = new MobileBridgeHandler();
            }

            return m_instance;
        }

        return {
            GetInstance: function () {
                return createInstance();
            },
            RecieveDataFromDevice: {
                SubmitUserData: function (i_response, deviceType) {
                    
                    //console.log(deviceType);
                    if (!m_instance) console.log("no mobile bridge manager instance.");
                    else m_instance.SubmitUserData(i_response);
                }
            }
        };
    })()
}

window.AIDLib = AIDLib;

// // //----/ handler
// // var SingletonMobileBridgeManager = AIDLib.MobileBridgeManager.GetInstance();
// // SingletonMobileBridgeManager.GetTokenFromMobileBridgeAndSubmitAsync(function(response){
// //     //do hear;
// // });

/***************************************** DOC *****************************************
SingletonMobileBridgeManager is a global dom singltone object that is responsible for handling the comunication with mobile bridge.
It takes both device types as same one so all methods should have the same signatures.

public methods:
1. SubmitUserData(string i_token): function m_activeCallback
    -> will try to submit curr user data (only public for async process so basiclly no need to use as stand alone)
2. GetTokenFromMobileBridgeAndSubmitAsync(function i_currBridgeCallback): void
    -> sets the callback method to invoke when response from server returns after saveing data from mobile bridge. DO NOTE async method
3. SetSettings(object i_settings): void
    -> overrides default settings. if missing data is submited then will be taken from defult.
4. GetBrowserData(): object
    -> returns current device browsing data
5. GetDeviceType(): string
    -> returns device type slug as "android" or "ios"

AIDLib.MobileBridgeManager is also a global dom singltone object that is responsible for creating instances and invoke
returns from mobile bridge.

/***************************************************************************************/

