import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { isValidNote } from '../utility';

class NoteModal extends Component {

    state = {
        noteLength: 50,
        note: '',
        tempNote: '',
        errorText: false,
        isInit: true,
        isOnChange: false,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user && state.isInit && props.user.note !== state.note) {
            return {
                note: props.user.note || '',
                isInit: false
            };
        }
        return null;
    }

    editNote = (e) => {
        this.setState({
            isOnChange: true,
            tempNote: this.state.note
        })
        const note = e.target.value;
        if ((note.length <= this.state.noteLength) && isValidNote(note)) {
            this.setState({
                note: note,
                errorText: false
            })
        }
        if ((note.length > this.state.noteLength)) {
            this.setState({
                errorText: true
            })
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        //this.props.user

        const user = {
            ...this.props.user,
            editedAndNotSent: true,
        }

      //  if (!this.state.selectedInValid) {
        
              //  .then(() => this.props.history.push(urls.commander))
       // }
        this.props.updateNote(this.props.user.mi, this.state.note);
        this.props.updateOtherStatus(user)
        this.props.closeNoteModal();
        this.setState({
            tempNote: ''
        })
    }

    closeNoteModal = () => {
        this.props.closeNoteModal()
        this.setState({
            note: this.state.tempNote,
            tempNote: '',
            isOnChange: false,
        })
    }

    render() {
        if (this.props.user && this.props.user.note != this.state.note && !this.state.isOnChange)
            this.setState({
                note: this.props.user.note || ''
            })
        let errorNoteClass = "errorNote";
        if (this.state.errorText) errorNoteClass += " active";
        return (
            <Modal className="mainModal noteModal" show={this.props.isNoteModalOpen} onHide={() => this.closeNoteModal()} centered>
                <div className="noteBox">
                    <button type="button" className="xModal" onClick={() => this.closeNoteModal()}></button>
                    <div className="title">עריכת הערה</div>
                    <form className="formNote" onSubmit={this.submitForm}>
                        <div className="textareaControlContainer">
                            <textarea
                                className="textarea"
                                value={this.state.note}
                                onChange={e => this.editNote(e)}></textarea>
                            <div className={errorNoteClass}>ניתן להזין עד 50 תווים</div>
                        </div>
                        <button type="submit" className="btnGeneral send">אישור</button>
                    </form>
                </div>
            </Modal>
        )
    }

}
export default NoteModal;
