import * as actionTypes from '../actions/actionTypes';
import { checkValidationTime } from '../../shared/utility';
import { filterUsers, removeDuplicates, sortStatisticUsers, sortUsers } from '../../shared/utilityCommander';

const initialState = {
    loader: true,
    endReportTime: null,
    cantReport: null,
    activeTabName: '',
    activeTabCode: '',
    isValidTimeToReport: null,
    usersGroup: {
        users: [],
        isGroupValid: false,
        isGroupDetermined: null,
        isEmergencyPractice: false,
        showEmergencyReport: false
    },
    groupsCodeAndAuthIndication: null,
    selectionGroup: {
        groupCode: null,
        usersReport: []
    },
    otherStatus: [],
    userToUpdateStatus: {},
    isUpdateOtherStatus: false,
    isUpdated: false,
    filterList: [],
    statistic: {},
    isCommanderMustSendFullReport: null,
    isSubmittedToday: false,
    canActivateEmergencyPractice: false,
    isEmergencyActive: false
}

let tempUsers = []

let initGroup = []

const commanderReducer = (state = initialState, action) => {

    let users = []

    switch (action.type) {

        case actionTypes.RESET_STORE:
            return {
                ...initialState
            }

        case actionTypes.SHOW_LOADER_COMMANDER:
            return {
                ...state,
                loader: true
            }

        case actionTypes.SET_VALID_TIME_TO_REPORT:
            let now = Date.now()
            let timeLeftToReport = new Date(state.endReportTime).getTime() - now
            return {
                ...state,
                isValidTimeToReport: timeLeftToReport > 0
            }

        case actionTypes.FETCH_COMMANDER_SUCCESS:
            tempUsers = action.payload.usersGroup.users.slice()
            initGroup = tempUsers
            const isValidTimeToReport = checkValidationTime(action.payload.endReportTime)
            let activeTabName = action.payload.groupsCodeAndAuthIndication[0].groupName
            let activeTabCode = action.payload.groupsCodeAndAuthIndication[0].groupCode
            if(state.activeTabName.length != 0 && state.activeTabCode.length != 0 && state.activeTabCode !== "כל הקבוצות"){
                activeTabName = state.activeTabName
                activeTabCode = state.activeTabCode
            }
            return {
                ...state,
                ...action.payload,
                usersGroup: {
                    ...state.usersGroup,
                    ...action.payload.usersGroup
                },
                loader: false,
                activeTabName: activeTabName,
                activeTabCode: activeTabCode,
                isValidTimeToReport,
                isUpdateOtherStatus: false,
                isUpdated: true,
            }

        case actionTypes.FETCH_COMMANDER_FAILED:
            return {
                ...state,
                loader: false
            }

        case actionTypes.SAVE_COMMANDER_SELECTION:
            // const groupsCodeAndAuthIndicationUpdate = state.groupsCodeAndAuthIndication.map(element => {
            //     if (element.groupCode === action.selectionGroup.groupCode) {
            //         return {
            //             ...element,
            //             isSubmittedToday: true
            //         }
            //     }
            //     return element
            // });
            return {
                ...state,
                // groupsCodeAndAuthIndication: groupsCodeAndAuthIndicationUpdate,
                selectionGroup: action.selectionGroup,
                loader: false,
                usersGroup: {
                    ...state.usersGroup,
                },
                isUpdated: false
            }

        case actionTypes.FETCH_OTHER_STATUSES_SUCCESS:
            return {
                ...state,
                isUpdateOtherStatus: false,
                otherStatus: action.otherStatus,
                endReportTime: action.otherStatus.endReportTime,
                loader: false
            }

        case actionTypes.USER_TO_UPDATE_STATUS:
            return {
                ...state,
                userToUpdateStatus: action.userToUpdateStatus,
            }
        
        case actionTypes.CHECK_IS_SUBMITTED_TODAY_SUCCESS:
            return{
                ...state,
                isSubmittedToday: action.isSubmittedToday
            }
    
        case actionTypes.UPDATE_OTHER_STATUS_SUCCESS:
            let isGroupValid = true
            if (initGroup.length > 0) {
                let updatedUsersIndex = initGroup.findIndex(user => user.mi === action.user.mi)
                initGroup[updatedUsersIndex] = action.user
            }

            users = state.usersGroup.users.map(user => {
                //check validation group after commander user report 
                if (state.isCommanderMustSendFullReport) {
                    if (user.mi !== action.user.mi) {
                        if (!user.editedAndNotSent) {
                            isGroupValid = false
                        }
                    }
                }
                if (user.mi === action.user.mi) {
                    return action.user
                }

                return user
            })

            // users = sortUsers(users)
            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users,
                    isGroupValid,
                },
                loader: false,
                isUpdateOtherStatus: false
            }

        case actionTypes.TOGGLE_USER:

            users = state.usersGroup.users.map(user => {
                if (user.mi === action.mi) {
                    return {
                        ...user,
                        isActiveRow: !user.isActiveRow,
                    }
                }
                return user
            })
            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users
                },
            }

        case actionTypes.FETCH_TAB_GROUP_SUCCESS:

            initGroup = action.usersGroup.users

            let users = {
                ...state.usersGroup,
                ...action.usersGroup,
            }

            const group = state.groupsCodeAndAuthIndication.find(group => group.groupCode === action.activeTabCode)

            if (group.groupCode === "כל הקבוצות") {
                return {
                    ...state,
                    activeTabCode: group.groupCode,
                    activeTabName: group.groupName,
                    loader: false,
                }
            }
            else {

                // const group = state.groupsCodeAndAuthIndication.find(group => group.groupCode === action.activeTabCode)

                tempUsers = action.usersGroup.users.slice()

                return {
                    ...state,
                    filterList: [],
                    usersGroup: {
                        ...state.usersGroup,
                        ...action.usersGroup,
                        isEmergencyPractice: action.usersGroup.isEmergencyPractice,
                        showEmergencyReport: action.usersGroup.showEmergencyReport
                    },
                    users: sortUsers(users.users),
                    activeTabCode: group.groupCode,
                    activeTabName: group.groupName,
                    loader: false,
                    canActivateEmergencyPractice: action.usersGroup.canActivateEmergencyPractice,
                    isEmergencyActive: action.usersGroup.isEmergencyActive
                    //isUpdated: true,
                }
            }

        case actionTypes.SEARCH_USER:
            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users: tempUsers.filter(user => {
                        return `${user.firstName}`.includes(action.value) || `${user.lastName}`.includes(action.value)
                            || `${user.firstName} ${user.lastName}`.includes(action.value)
                            || `${user.lastName} ${user.firstName}`.includes(action.value)
                    })
                }
            }

        case actionTypes.STORE_FILTERS:
            let filterListData = [];
            if (action.filters instanceof Array) {
                filterListData = [...state.filterList, ...action.filters]
            } else {
                filterListData = [...state.filterList, action.filters]
            }
            let uniqueFilterList = removeDuplicates([...filterListData], 'mainCode', 'secondaryCode', 'type')
            return {
                ...state,
                filterList: uniqueFilterList
            }

        case actionTypes.FILTER_USERS:
            //tempUsers = state.usersGroup.users.slice()
            if (initGroup && initGroup.length === 0) {
                initGroup = tempUsers
            }

            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users: filterUsers(state.filterList, initGroup)
                    //users: filterUsers(state.filterList, tempUsers)
                },
                isUpdated: true

            }
        case actionTypes.FETCH_GROUPS_AUTH_INDICATION_SUCCESS:
            return {
                ...state,
                groupsCodeAndAuthIndication: action.groupsCodeAndAuthIndication
            }

        case actionTypes.REMOVE_FILTER:
            let updateFilterList = []
            if(action.secondaryCode && action.pageType) {
                updateFilterList = state.filterList.filter(item => item.mainCode !== action.mainCode || item.secondaryCode !== action.secondaryCode || item.type !== action.pageType)
            }
            else{
                updateFilterList = state.filterList.filter(item => item.mainCode !== action.mainCode)
            }
            let updateUsers = filterUsers(updateFilterList, tempUsers)
            if (updateFilterList && updateFilterList.length === 0) {
                tempUsers = initGroup
                updateUsers = initGroup
            }

            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users: updateUsers
                },
                filterList: [...updateFilterList],
                isUpdated: true
            }

        case actionTypes.REMOVE_ALL_FILTERS:

            if (initGroup && initGroup.length === 0) {
                tempUsers = initGroup
                initGroup = tempUsers
            }

            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users: initGroup
                },
                filterList: [],
                isUpdated: true
            }

        case actionTypes.FETCH_STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: sortStatisticUsers(action.statistic)
            }

        case actionTypes.TOGGLE_FAVORITE:
            let usersList = { ...state.usersGroup }
            if (action.res) {
                usersList = state.usersGroup.users.map(user => {
                    if (user.mi === action.userMi) {
                        return {
                            ...user,
                            favorite: !user.favorite
                        }
                    }
                    return user
                })
            }

            if (initGroup.length > 0) {
                let updatedUsersIndex = initGroup.findIndex(user => user.mi === action.userMi)
                let newUpdatedUsersIndex = usersList.findIndex(user => user.mi === action.userMi)
                initGroup[updatedUsersIndex] = usersList[newUpdatedUsersIndex]
            }

            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users: usersList
                }
            }
        case actionTypes.REMOVE_USER_FROM_GROUP_SUCCESS:
            isGroupValid = true;
            users = state.usersGroup.users.map(user => {
                if (state.isCommanderMustSendFullReport) {
                    if (user.mi !== action.userMi) {
                        if (!user.editedAndNotSent) {
                            isGroupValid = false
                        }
                    }
                }
                if (user.mi === action.userMi) {
                    return {
                        ...user,
                        userRemovedFromGroup: true
                    }
                }
                return user
            })

            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    users,
                    isGroupValid
                }
            }
        case actionTypes.GET_ALL_STATUSES_SUCCESS:
            return {
                ...state,
                isUpdateOtherStatus: true,
                otherStatus: action.statuses

            }
        case actionTypes.SUBMIT_GROUP_NAME_FORM_SUCESS:

            const groupsCodeAndAuthIndication = state.groupsCodeAndAuthIndication.map(group => {
                if (group.groupCode === action.groupCode) {
                    return {
                        ...group,
                        groupName: action.newGroupName
                    }
                }
                return group
            })

            return {
                ...state,
                groupsCodeAndAuthIndication
            }

        case actionTypes.UPDATE_NOTE_SUCCESS:
            if (action.res) {
                let isGroupValid = true
                users = state.usersGroup.users.map(user => {
                    if (user.mi === action.userMi) {
                        return {
                            ...user,
                            note: action.note,
                            editedAndNotSent: true,
                            isUpdated: true
                        }
                    }
                    if (state.isCommanderMustSendFullReport) {
                        if (user.mi !== action.userMi) {
                            if (!user.editedAndNotSent && !user.userRemovedFromGroup) {
                                isGroupValid = false
                            }
                        }
                    }
                    return user
                })
                return {
                    ...state,
                    usersGroup: {
                        ...state.usersGroup,
                        users,
                        isGroupValid
                    },
                    isUpdated: true,
                    loader: false,
                    // isUpdateOtherStatus:true             
                }
            } else {
                return {
                    ...state,
                    isUpdated: true,
                    loader: false,
                    //  isUpdateOtherStatus:true
                }
            }
            

        case actionTypes.CHANGE_EMERGENCY_PRCATICE_MODE_SUCCESS:
            return {
                ...state,
                usersGroup: {
                    ...state.usersGroup,
                    isEmergencyPractice: action.res

                }
            }

        case actionTypes.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTabCode: action.groupCode
            }

        case actionTypes.CLEAR_STATE_COMMANDER:
            return {
                ...initialState
            }
        default:
            return state
    }

}

export default commanderReducer;