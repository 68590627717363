import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import Wizard from '../wizard/containers/wizard';
import Header from './header';
import Login from '../auth/containers/login';
import LoginCommander from '../auth/containers/loginCommander';
import ServerError from '../shared/components/serverError';
import Commander from '../commander/containers/commander';
import SendSuccsess from '../commander/containers/sendSuccsess';
import OtherStatus from '../commander/containers/otherStatus';
import Primaries from '../wizard/containers/primaries';
import Secondaries from '../wizard/containers/secondaries';
import Finish from '../wizard/containers/finish';
import Hp from '../wizard/containers/hp';
import UploadFiles from '../wizard/containers/uploadFiles';
import UpdateDates from '../wizard/containers/updateDates';
import ConfirmGroup from '../commander/containers/confirmGroup';
import { urls } from '../shared/utility';
import Filters from '../commander/containers/filters';
import FilterByStatus from '../commander/containers/filterByStatus';
import HistoryPrat from '../wizard/containers/historyPrat';
import HistoryGroups from '../commander/containers/historyGroups';
import FutureReport from '../wizard/containers/futureReport';
import Settings from '../core/settings';
import ProtectedRoute from '../hoc/protectedRoute';
import LoginRoute from '../hoc/LoginRoute';

class Layout extends Component {
	render() {
		return (
			<Fragment>
				<Header />
				<Switch>
					<LoginRoute
						exact
						path={urls.login}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={Login}
					/>
					<ProtectedRoute
						path={urls.hp}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={Hp}
					/>
					<ProtectedRoute
						path={urls.primaries}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={Primaries}
					/>
					<ProtectedRoute
						path={urls.secondaries}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={Secondaries}
					/>
					<ProtectedRoute
						path={`${urls.uploadFiles}/:param?`}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={UploadFiles}
					/>
					<ProtectedRoute
						path={`${urls.updateDates}/:param?`}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={UpdateDates}
					/>
					<ProtectedRoute
						path={urls.finish}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={Finish}
					/>
					{/* <ProtectedRoute
            path={urls.historyGroups}
            isUserAuthenticated={this.props.isUserAuthenticated}
            component={HistoryGroups}
          /> */}
					<ProtectedRoute
						path={`${urls.historyPrat}/:param?`}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={HistoryPrat}
					/>
					{/* <ProtectedRoute
            path={urls.loginCommander}
            isUserAuthenticated={this.props.isUserAuthenticated}
            component={LoginCommander}
          /> */}
					<ProtectedRoute
						path={urls.serverError}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={ServerError}
					/>
					{/* <ProtectedRoute
            path={urls.commander}
            isUserAuthenticated={this.props.isUserAuthenticated}
            component={Commander}
          /> */}
					{/* <ProtectedRoute
            path={urls.confirmGroup}
            isUserAuthenticated={this.props.isUserAuthenticated}
            component={ConfirmGroup}
          /> */}
					{/* <ProtectedRoute
            path={urls.sendSuccsess}
            isUserAuthenticated={this.props.isUserAuthenticated}
            component={SendSuccsess}
          /> */}
					<ProtectedRoute
						path={`${urls.otherStatus}/:param?`}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={OtherStatus}
					/>
					{/* <ProtectedRoute
            path={urls.filters}
            isUserAuthenticated={this.props.isUserAuthenticated}
            component={Filters}
          /> */}
					<ProtectedRoute
						path={urls.filterByStatus}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={FilterByStatus}
					/>
					<ProtectedRoute
						path={urls.settings}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={Settings}
					/>
					<ProtectedRoute
						path={`${urls.futureReport}`}
						isUserAuthenticated={this.props.isUserAuthenticated}
						component={FutureReport}
					/>

					{/* <Route path={urls.finish} exact component={Finish} /> */}
					<Route path={urls.loginCommander} exact component={LoginCommander} />
					{/* <Route path={urls.serverError} exact component={ServerError} /> */}
					<Route path={urls.commander} exact component={Commander} />
					<Route path={urls.confirmGroup} exact component={ConfirmGroup} />
					<Route path={urls.sendSuccsess} exact component={SendSuccsess} />
					{/* <Route path={`${urls.otherStatus}/:param?`} exact component={OtherStatus} /> */}
					<Route path={urls.filters} exact component={Filters} />
					{/* <Route path={urls.filterByStatus} exact component={FilterByStatus} /> */}
					{/* <Route path={`${urls.historyPrat}/:param?`} exact component={HistoryPrat} />
          <Route path={urls.historyGroups} exact component={HistoryGroups} /> */}
					{/* <Route path={urls.settings} exact component={Settings} /> */}
					<Route path={urls.historyGroups} exact component={HistoryGroups} />

					<Redirect from='/' to={urls.hp} />
				</Switch>
			</Fragment>
		)
	}
}


export default Layout;