import React from 'react'

const tabsPrimaries = (props) => {
    let primaryBtnClass = '';
    let primariesList = '';
    let titleFilter = ''
    if (props.primaries) {

        primariesList = props.primaries.map((primary, index) => {
            (props.activeIndex === index) ? primaryBtnClass = 'primariesCirclesBtn active' : primaryBtnClass = 'primariesCirclesBtn'
            return (
                <li className="item" key={primary.statusCode}>
                    <button className={primaryBtnClass} type="button" onClick={() => props.onPrimaryClick(primary.statusCode, primary.statusDescription, index)}>
                        <div className="primariesCircle">
                            <div className="icon">
                                <img src={`/${primary.icon}`} alt="" />
                            </div>
                        </div>
                        <div className="text">{primary.statusDescription}</div>
                    </button>
                </li>
            )
        })
    }

    if(props.pageType === 'personalReported') titleFilter = 'דיווח אישי'
    if(props.pageType === 'commanderReported') titleFilter = 'דיווח מפקד/ת'

    return (
        <section className="selectPrimariesSection">
            <div className="container-fluid">
                <div className="titleContainer">
                    {
                        props.pageType === '' ?
                            <>
                                <div className="mainTitle"> {props.userName}</div>
                                <div className="subTitle">{props.selectMainStatusText}</div>
                            </> : <div className="mainTitle">סינון לפי {titleFilter}</div>
                    }
                </div>
                <div className="primariesCirclesContainer">
                    <ul className="primariesCirclesList">
                        {primariesList}
                    </ul>
                </div>
            </div>
        </section>
    )

}

export default tabsPrimaries;