import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as layoutActions from '../../store/actions/index';
import * as commanderActions from '../../store/actions/index';
import TitleContainer from '../../shared/components/titleContainer';
import { urls } from '../../shared/utility';

class Filters extends Component {

    componentDidMount() {
        this.props.onShowWizardHeader();
    }

    goToFilterByStatus = () => {
        this.props.history.push(urls.filterByStatus)
    }

    goToFilterBy = (param) => {
        this.props.history.push(`${urls.otherStatus}/${param}`)
    }

    removeAllFilters = () => {
        // e.stopPropagation();
        this.props.onRemoveAllFilters()
    }

    removeFilter = (e,mainCode, secondaryCode, type) => {
        e.stopPropagation();
        this.props.onRemoveFilter(mainCode, secondaryCode, type);
    }

    render() {
        let filteredListByStatus;
        let filteredListByPersonalReport;
        let filteredListByCommanderReport;
        const statusFilters = this.props.filterList.filter(x => x.type === 'status')
        const personalReport = this.props.filterList.filter(x => x.type === 'personalReported')
        const commanderReport = this.props.filterList.filter(x => x.type === 'commanderReported')

        filteredListByStatus = statusFilters.map(x => {
            return (
                <li className="deleteItem" key={x.value}>
                    <div className="filterDeleteBtn" onClick={(e) => this.removeFilter(e,x.mainCode)}>
                        <div className="deleteBtn"></div>
                        <div className="description">{x.value}</div>
                    </div>
                </li>
            )
        })
        filteredListByPersonalReport = personalReport.map(x => {
            return (
                <li className="deleteItem" key={x.mainName}>
                    <div className="filterDeleteBtn" onClick={(e) => this.removeFilter(e,x.mainCode, x.secondaryCode, x.type)}>
                        <div className="deleteBtn"></div>
                        <div className="description">{`${x.mainName} - ${x.secondaryName}`}</div>
                    </div>
                </li>
            )
        })
        filteredListByCommanderReport = commanderReport.map(x => {
            return (
                <li className="deleteItem" key={x.mainName}>
                    <div className="filterDeleteBtn" onClick={(e) => this.removeFilter(e,x.mainCode, x.secondaryCode, x.type)}>
                        <div className="deleteBtn"></div>
                        <div className="description">{`${x.mainName} - ${x.secondaryName}`}</div>
                    </div>
                </li>
            )
        })

        return (
            <div className="pageContainer">
                <TitleContainer
                    iconPath={'img/filter.png'}
                    mainTitle={'סינון לפי'}
                    subTitle={''}
                    isShadow={true} />
                <section className="filterListSection">
                    <div className="container-fluid">
                        <div className="filterListContainer">
                            <ul className="filterList">
                                <li className="item">
                                    <button type="button" className="filterItemBtn" onClick={this.goToFilterByStatus}>
                                        <span className="text">סטטוס</span>
                                        <ul className="filterDeleteList">
                                            {filteredListByStatus}
                                        </ul>
                                    </button>
                                </li>
                                <li className="item">
                                    <button type="button" className="filterItemBtn" onClick={() => this.goToFilterBy('personalReported')}>
                                        <span className="text">דיווח אישי</span>
                                        <ul className="filterDeleteList">
                                            {filteredListByPersonalReport}
                                        </ul>

                                    </button>
                                </li>
                                <li className="item">
                                    <button type="button" className="filterItemBtn" onClick={() => this.goToFilterBy('commanderReported')}>
                                        <span className="text">דיווח מפקד/ת</span>
                                        <ul className="filterDeleteList">
                                            {filteredListByCommanderReport}
                                        </ul>

                                    </button>
                                </li>
                            </ul>
                            <div className="removeFilters">
                                <button type="button" className="cancelBtn underline" onClick={this.removeAllFilters}>
                                    <span className="text">מחיקת מסננים</span>
                                </button>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usersGroup: state.commander.usersGroup,
        activeTabCode: state.commander.activeTabCode,
        filterList: state.commander.filterList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onShowWizardHeader: () => dispatch(layoutActions.showWizardHeader()),
        onRemoveAllFilters: () => dispatch(commanderActions.removeAllFilters()),
        onRemoveFilter: (mainCode, secondaryCode, type) => dispatch(commanderActions.removeFilter(mainCode, secondaryCode, type)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
