import React, { Component, Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import DeleteFutureModal from './deleteModal';
import { getFullDate, getDayName, getText } from '../../shared/utility';
import { calcDiffDays } from '../../shared/utility';

class FutureModal extends Component {

    state = {
        isModalShow: false,
        reportStatusMsgText: '',
        reportStatusUrlText: '',
        indexDay: -1,
        ios: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    }

    componentDidUpdate(prevProps) {
        if (this.props.indexDay !== prevProps.indexDay) {
            this.setState({
                indexDay: this.props.indexDay,
                reportStatusMsgText: getText(this.props.contentManagment.reportedStatus, 'statusMsg', this.props.gender),
                reportStatusUrlText: getText(this.props.contentManagment.reportedStatus, 'statusLink', this.props.gender)
            })
        }
    }

    loadText = () => {
        this.setState({
            reportStatusMsgText: getText(this.props.contentManagment.reportedStatus, 'statusMsg', this.props.gender),
            reportStatusUrlText: getText(this.props.contentManagment.reportedStatus, 'statusLink', this.props.gender)
        })
    }

    closeModal = () => {
        this.setState({
            isModalShow: false
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true
        })
    }

    setReport = (title, type, mainStatus, secondaryStatus) => {
        return (
            <div className={`item ${type}`}>
                <div className="title">{title}</div>
                <div className="text">{mainStatus}</div>
                <div className="text">{secondaryStatus}</div>
            </div>
        )
    }

    goToCalendar = (user) => {
        this.props.goToCalendar(user)
    }

    changeReport = (date) => {
        this.props.goToPrimaries(date)
    }

    showDeleteModal = () => {
        this.setState({
            isModalShow: false
        })
        this.refs.deleteFutureModalRef.showModal()
    }

    renderItem = (item, index) => {
        let header;
        let btn;
        let reportedStatusMsg = ''
        let reportedStatusUrl = ''
        let reportedStatusLink = ''
        let isMobile = window.innerWidth < 768
        if (this.props.successReport) {
            header = <div>
                        תיזמנת בהצלחה לדיווח לתאריך: 
                        <div className="futureDate">
                            {getFullDate(item.date)}
                        </div>
                    </div>
            btn = <button type="button" className="btnGeneral send" onClick={this.closeModal} >אישור וסיום</button>
        }
        else {
            header = <div>
                        דיווח מתוזמן לתאריך: 
                        <div className="futureDate">
                            {getFullDate(item.date)} | יום {getDayName(item.date)}
                        </div>
                        <div className="subTitle">
                            יישלח בעוד {calcDiffDays(item.date)} ימים
                        </div>
                     </div>
            btn = <button type="button" className="deleteLink" onClick={this.showDeleteModal}>מחיקת דיווח</button>
        }

        if (item.showStatusMsg) {
            reportedStatusMsg = this.state.reportStatusMsgText;
            reportedStatusUrl = this.state.reportStatusUrlText;
            if (reportedStatusUrl){
                let link = reportedStatusUrl.startsWith("http") ? reportedStatusUrl : "https://" + reportedStatusUrl
                reportedStatusLink = (
                    <a className="statusLink" onClick={() => {
                        if (!isMobile || /android/i.test(navigator.userAgent)) {
                            window.open(link, "_blank");
                        }
                        else {
                            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                                window.location.assign(link, "_system", 'location=yes')
                            }
                            else {
                                window.webkit.messageHandlers.appJSBridge.postMessage({
                                    "actionType": "web",
                                    "link": link
                                });
                            }

                        }
                        }}
                    > לחץ כאן</a>
                )
            }
        }

        return (
            <div key={index}>
                <div className="futureDetails">
                    {header}
                    <div className="finishBox">
                        <Fragment>
                            <div className="reportRow personal">
                                <div>
                                    <figure className="icon">
                                        <img src={`/${item.icon}`} alt="" />
                                    </figure>
                                    <div className="title">{item.reportedMainName}</div>
                                    <div className="text">{item.secondaryStatusReported}</div>
                                    <button type="button" className="link" onClick={() => this.changeReport(new Date(item.date))}>עריכת דיווח</button>
                                </div>
                                <div className="statusMsg">
                                    <div className="content">{reportedStatusMsg}</div>
                                    {reportedStatusLink}
                                </div>
                            </div>
                        </Fragment>
                    </div>
                    {btn}
                </div>
            </div>
        )
    }

    render() {
        let item;
        let date;
        if (this.props.days && this.state.indexDay > -1 && this.state.indexDay < this.props.days.length) {
            item = this.renderItem(this.props.days[this.props.indexDay])
            date = this.props.days[this.props.indexDay].date
        }

        return (
            <>
                <Modal className="mainModal" show={this.state.isModalShow} onHide={this.closeModal} centered>
                    <div className="futureModalBox">
                        <button type="button" className="xBtn" onClick={this.closeModal}></button>    
                        {item}         
                    </div>
                </Modal>

                <DeleteFutureModal
                    ref="deleteFutureModalRef"
                    date={date}
                    deleteReport={this.props.deleteReport}
                />
            </>
        )
    }
}
export default FutureModal;




