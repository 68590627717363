import React, { Component } from 'react'
import Switch from "react-switch";

class Switcher extends Component {

    state = {
        choice: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.choice !== prevProps.choice) {
            this.setState({
                choice: this.props.choice
            })
        }
    }

    switchChange = (choice) => {
        this.setState({
            choice
        },()=>{
            this.props.switchChange(this.state.choice,this.props.property);
        })
    }

    render() {
        return (
            <Switch
                className="Switch"
                onChange={this.switchChange}
                checked={this.state.choice}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#ffffff"
                activeBoxShadow="box-shadow: rgba(236, 207, 18, 0.25) 0px 1px 27px 0px"
                // activeBoxShadow	="12px 12px 2px 1px #ebcf10 "
                onHandleColor="#f2d92b"
                offHandleColor="#dae6f3"
                className="switch"
                height={22}
                width={44}
                handleDiameter={25}
            />
        )
    }
}
export default Switcher; 