import React, { Component } from 'react';
import { getText } from '../../shared/utility';
import Switcher from '../../shared/components/switcher';
import Switch from "react-switch";
import * as layoutActions from '../../store/actions/index';
import { connect } from 'react-redux'

class KeyPanel extends Component {

    constructor(props) {
        super(props);
        this.inputElementRef = React.createRef();
        this.state = {
            isSearchShow: false,
            isDeleteShow: false,
            soldiersText: '',
            NacsalPracticeText: ''
        }
    }

    componentDidMount() {
        this.loadText()

    }

    loadText = () => {
        this.setState({
            soldiersText: getText(this.props.mainScreenCommanderManagment, 'Soldiers', this.props.gender),
            NacsalPracticeText: getText(this.props.contentManagment.nacsalTexts, 'NacsalPractice', this.props.gender)
        })
    }


    change = (value) => {

        if (value) {
            value = value.replace(/\u200F/,'');
            value = value.replace("‘", "'");
            value = value.replace("’", "'");
            value = value.replace("'", "'");
            this.setState({
                isDeleteShow: true,
            })
        }
        else {
            this.setState({
                isDeleteShow: false,
            })
        }
        this.props.onSearch(value);
    }

    showSearchRow = () => {
        this.setState({
            isSearchShow: true
        })
    }

    closeSearchRow = () => {
        this.setState({
            isSearchShow: false
        })
    }

    deleteSearch = () => {
        this.inputElementRef.current.value = '';
        this.setState({
            isDeleteShow: false
        })
        this.props.onSearch('');
    }

    render() {
        let badgeNote = '';
        let searchClass = 'searchRow';
        let filterCount = '';
        let emergencyPracticeBtn = '';
        if (this.props.showOnly) {
            badgeNote = (<div className="badgeNoteContainer">
                <span className="badgeNote">
                    <img src="/img/eye.png" alt="" />
                    <span className="text">קבוצה לצפייה בלבד</span>
                </span>
            </div>)
        }

        if (this.props.filterListLength) {
            filterCount = <div className="numberFilters">{this.props.filterListLength}</div>
        }

        if (this.state.isSearchShow) { searchClass += ' open' }

        if(this.props.canActivateEmergencyPractice){

            emergencyPracticeBtn = (       
                <div  className="emergencyPractice"> 
                    <label className="emergencyLabel">
                        {this.state.NacsalPracticeText}
                    </label> 
                    <Switch
                        className="Switch"
                        onChange={this.props.changeEmergencyPracticeMode}
                        checked={this.props.isEmergencyPractice}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#ffffff"
                        activeBoxShadow="box-shadow: rgba(236, 207, 18, 0.25) 0px 1px 27px 0px"
                        onHandleColor="#f2d92b"
                        offHandleColor="#dae6f3"
                        className="switch"
                        height={22}
                        width={44}
                        handleDiameter={25}
                    />
                </div>
            )
        }

        return (
            <div className="rowFilterContainer">
                <div className="searchContainer">
                    <div className="container-fluid">
                        <div className={searchClass}>
                            {this.state.isDeleteShow ?
                                <button className="deleteBtn" onClick={this.deleteSearch}></button> : null
                            }
                            <input
                                type="text"
                                className="serachInput"
                                placeholder="הקלד שם לחיפוש"
                                ref={this.inputElementRef}
                                onChange={e => this.change(e.target.value)}>
                            </input>

                            <button type="button" className="xBtn" onClick={this.closeSearchRow}></button>
                        </div>

                    </div>

                </div>
                <div className="container-fluid">
                    {badgeNote}
                    <div className="commander__reports-row_filter">
                        <p className="commander__solgers-count">{this.props.usersLength} {this.state.soldiersText}</p>
                        <div className="filterSearchBtns">
                            <div>
                                {emergencyPracticeBtn}
                                <button className="commander__search-btn" onClick={this.showSearchRow}>
                                    <i className="search-icon"></i>
                                </button>
                                <button className="commander__filter-btn" onClick={this.props.goToFiterPage}>
                                    <i className="filter-icon"></i>
                                    {filterCount}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        contentManagment: state.layout.contentManagment
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetContentManagment: () => dispatch(layoutActions.getContentManagment())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyPanel);