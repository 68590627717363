import * as actionTypes from '../actions/actionTypes';


const initialState = {
    data: null,
    error: null
}

const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STORE:
            return {
                ...initialState
            }
        case actionTypes.FETCH_SETTING_SUCCESS:
            return {
                ...state,
                data: action.data,
            }
        case actionTypes.FETCH_SETTING_FAILED:
            return {
                ...state,
                error: action.error,
            }
        default:
            return state
    }
};

export default settingReducer;