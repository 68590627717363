import React, { Component } from 'react'
import { isValidNote } from '../utility';

class BtnRadioRow extends Component {

    state = {
        note: '',
        url: window.location.pathname.split('/')[1]
    }

    handleOptionChange = (e) => {
        let secondrySelectedInfo = e.currentTarget.value.split(',')
        this.props.setSelectedSecondary(secondrySelectedInfo[0], this.props.secondaryItem)
    }

    getNoteFromUser = (e) => {
        //const viewportmeta = document.querySelector('meta[name=viewport]');
       // viewportmeta.setAttribute('content', "width=device-width, maximum-scale=1 , initial-scale=1.0; user-scalable:no;");

        if (isValidNote(e.target.value)) {
            this.setState({
                note: e.target.value
            }, () => {
                this.props.setNoteFromUser(this.state.note);
            })
        }
    }

    render() {
        return (
            <li className="secondaryItem">
                <label className={this.props.rowClass} htmlFor={this.props.index + '' + this.props.activeIndexPrimary} aria-expanded="false" aria-controls="" onClick={() => this.props.closeNote(this.props.index)}>
                    <input
                        className="inputRadioRow"
                        type="radio"
                        id={this.props.index + '' + this.props.activeIndexPrimary}
                        name={this.props.activeIndexPrimary}
                        value={[this.props.secondaryItem.statusCode.slice(0, this.props.secondaryItem.statusCode.indexOf("-")), this.props.secondaryItem.statusDescription]}
                        onChange={this.handleOptionChange}
                        autoComplete="off" />

                    <span className="radioTextRow">
                        <span className="text">{this.props.secondaryItem.statusDescription}</span>
                        <textarea
                            type="text"
                            className="textareaControl"
                            maxLength="50"
                            value={this.state.note}
                            placeholder="הזן הערה לדיווח הנוכחות (אופציונלי)"
                            onChange={this.getNoteFromUser}
                            ></textarea>
                    </span>
                    {
                        this.props.secondaryItem.canEditNote && ((this.state.url==="commander" && !this.props.isUpdateOtherStatus ) || this.state.url!=="commander")  ?
                            <button type="button" className="noteBtn" onClick={(event) => { this.props.expandRow(event, this.props.index) }}>הוספת הערה</button>
                            : null
                    }
                </label>
            </li>
        )
    }
}

export default BtnRadioRow;
