import React, { Component } from 'react'
import Select from 'react-select';


const options = [
    { value: null, label: 'לא פעיל' },
    { value: 15, label: '15 דקות' },
    { value: 30, label: '30 דקות' },
    { value: 60, label: '60 דקות' },
];

class SelectControl extends Component {

    state = {
        firstLoad: true,
        selectedOption: {
            value: null,
            label: ''
        },
        selectedOptionMobile: -1,//options[0].value,
        selectedOptionMobileView: '' //options[0].label
    };

    componentDidUpdate(prevProps, prevState) {
        if (((this.props.isMobile && this.props.timing !== prevState.selectedOptionMobile) ||
            ((!this.props.isMobile && this.props.timing !== prevState.selectedOption.value))) && (this.state.firstLoad || prevProps.timing !== this.props.timing)) {
            const index = options.findIndex(option => option.value === this.props.timing);
            if (index !== -1) {
                if (this.props.isMobile) {
                    this.setState({
                        firstLoad: false,
                        selectedOptionMobile: options[index].value,
                        selectedOptionMobileView: options[index].label
                    })
                } else {
                    this.setState({
                        firstLoad: false,
                        selectedOption: {
                            value: options[index].value,
                            label: options[index].label
                        }
                    })
                }
            }
        }
    }

    handleChange = selectedOption => {
        this.setState({
            selectedOption
        }, () => {
            this.props.changeSelect(this.state.selectedOption.value, this.props.type);
        });
    };

    handleChangeMobile = (e) => {
        const option = options.find(item => item.value === Number(e.target.value))
        this.setState({
            selectedOptionMobile: !option ? null : e.target.value,
            selectedOptionMobileView: !option ? 'לא פעיל' : option.label
        }, () => {
            this.props.changeSelect(this.state.selectedOptionMobile, this.props.type);
        });
    }

    render() {
        const optionsList = options.map((option, index) => {
            return (
                <option key={index} value={option.value}>{option.label}</option>
            )
        })

        return (
            <>
                {
                    this.props.isMobile ?
                        <label htmlFor={`selectControl-${this.props.type}`} className="selectControlLabel">
                            <select className="selectControlMobile" id={`selectControl-${this.props.type}`} value={this.state.selectedOptionMobile} onChange={this.handleChangeMobile}>
                                {optionsList}
                            </select>
                            <span className="selectControlText">{this.state.selectedOptionMobileView}</span>
                        </label> :
                        <Select
                            className="selectControl"
                            isSearchable={false}
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={options}
                            placeholder={this.state.selectedOption}
                        />

                }
            </>
        )
    }
}
export default SelectControl 