import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Slider from "react-slick";
import { getFullDate, getDayName , stringDownloadFileChange, getText } from '../../shared/utility';
import { downloadFile } from '../../shared/utility';

class HistoryModal extends Component {

    state = {
        isModalShow: false,
        indexDay: 0,
        ios: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    }

    componentDidUpdate(prevProps) {
        if (this.props.indexDay !== prevProps.indexDay) {
            this.setState({
                indexDay: this.props.indexDay
            }, () => {
                this.slider.slickGoTo(this.state.indexDay)
            })
        }
    }

    closeModal = () => {
        this.setState({
            isModalShow: false
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true
        })
    }

    sortDays = (days) => {
        days.sort((a, b) => {
            const aMonth = new Date(a.date).getMonth()
            const bMonth = new Date(b.date).getMonth()
            const aDate = new Date(a.date).getDate()
            const bDate = new Date(b.date).getDate()

            if (aMonth < bMonth) return 1;
            if (aMonth > bMonth) return -1;
            if (aDate < bDate) return 1;
            if (aDate > bDate) return -1;
            return 1;
        })
    }

    setReport = (title, type, mainStatus, secondaryStatus) => {
        return (
            <div className={`item ${type}`}>
                <div className="title">{title}</div>
                <div className="text">{mainStatus}</div>
                <div className="text">{secondaryStatus}</div>
            </div>
        )
    }

    getReportList = (item) => {
        let reportList = [];
        if (item.mainStatusDeterminedName || item.secondaryStatusDeterminedName)
            reportList.push(this.setReport('דיווח ממשרד המשא"ן', 'determine', item.mainStatusDeterminedName, item.secondaryStatusDeterminedName))
        if (item.approvedMainName || item.approvedSecondaryName)
            reportList.push(this.setReport('דיווח מפקד/ת', 'commander', item.approvedMainName, item.approvedSecondaryName))
        if (item.reportedMainName || item.secondaryStatusReported)
            reportList.push(this.setReport('דיווח אישי', '', item.reportedMainName, item.secondaryStatusReported))
        if (item.recommandedMainName || item.recommandedSecondaryName)
            reportList.push(this.setReport('דיווח מומלץ', 'recommended', item.recommandedMainName, item.recommandedSecondaryName))
        return reportList
    }

    generateAlert = (item) => {
        let text = '';
        // if(item.secondaryStatusReported && item.secondaryStatusDeterminedName && item.recommandedSecondaryName &&
        //     item.reportedMainName && item.recommandedMainName && item.mainStatusDeterminedName &&
        //      (item.secondaryStatusReported === item.secondaryStatusDeterminedName)  && (item.secondaryStatusDeterminedName === item.recommandedSecondaryName) &&
        //      (item.reportedMainName === item.recommandedMainName)  && (item.recommandedMainName === item.mainStatusDeterminedName)) return;
        if ((!item.mainStatusDeterminedName || !item.secondaryStatusDeterminedName) || (!item.approvedMainName && !item.reportedMainName)) return;
        if (item.approvedMainName && (item.mainStatusDeterminedName !== item.approvedMainName) && (item.secondaryStatusDeterminedName !== item.approvedSecondaryName)) {
            text = 'דיווח אחראי שונה מדיווח קובע';
        }
        else if (item.reportedMainName && (item.mainStatusDeterminedName !== item.reportedMainName) && (item.secondaryStatusDeterminedName !== item.secondaryStatusReported)) {
            text = 'דיווח אישי שונה מדיווח קובע';
        }
        if(text === ''){
            return
        }
        return (
            <div className="alertText">
                <img className="icon" src="/img/errorIcon.png" alt="" />
                <span>{text}</span>
            </div >
        )
    }

    goToCalendar = (user) => {
        this.props.goToCalendar(user)
    }

    goToUploadFiles = (item) => {
        this.props.setDateToUploadFiles(item);        
    }

    renderFileRow = (fileId, fileName, canDownloadFile, fileStatus, isCommanderState) => {
        let status = this.setFileStatus(fileStatus)
        return (
            <div className = "downloadFileBtnContainer">
                <div>
                    <button type="button" className={`downloadFileBtn border  ${!canDownloadFile && ' disabled'}`} onClick={() => canDownloadFile && downloadFile(fileId,fileName)}>
                        <img className="solger__action-icon" src={require('../../../src/assets/img/attach.png')} alt="צרף מסמך" />
                        {/* <span className="fileName">{fileName} {!canDownloadFile && " - בתהליך שמירה"}</span> */}
                        <span className="fileName">{stringDownloadFileChange(fileName,canDownloadFile, 9, isCommanderState)}</span>
                    </button>
                </div>
                <div className="text"> {status} </div>
            </div>
        )
    }

    setFileStatus = (status) => {
        if (status === 'success')
            return getText(this.props.contentManagment.fileStatus, 'RedAckSuccess', this.props.gender)

        else if (status === 'fail')
            return getText(this.props.contentManagment.fileStatus, 'RedAckFail', this.props.gender)

        else 
            return getText(this.props.contentManagment.fileStatus, 'WaitingForAck', this.props.gender)

    }

    renderItem = (item, index) => {
        let reportList = this.getReportList(item);
        let date = this.props.isCommanderState ? this.props.date : item.date;
        let dateConteinerClass = this.props.isCommanderState ? 'dateConteiner' : ''
        return (
            <div key={index}>
                <div className="headerInfo">
                    <div className="timeContainer">
                        {this.props.isCommanderState && <div className="name">{item.firstName} {item.lastName}</div>}
                        <div className={dateConteinerClass}>
                            <div className="date">{getFullDate(date)} </div>
                            <div className="day">יום {getDayName(date)}</div>
                        </div>
                    </div>
                    {this.generateAlert(item)}
                </div>

                <ul className="reportList">
                    {reportList.map((item, i) => <li key={i}>{item}</li>)}
                </ul>

                {
                   
                    ( item.canUploadFile) &&
                    <div className="uploadBtnBlueContanier">
                        <button type="button" onClick={() => this.goToUploadFiles(item)} className="uploadBtnBlue">
                            <img className="icon" src="/img/attachFile.png" alt="" />
                            <span className="text">העלאת מסמכים</span>
                        </button>
                    </div>
                }
                {item.fileID1 && this.renderFileRow(item.fileID1, item.fileName1, item.canDownloadFile1, item.fileStatus1, item.isCommanderState)}
                {item.fileID2 && this.renderFileRow(item.fileID2, item.fileName2, item.canDownloadFile2, item.fileStatus2, item.isCommanderState)}

                <div className="noteContainer">
                    <p>
                        {item.note}
                    </p>
                </div>
                {
                    this.props.isCommanderState && <div className="historyReportBtnContainer">
                        <button type="button" className="historyReportBtn" onClick={() => this.goToCalendar(item)}>
                            <img className="icon" src="/img/historyIcon.png" alt="" />
                            <span>היסטוריית דיווחים אישית</span>
                        </button>
                    </div>
                }

            </div>
        )
    }

    render() {
        let itemCarusel;
        let settings = { unslick: true }
        if (!this.props.isCommanderState) {
            settings = {
                rtl: true,
                initialSlide: this.state.indexDay,
                speed: this.state.ios ? 50 : 500,
                // fade: true,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }

        if (this.props.days) {
            this.sortDays(this.props.days);
            itemCarusel = this.props.days.map((day, index) => this.renderItem(day, index))
        }

        if (this.props.user && this.state.isModalShow) {
            itemCarusel = this.renderItem(this.props.user, 0)
        }

        return (

            
            <Modal className="mainModal" show={this.state.isModalShow} onHide={this.closeModal} centered>
                <div className="historyModalBox">
                    <button type="button" className="xBtn" onClick={this.closeModal}></button>             
                    <Slider {...settings} ref={slider => (this.slider = slider)} className="historyModalCarusel">    
                        {itemCarusel}
                    </Slider>     
                </div>
            </Modal>
        )
    }
}
export default HistoryModal;




