import React from 'react'

const loader = (props) => {
    return (
        <div className="loaderContainer">
            <div className="wrapper c-loader">
                <svg viewBox="0 0 34 34">
                    <line className="dot active" x1="3" y1="17" x2="31" y2="17"></line>
                    <circle className="juggler" cx="17" cy="17" r="14" />
                </svg>
            </div>
        </div>
    )
}

export default loader;