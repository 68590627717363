
/*Wizard*/ 
export const SHOW_LOADER_WIZARD = 'SHOW_LOADER_WIZARD';
export const SET_WIZARD = 'SET_WIZARD';
export const FETCH_WIZARD_FAILED = 'FETCH_WIZARD_FAILED';
export const SET_POSITION = 'SET_POSITION';
export const SAVE_SELECTION = 'SAVE_SELECTION';
export const SAVE_SELECTION_FAILED = 'SAVE_SELECTION_FAILED';
export const STORE_SELECTION_SUCCESS = 'STORE_SELECTION_SUCCESS';
export const GO_TO_PREV_STEP = 'GO_TO_PREV_STEP';
export const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';
export const GO_TO_START = 'GO_TO_START';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_PRIMARIE_SELECTION = 'SET_PRIMARIE_SELECTION';
export const FETCH_REPORTED_DATA_SUCCESS = 'FETCH_REPORTED_DATA_SUCCESS';
export const CHANGE_REPORT = 'CHANGE_REPORT';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const STORE_FULL_SELECTION = 'STORE_FULL_SELECTION';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';
export const STORE_FILES = 'STORE_FILES';
export const SET_CHANGE_REPORT ='SET_CHANGE_REPORT';
export const FETCH_FUTURE_MODE_SUCCESS = 'FETCH_FUTURE_MODE_SUCCESS';

/*Login*/
export const SHOW_LOADER_AUTH = 'SHOW_LOADER_AUTH';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const GET_USER_AUTH = 'GET_USER_AUTH';
export const GET_USER_AUTH_FAIL = 'GET_USER_AUTH_FAIL';
export const GET_USER_AUTH_SUCCESS = 'GET_USER_AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const LOGOUT_COMMANDER = 'LOGOUT_COMMANDER';
export const RESET_STORE = 'RESET_STORE';

/*Commander*/
export const SHOW_LOADER_COMMANDER = 'SHOW_LOADER_COMMANDER';
export const FETCH_COMMANDER_SUCCESS = 'FETCH_COMMANDER_SUCCESS';
export const FETCH_COMMANDER_FAILED = 'FETCH_COMMANDER_FAILED';
export const CHECK_IS_SUBMITTED_TODAY_SUCCESS = 'CHECK_IS_SUBMITTED_TODAY_SUCCESS';
export const SAVE_COMMANDER_SELECTION = 'SAVE_COMMANDER_SELECTION'
export const FETCH_OTHER_STATUSES_SUCCESS = 'FETCH_OTHER_STATUSES_SUCCESS'
export const UPDATE_OTHER_STATUS_SUCCESS = 'UPDATE_OTHER_STATUS_SUCCESS'
export const USER_TO_UPDATE_STATUS = 'USER_TO_UPDATE_STATUS'
export const TOGGLE_USER = 'TOGGLE_USER'
export const FETCH_TAB_GROUP_SUCCESS = 'FETCH_TAB_GROUP_SUCCESS'
export const SAVE_SELECTION_SUBMIT = 'SAVE_SELECTION_SUBMIT'
export const SEARCH_USER = 'SEARCH_USER'
export const STORE_FILTERS = 'STORE_FILTERS'
export const FILTER_USERS = 'FILTER_USERS'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS'
export const FETCH_STATISTIC_SUCCESS = 'FETCH_STATISTIC_SUCCESS'
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE'
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS'
export const FETCH_GROUPS_AUTH_INDICATION_SUCCESS = 'FETCH_GROUPS_AUTH_INDICATION_SUCCESS'
export const GET_ALL_STATUSES_SUCCESS = 'GET_ALL_STATUSES_SUCCESS'
export const SUBMIT_GROUP_NAME_FORM_SUCESS = 'SUBMIT_GROUP_NAME_FORM_SUCESS'
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAILED = 'UPDATE_NOTE_FAILED'
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'
export const CLEAR_STATE_COMMANDER = 'CLEAR_STATE_COMMANDER'
export const SET_VALID_TIME_TO_REPORT = 'SET_VALID_TIME_TO_REPORT'
export const CHANGE_EMERGENCY_PRCATICE_MODE_SUCCESS = 'CHANGE_EMERGENCY_PRCATICE_MODE_SUCCESS'
export const CHANGE_EMERGENCY_PRCATICE_MODE_FAILED = 'CHANGE_EMERGENCY_PRCATICE_MODE_FAILED'
/*Layout*/
export const SHOW_WIZARD_HEADER = 'SHOW_WIZARD_HEADER'
export const HIDE_WIZARD_HEADER = 'HIDE_WIZARD_HEADER'
export const CHECK_IS_MOBILE = 'CHECK_IS_MOBILE'
export const GET_CONTENT_MANAGMENT_SUCCESS = 'GET_CONTENT_MANAGMENT_SUCCESS'

/*History*/ 
export const SHOW_LOADER_HISTORY = 'SHOW_LOADER_HISTORY';
export const FETCH_HISTORY_PRAT_SUCCESS = 'FETCH_HISTORY_PRAT_SUCCESS';
export const SET_DATE_TO_UPLOAD_FILES = 'SET_DATE_TO_UPLOAD_FILES';
export const FETCH_HISTORY_GROUP_SUCCEESS = 'FETCH_HISTORY_GROUP_SUCCEESS'
export const FETCH_HISTORY_TAB_GROUP_SUCCESS = 'FETCH_HISTORY_TAB_GROUP_SUCCESS'
export const FETCH_HISTORY_TAB_GROUP_ERROR = 'FETCH_HISTORY_TAB_GROUP_ERROR'

export const SET_PREV_DAY = 'SET_PREV_DAY'
export const SET_NEXT_DAY = 'SET_NEXT_DAY'
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER'
export const STORE_FILES_HISTORY = 'STORE_FILES_HISTORY'

/*Setting*/
export const FETCH_SETTING_SUCCESS = 'FETCH_SETTING_SUCCESS'
export const FETCH_SETTING_FAILED = 'FETCH_SETTING_FAILED'
// export const FETCH_SETTING_COMMANDER_SUCCESS = 'FETCH_SETTING_COMMANDER_SUCCESS'
// export const FETCH_SETTING_COMMANDER_FAILED = 'FETCH_SETTING_COMMANDER_FAILED'

/*Future*/
export const FETCH_FUTURE_REPORT_SUCCESS = 'FETCH_FUTURE_REPORT_SUCCESS'
export const SHOW_LOADER_FUTURE = 'SHOW_LOADER_FUTURE'
export const DELETE_FUTURE_REPORT_SUCCESS = 'DELETE_FUTURE_REPORT_SUCCESS'