import React, { Component } from 'react'
import { withMsal } from '@azure/msal-react'
//import { checkValidityTz } from '../../shared/utility';
import Loader from '../../shared/components/loader'
import ReCAPTCHA from 'react-google-recaptcha';
import { config } from './../../config';
import axios from 'axios';
import packageJson from '../../../package.json';
global.appVersion = packageJson.version;

class LoginSection extends Component {

    reCAPTCHASitekey = "6LdIFp4UAAAAAJE-vm9QJgt0irsH8pIN5W3xqMy-";
    regTz = /^\d{5,9}$/;
    regReplaceInvalidTz = /[^\d)]+/g

    isProd = false

    state = {
        tz: '',
        tzValid: null,
        tzTouched: false,
        formErrors: { tz: ''},
        verified: !this.props.isCommander && !config.isRecaptchaDisable ? false : true,
        isFormValid: false,
        showDisplaysClick: false,
        showDispalyConnect: false,
        showDisplayPhoneContact: false,
    }

    onVerify = () => {
        this.setState({
            verified: true
        }, () => { this.validateForm(); })
    };

    change = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value.replace(this.regReplaceInvalidTz, '')
        },
            () => { this.checkValidityForm(name, value) }
        )
        this.validateForm();
    }

    composeValidation = (value, ...fn) => fn ? fn.every(x => x(value)): false

    requiredField = (value) => {
        return value.trim() !== ''
    }

    isTzValidField = (value) => {
        return this.regTz.test(value);
    }

    checkValidityForm = (fieldName, value) => {
        let fieldValidationErrors = this.state.formErrors;
        let tzValid = this.state.tzValid;

        switch (fieldName) {
            case 'tz':
                tzValid = this.composeValidation(value, this.requiredField, this.isTzValidField) //&& checkValidityTz(value);
                
                if (value.trim().length > 1) {
                    this.setState({ tzTouched: true })
                    fieldValidationErrors.tz = (tzValid) ? '' : ' שם משתמש או סיסמא לא נכונים';
                }
                if (value.trim().length === 0) {
                    this.setState({ tzTouched: false })
                    fieldValidationErrors.tz = '';
                }
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            tzValid: tzValid
        }, this.validateForm);

    }
    validateForm() {
        this.setState({ isFormValid: this.state.tzValid });
    }

    submitForm = (event) => {
        event.preventDefault();
        if (this.state.isFormValid) {

            if (this.props.isCommander) {
                this.props.submitLogin(this.state.tz);
            }
            else {
                const recaptchaValue = !config.isRecaptchaDisable ? event.target['g-recaptcha-response'].value : null;
                this.props.submitLogin(this.state.tz);
            }
            this.setState({
                isFormValid: false,
            })
        }
    }

    handleUrl = (url) => {
        let isMobile = window.innerWidth < 768

        if (!isMobile || /android/i.test(navigator.userAgent)) {
            window.open(url, "_blank");
        }
        else {
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                window.location.assign(url, "_system", 'location=yes')
            }
            else {
                window.webkit.messageHandlers.appJSBridge.postMessage({
                    "actionType": "web",
                    "link": url
                });
            }

        }
    }
    

    componentDidMount(){
        
        if(!this.state.isInit){

            this.isProd = process.env.REACT_APP_BTN_FILE === "prod"
            this.props.setIsProd(this.isProd);
         
                axios.get('/api/Account/GetParams', { crossdomain: true })
                .then(res => {
                        const data = res.data;
                        this.setState({
                            showDisplaysClick: data.showDisplaysClick,
                            showDispalyConnect: data.showDispalyConnect,
                            showDisplayPhoneContact: data.showDisplayPhoneContact
    
                        });
                })
                .catch(error => {
                    console.log(error)
                });
        }  
  
    }


    render() {
        let url = this.props.loginExplanationLink.startsWith("http") ? this.props.loginExplanationLink : "https://" + this.props.loginExplanationLink //'https://www.prat.idf.il/'
        let urlConnect = "https://go.idf.il/support"
        let loginTitle = ''
        let classesTz = 'formGroup'
        let classesPassword = 'formGroup'
        let linkIdf = ''
        let linkSupportConnect = ''
        let contactPhone = ''
        let contactActivity = ''
        let helpText=''
        let smallMobile = false;
        smallMobile = (window.innerWidth < 375)

        if (this.props.loader) {
            return <Loader />
        }
        if (!this.props.isCommander) {
            loginTitle = this.props.loginTitle
            if(this.state.showDisplaysClick){
                linkIdf = (
                    <div>
                        <span className="linkIdf"> {this.props.loginExplanation}
                        <a className="link" onClick={() => {            
                            this.handleUrl(url)
                        }}
                        > {this.props.loginExplanationLink}</a>
                        </span>
                    </div>
                )
            }
                
            
            if(this.state.showDispalyConnect){
                    linkSupportConnect = (
                        <div className='phone-activity'>
                            <span className="linkIdf"> {this.props.loginExplainConnection}
                            <a className="link" onClick={() => {
                                this.handleUrl(urlConnect)
                            }}
                            > go.idf.il/support</a>
                            </span>
                        </div>
                    )
                } 
                if(this.state.showDisplayPhoneContact){
                    contactActivity = this.props.loginActivityTime
                    contactPhone = this.props.loginPhoneContact
                    helpText = this.props.loginHelpText  
                }    
            
        } else {
            loginTitle = 'כניסה לאיזור אחראי'
        }

        return (
            <section className="loginSection">
                <div className="container-fluid">
                    <div className="loginPage">
                        <div className="version"> {global.appVersion}</div>
                        {!this.props.isCommander && <img  className="icon-shacharLogo" src={require('../../../src/assets/img/shacharLogo.png')} alt="" />}
                        <h1 className="loginTitle">{loginTitle}</h1>
                            <h2 className="connectTitle">{this.props.loginTitleMyIdfConnect}</h2>
                        <div className="loginBox">
                            <form onSubmit={this.submitForm}>
                                {/* add class error to formGroup  */}
                                <div className={classesTz}>
                                    <input
                                        name="tz"
                                        autoComplete="off"
                                        className="formControl"
                                        type="tel"
                                        placeholder="תעודת זהות"
                                        onChange={e => this.change(e)}
                                        value={this.state.tz}
                                    />
                                    {/* <div className="errorMsg">{this.state.formErrors.tz}</div> */}
                                </div>
                                <div className="btnSendContainer">

                                    <button 
                                        className="btnGeneral send" 
                                        disabled={this.isProd && (!this.state.isFormValid || !this.state.verified)} 
                                        type='submit'>
                                            {this.isProd ? "כניסה" : "כניסה בדיקות"} </button>

                                
                                </div>
                                <div className="generalError">{this.props.serverErrorMsg}</div>
                                {!this.props.isCommander && !config.isRecaptchaDisable ?
                                    <div className="captchaContainer">
                                        <ReCAPTCHA {...(smallMobile ? { size: "compact" } : null)} sitekey={this.reCAPTCHASitekey} onChange={this.onVerify} />
                                    </div>
                                    : null}
                            </form>
                        </div>
                    </div>
                    <div className="linkIdfContainer">
                        {linkIdf}
                        {linkSupportConnect}
                        <div className='phone-activity'>
                            <div className="contactTextPhone">
                                <span>{contactPhone}</span>
                            </div>
                            <div className="contactTextTime">
                                <span >{contactActivity}</span>
                            </div>                     
                            <div className="helpText">
                                <span >{helpText}</span>
                            </div>            
                        </div>
                    </div>
                    
                    
                </div>
            </section>
        )
    }
}
export default withMsal(LoginSection);