import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';

class RemoveUserModal extends Component {

    state = {
        isModalShow: false
    }

    handleModalClose = () => {
        this.setState({
            isModalShow: false
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true
        })
    }

    removeUser = () =>{
        this.setState({
            isModalShow: false
        })
        this.props.removeUser()
    }

    render() {
        return (

            <Modal className="mainModal" show={this.state.isModalShow} onHide={this.handleModalClose} centered>
                <div className="warningBox">
                    <button type="button" className="xModal" onClick={this.handleModalClose}></button>
                    <img className="removeUserIcon" src="./img/removeUserIcon.png" alt="" />
                    {this.props.user ?
                        <div className="text removeUser">{`האם אתה בטוח שברצונך להסיר את ${this.props.user.firstName} ${this.props.user.lastName} מהקבוצה?`}</div>
                        : null}
                    <button type="button" className="btnGeneral send" onClick={this.removeUser}>כן</button>
                </div>
            </Modal>

        )
    }
}
export default RemoveUserModal