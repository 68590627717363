import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withMsal } from '@azure/msal-react';

import { AIDLib } from '../../auth/containers/PushNotification';
import * as actions from '../../store/actions/index';
import LoginSection from '../components/loginSection';
import { getText, idfMail, mobileAndTabletCheck, urls } from '../../shared/utility';
import {loginRequest, msalConfig} from '../../authConfig'
import { InteractionRequiredAuthError } from '@azure/msal-browser';

class Login extends Component {

    state = {
        errorText: '',
        isProd: false,
    }

    async login (tz, msalRes = null) {
        let content = {};
        try{
            content = this.props.contentManagment;
            const loginRequestObj = Object.assign(loginRequest, {
                redirectUri: window.location.origin
            })
            if(tz) {
                tz = `${tz}`.padStart(9, '0')
                Object.assign(loginRequestObj, {
                    loginHint: `${tz}${idfMail}`
                })
            }
            this.props.startingAuth()
            if(!msalRes && this.state.isProd) {
                msalRes = (await (mobileAndTabletCheck() ? 
                    this.props.msalContext.instance.loginRedirect(loginRequest): 
                    this.props.msalContext.instance.loginPopup(loginRequest)))
            }
            const res = this.state.isProd ? 
                await this.props.onAuth(tz, msalRes.idToken) : 
                await this.props.onAuthDev(tz);
            var SingletonMobileBridgeManager = AIDLib.MobileBridgeManager.GetInstance()
            SingletonMobileBridgeManager.GetTokenFromMobileBridgeAndSubmitAsync(function (response, tz) {
                //do hear;
            });
            if (res.isUserAuth) {
                this.props.history.push({
                    pathname: urls.hp,
                    state: { prevUrl: null}});
            }
            else{
                let doch1Err = getText((content || this.props.contentManagment || {}).loginErrors, 'UserNotExistError', 'ז')
                this.setState({errorText: doch1Err})
                this.logout()
            }
        }
        catch(error){
            let azureError = getText((content || this.props.contentManagment || {}).loginErrors, 'AzureError', 'ז')
            this.setState({errorText: azureError});
            console.error(error);
            this.props.finishAuthError()
        }
    }

    logout = () => {
        const msalContext = this.props.msalContext;
        this.props.onLogout(() =>  msalContext.instance.logout())
            .then(() => {
                if (window.location.pathname !== urls.login) {
                    this.props.history.push(urls.login);
                }
            })
    }

    silentSso(){
        this.props.msalContext.instance.ssoSilent(Object.assign({}, loginRequest, {
            redirectUri: window.location.origin
        }))
            .then(loginResponse => loginResponse && this.login(null, loginResponse))
            .catch(err => {
                console.log('ssoSilent failed')
                const account = this.props.msalContext.instance.getActiveAccount() || this.props.msalContext.instance.getAllAccounts()[0]

                if (err instanceof InteractionRequiredAuthError) {
                    this.props.msalContext.instance.acquireTokenSilent(Object.assign({}, loginRequest, {
                        redirectUri: window.location.origin,
                        account
                    }))
                        .then(loginResponse => loginResponse && this.login(null, loginResponse))
                        .catch(err => {
                            console.log('acquireTokenSilent failed')
                        })
                }
            })
    }

    render() {
        let loginTitle = 'כניסה לדו"ח 1'
        let explanationText = 'אין לך משתמש עדיין? גלשו אל '
        let explainConnection = 'לא מצליחים להתחבר? היכנסו ל - '
        let titleMyIdfConnect = 'התחברות באמצעות משתמש MY IDF'
        let explanationLink = 'prat.idf.il'
        let phoneContact = 'או באמצעות המוקד הטלפוני: 02-3761235'
        let activityTime = 'שעות פעילות המוקד: 8:30-17:30'
        let helpText = "זקוקים לסיוע בנושא דו״ח 1? יש לפנות לק\' משא\"ן היחידה"
        let errorMsg = this.state.errorText
        if (this.props.contentManagment){
            var title = getText(this.props.contentManagment.entry, 'EntryTitle', 'ז');
            var explanation = getText(this.props.contentManagment.entry, 'EntryExplanation', 'ז');
            var link = getText(this.props.contentManagment.entry, 'EntryExplanationLink', 'ז');
            loginTitle = title ? title : loginTitle;
            explanationText = explanation ? explanation : explanationText;
            explanationLink = link ? link : explanationLink;
        }
        return (
            <Fragment>
                <LoginSection
                    setIsProd= {(val) => this.setState({isProd: val})}
                    loader={this.props.loader}
                    isCommander={false}
                    serverErrorMsg={errorMsg}
                    submitLogin={(tz) => this.login(tz)}
                    loginTitle={loginTitle}
                    loginExplanation={explanationText}
                    loginExplanationLink={explanationLink}
                    loginTitleMyIdfConnect={titleMyIdfConnect}
                    loginExplainConnection={explainConnection}
                    loginPhoneContact={phoneContact}
                    loginActivityTime={activityTime}
                    loginHelpText={helpText}
                    login={this.login}
                    onAuthDev={this.props.onAuthDev}
                    history={this.props.history}
                />
            </Fragment>
        )
    }

    componentDidMount(){
        if(!this.props.isUserAuthenticated && !this.props.serverErrorMsg) this.silentSso();
    }
}

const mapStateToProps = state => {
    return {
        loader: state.auth.loader,
        serverErrorMsg: state.auth.error,
        isUserAuthenticated: state.auth.isUserAuth,
        contentManagment: state.layout.contentManagment
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onGetContentManagment: () => dispatch(actions.getContentManagment()),
        onAuth: (tz, token) => dispatch(actions.auth(tz, token)),
        onAuthDev: (tz) => dispatch(actions.authDev(tz)),
        onLogout: (cb) => dispatch(actions.logout(cb)),
        startingAuth: () => dispatch(actions.startingAuth()),
        finishAuthError: () => dispatch(actions.finishAuthError()),
    }
}

const LoginWithMsal = withMsal(Login)

export default connect(mapStateToProps, mapDispatchToProps)(LoginWithMsal);




 