import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as layoutActions from '../../store/actions/index';
import * as wizardAction from '../../store/actions/index';
import Timer from '../../shared/components/timer'
import Loader from '../../shared/components/loader'
import { getTime, urls, navigate, getText, isValidReportTime } from '../../shared/utility';
import ExplanationModal from '../../shared/components/explanationModal';
import BtnFutureReport from '../../shared/components/btnFutureReport';
import ChangeableBtn from '../../shared/components/ChangeableBtn';

class Hp extends Component {

    state = {
        titleText: '',
        timeLeftText: '',
        sendReport: '',
        hasGender: true,
        loader: false,
        firstEntry: false,
        NacsalEmerencyText: '',
        NacsalPracticeText: '' 
    }

    checkHasGender = () => {
        if (!this.props.gender) {
            this.setState({ hasGender: false })
        }
    }

    loadAllData = () => {
        this.checkHasGender()
        this.loadText()
        this.props.onHideWizardHeader();
        //click on change Report from finish page
        
        this.props.onFetchWizard();
        this.props.onFecthReportedData()
            .then((reportedData) => {
                this.setState({ loader: true })
                if (!this.state.hasGender) {
                    this.loadText()
                    this.setState({ hasGender: true })
                }
                navigate(reportedData, this.props, this.props.isChangeReport,false, this.state.firstEntry)
            })
    }

    componentDidMount() {
        this.loadAllData()
        const { location } = this.props
        if(!sessionStorage.getItem("popup") || location.state) {
            this.setState({firstEntry: true});
            sessionStorage.setItem("popup", "true");
        }
    }

    loadText = () => {
        this.setState({
            titleText: getText(this.props.contentManagment.mainScreen, 'title', this.props.gender),
            timeLeftText: getText(this.props.contentManagment.mainScreen, 'timeLeft', this.props.gender),
            sendReport: getText(this.props.contentManagment.mainScreen, 'sendReport', this.props.gender),
            NacsalEmerencyText: getText(this.props.contentManagment.nacsalTexts, 'NacsalEmerency', this.props.gender),
            NacsalPracticeText: getText(this.props.contentManagment.nacsalTexts, 'NacsalPractice', this.props.gender)
        })
    }

    baseLocationHandler = () => {
        if (this.props && this.props.timeToReport && this.props.reportedData && this.props.reportedData.endReport && isValidReportTime(this.props.reportedData)) {
            this.props.onSaveSelection({
                primaryCode: this.props.basePrimarie.statusCode,
                primaryText: this.props.basePrimarie.statusDescription,
                icon: this.props.basePrimarie.icon,
                secondaryCode: this.props.basePrimarie.secondaries[0].statusCode,
                secondaryText: this.props.basePrimarie.secondaries[0].statusDescription
            })
            .then (() => {
                if(this.props.isSaveSelection){

                    this.props.history.push(urls.finish)
                }
                else
                    window.location.reload();

            })
        }
        else {
            window.location.reload();
        }
    }

    goToPrimaries = () => {
        if (this.props.reportedData && this.props.reportedData.endReport && isValidReportTime(this.props.reportedData)) {
            this.props.history.push(urls.primaries)
        }
        else {
            this.loadAllData()
        }
    }

    goToCalendar = () => {
        this.props.history.push(urls.futureReport)
    }

    goToEmergencyPrimaries = () => {
        this.props.history.push({
            pathname: urls.primaries,
            state: { prevUrl: "emergency"}});
    }

    componentWillUnmount(){
        this.props.onChangeReport(false)
    }

    goToFinish = () => {
        navigate(this.props.reportedData, this.props, false, true)
    }

    render() {
        let baseBtn = null;
        let disabled = true;
        let locationIcon = 'img/locationBtn.png';
        let explanationPopUp = '';
        let emergencyReport = '';
        let emergencyBtn = '';
        let isEmergenctActive = this.props.showEmergencyBtn || this.props.showEmergencyPractice
        let emergencyIcon = <img className="icon" src='img/emergencyBtn.png' alt=""/>
        let emergencyPracticeIcon = <img className="icon" src='img/emergencyPractice.png' alt=""/>
        let changeableUrl = getText(this.props.contentManagment.changeableBtn, 'link', this.props.gender)
        let btnName = getText(this.props.contentManagment.changeableBtn, 'btnName', this.props.gender)

        if (this.props.basePrimarie && this.props.reportedData && this.props.reportedData.endReport && isValidReportTime(this.props.reportedData)) {
            baseBtn = (
                <button type="button" className="locationStatusBtn bounceIn" onClick={this.baseLocationHandler}>
                    <div className="locationStatusInner">
                        {(this.props.basePrimarie.icon) ? (
                            <div className="icon">
                                <img src={`/${this.props.basePrimarie.icon}`} alt="" />
                            </div>
                        ) : null}
                        <span className="text">אני <br />בבסיס</span>
                    </div>

                </button>
            )
        }

        if (this.props.primaries.length) {
            disabled = false
        }



        if (this.props.loader || !this.state.hasGender || !this.state.loader) {
            return <Loader />
        }

        if(this.state.firstEntry  && this.props.showExplanationPopUp){
                explanationPopUp = (<ExplanationModal 
                                    title = {getText(this.props.contentManagment.updates, 'explanationTitle', this.props.gender)}
                                    content = {getText(this.props.contentManagment.updates, 'explanationContent', this.props.gender)}
                                    link = {getText(this.props.contentManagment.updates, 'explanationLink', this.props.gender)}/>)

        }


        if (this.props.showEmergencyBtn)
        emergencyBtn = <button type="button" className=" btnGeneral welcome emergencyBtn" onClick={() => this.goToEmergencyPrimaries()}> {emergencyIcon} {this.state.NacsalEmerencyText}</button>;
        else if (this.props.showEmergencyPractice)
            emergencyBtn = <button type="button" className="btnGeneral welcome emergencyBtn practice" onClick={() => this.goToEmergencyPrimaries()}> {emergencyPracticeIcon} {this.state.NacsalPracticeText}</button>;
        
        if (isEmergenctActive && this.props.reportedData.emergencyMainStatus && this.props.reportedData.emergencySecondaryStatus){
            emergencyReport = (
                <Fragment>
                    <div className="finishEmergency">
                        <div className="icon "></div>
                        דיווח חירום נשלח בהצלחה ב
                        <span>{getTime(this.props.reportedData.emergencyUpdateOn)}</span>
                    </div>
                </Fragment>
            )
        }

        return (

            <section className="welcomeSection">
                <div className="container-fluid">
                    <div className="welcomeContent">
                    {explanationPopUp}
                        <div>
                            <div className="welcomeUser">שלום {this.props.userName}</div>
                            <h1 className="welcomeTitle">{this.state.titleText}</h1>
                            {
                                this.props.timeToReport ? (
                                    <div className="timeLeft">{this.state.timeLeftText} <Timer goToFinish={this.goToFinish} timeToReport={this.props.timeToReport} />  {this.state.sendReport}</div>
                                ) : null
                            }
                            {emergencyReport}
                        </div>
                        {this.props.reportedData && this.props.reportedData.endReport && isValidReportTime(this.props.reportedData) ? baseBtn : null}
                        <button type="button" disabled={disabled} className="btnGeneral welcome" onClick={() => this.goToPrimaries()}>
                            <img className="icon" src={locationIcon} alt=""/>
                            אני במקום אחר </button>
                        <BtnFutureReport 
                            className="btnGeneral welcome"
                            disableFutureReport = {this.props.disableFutureReport}
                            fetchBtnMode = {() => this.props.onFetchFutureMode()}
                        />
                        <ChangeableBtn 
                            className="btnGeneral welcome"
                            disableChangeabltBtn = {this.props.disableChangeabltBtn}
                            disableCoronaIcon = {this.props.disableCoronaIcon}
                            btnText = {btnName}
                            url = {changeableUrl}
                            onAddClickToCounter = {() => this.props.onAddClickToCounter()}
                        /> 
                        {emergencyBtn}    
                    </div>
                </div>
            </section>
        )
    }
}


const mapStateToProps = state => {
    return {
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        reportedData: state.wizard.reportedData,
        userName: state.wizard.userName,
        timeToReport: state.wizard.timeToReport,
        primaries: state.wizard.primaries,
        basePrimarie: state.wizard.basePrimarie,
        loader: state.wizard.loader,
        isChangeReport: state.wizard.isChangeReport,
        isSaveSelection: state.wizard.isSaveSelection,
        showExplanationPopUp: state.wizard.showExplanationPopUp,
        showEmergencyBtn: state.wizard.showEmergencyBtn,
        showEmergencyPractice: state.wizard.showEmergencyPractice,
        disableFutureReport: state.wizard.disableFutureReport,
        disableChangeabltBtn: state.wizard.disableChangeabltBtn,
        disableCoronaIcon: state.wizard.disableCoronaIcon
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetContentManagment: () => dispatch(layoutActions.getContentManagment()),
        onSaveSelection: (selection) => dispatch(wizardAction.storeSelection(selection)),
        onHideWizardHeader: () => dispatch(layoutActions.hideWizardHeader()),
        onFetchWizard: () => dispatch(wizardAction.fetchWizard()),
        onFecthReportedData: () => dispatch(wizardAction.fecthReportedData()),
        onChangeReport: (bool) => dispatch(wizardAction.changeReport(bool)),
        onFetchFutureMode: () =>dispatch(wizardAction.fetchFutureMode()),
        onAddClickToCounter: () => wizardAction.addClickToCounter()
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Hp);
