import React, { Component } from "react";
import WarningModal from "./warningModal";

class ChangeableBtn extends Component {
  notification = ["השירות אינו פעיל"]

  changeableBtnClick = (changeableUrl) => {
    const { navigator, plateform, osDetails } = window.device;

    this.props.onAddClickToCounter();

    if (plateform === "mobile" && navigator === "application") {
      if (osDetails === "android") {
        window.Android && window.Android.OpenInBrowser(changeableUrl)
      } else if (typeof window.webkit.messageHandlers !== "undefined" && typeof window.webkit.messageHandlers.AppJSBridge !== "undefined") { //case IOS app
        var message = {
          "actionType": "web",
          "link": changeableUrl
        }
        window.webkit.messageHandlers.AppJSBridge.postMessage(message);
      }
    } else {
      window.open(changeableUrl, "_blank");
    }

  }

  render() {
    let coronaIcon = 'img/corona.png';
    return (
      <div>
        {!this.props.disableChangeabltBtn && (
          <button className={this.props.className} onClick={() => this.changeableBtnClick(this.props.url)}>
            {!this.props.disableCoronaIcon && (<img className="icon" src={coronaIcon} alt="" />)}
            <a className="link"
            > {this.props.btnText} </a>
          </button>
        )}

        {/* <WarningModal
          notifications={this.notification}
          ref="warningModal"
        /> */}
      </div>
    );
  }
}

export default ChangeableBtn;
