import * as actionTypes from './actionTypes'
import { checkIsMobile } from '../../shared/utility'
import axios from 'axios'


export const showWizardHeader = () => {
    return {
        type: actionTypes.SHOW_WIZARD_HEADER
    }
}

export const hideWizardHeader = () => {
    return {
        type: actionTypes.HIDE_WIZARD_HEADER
    }
}

export const loadIsMobile = () => {
    return {
        type: actionTypes.CHECK_IS_MOBILE,
        isMobile: checkIsMobile()
    }
}

export const getContentManagment = () => {
    return dispatch => {
        axios.get('/api/TextContent/GetAllTexts')
            .then(res => {
                dispatch(getContentManagmentSuccess(res.data));
            })
            .catch(error => {
                dispatch(getContentManagmentFailed());
            });
    }
}

const getContentManagmentSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENT_MANAGMENT_SUCCESS,
        contentManagment: data
    }
}

const getContentManagmentFailed = (err) => {
    console.log(err)
}
