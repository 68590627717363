import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import * as authAction from '../store/actions/index';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { urls, getText } from '../shared/utility';
import Modal from 'react-bootstrap/Modal';
import { withMsal } from '@azure/msal-react';
class Header extends Component {

    dispose = null;
    state = {
        isModalShow: false,
        mainTitleText: null,
    }

    onBackButtonEvent = () => {
        this.setState({
            isModalShow: false,
        })
    }

    componentDidMount() {
        window.onpopstate = this.onBackButtonEvent;
        this.dispose = this.props.history.listen(() => {
            this.setState({ isModalShow: false });
        });
        if (this.props.contentManagment) {
            this.setState({
                mainTitleText: getText(this.props.contentManagment.textsCommon, 'DochOne', this.props.gender),
            })
        }
    }

    componentWillUnmount() {
        if (this.dispose) {
            this.dispose();
        }
    }

    handleClose() {
        this.setState({ isModalShow: false });
    }

    handleShow() {
        this.setState({ isModalShow: true });
    }

    prevStep = () => {
        const location = this.props.location.pathname.split('/')
        if (location[1] === urls.uploadFiles.replace(/[/]/g, '') && this.props.dateToUploadFilesAndDates) {
            let d = new Date(this.props.dateToUploadFilesAndDates.date)
            this.props.history.push({
                pathname: urls.historyPrat,
                state: { m: d.getMonth() + 1, y: d.getFullYear() }
            })
        }
        else {
            this.props.history.goBack();
        }
    }

    closeWizard = () => {
        const location = this.props.location.pathname.split('/')
        switch (location[1]) {
            case urls.commander.replace(/[/]/g, ''):
                this.props.history.push({
                    pathname: urls.commander,
                    state: { prevUrl: null }
                })
                //this.props.history.push(urls.commander);
                break;
            case urls.historyPrat.replace(/[/]/g, ''):
                if (location.length > 2 && location[2] === "user") {
                    //this.props.history.push(urls.commander);
                    this.props.history.push({
                        pathname: urls.commander,
                        state: { prevUrl: null }
                    })
                }
                else {
                    this.props.history.push(urls.historyGroups);
                }
                break;
            default:
                this.props.history.push(urls.hp);
        }
    }

    goToMainPage = () => {
        this.props.history.push(urls.hp);
    }

    logout = () => {
        const msalContext = this.props.msalContext;
        this.props.onLogout(() => {
            const logoutRequest = {}
            const currentAccounts = msalContext.accounts;
            if (currentAccounts && currentAccounts.length > 0) {
                Object.assign(logoutRequest, {
                    account: currentAccounts[0],
                });
            }

            return (logoutRequest && Object.keys(logoutRequest).length > 0 && Object.getPrototypeOf(logoutRequest) === Object.prototype) ? 
                        msalContext.instance.logout(logoutRequest): 
                        new Promise((res, _) => res())
        })
            .then(() => {
                if (window.location.pathname !== urls.login) {
                    this.props.history.push(urls.login);
                }
            })
    }

    render() {
        let header = null;
        const isSetting = this.props.location.pathname.split('/')[1] === urls.settings.replace(/[/]/g, '')
        const isFutureReport = this.props.location.pathname.split('/')[1] === urls.futureReport.replace(/[/]/g, '')
        if (this.props.isWizardHeader) {
            header = (
                <Fragment>
                    <button type="button" className="closeBtn" onClick={this.closeWizard}></button>
                    {isSetting && <div className="pageName">הגדרות</div>}
                    {isFutureReport && <div className="pageName">
                        <button type="button" className="backBtn" onClick={this.goToMainPage}></button>
                        דיווחים עתידיים
                    </div>}
                    {!isSetting && !isFutureReport && <button type="button" className="backBtn" onClick={this.prevStep}></button>}
                </Fragment>
            )
        } else {
            header = (
                <Fragment>
                    <Link to={urls.hp} className="appTitle">{this.state.mainTitleText}</Link>
                    <button type="button" className="menuBtn" onClick={() => this.handleShow()}>
                        <img src="/img/menuBtn.png" alt="" />
                    </button>
                </Fragment>

            )
        }

        return (
            <Fragment>
                {this.props.location.pathname !== urls.login ? <div className="headerSection">
                    <header className="header">
                        {header}
                        <Modal className="mainMenuModal" show={this.state.isModalShow} onHide={() => this.handleClose()}>
                            <button className="closeMenuBtn" type="button" onClick={() => this.handleClose()}></button>

                            <div className="mainMenu">
                                <ul className="mainMenuList">
                                    {
                                        this.props.isCommanderAuth ? null : <li className="item">
                                            <Link to={urls.loginCommander} className="link">
                                                <img src="/img/report.png" alt="" />
                                                <span className="text">כניסת מפקד</span>
                                            </Link>
                                        </li>
                                    }

                                    <li className="item">
                                        <Link to={urls.hp} className="link">
                                            <img src="/img/report.png" alt="" />
                                            <span className="text">דיווח נוכחות</span>
                                        </Link>
                                    </li>
                                    <li className="item">
                                        <Link to={urls.historyPrat} className="link">
                                            <img src="img/history.png" alt="" />
                                            <span className="text">היסטוריית דיווחים</span>
                                        </Link>
                                    </li>
                                    {
                                        this.props.isCommanderAuth ? <>
                                            <li className="item">
                                                <Link to={{ pathname: urls.commander, state: { prevUrl: this.props.location.pathname } }} className="link">
                                                    <img src="/img/groupsReport.png" alt="" />
                                                    <span className="text">דיווח נוכחות עבור קבוצות</span>
                                                </Link>
                                            </li>
                                            <li className="item">
                                                <Link to={urls.historyGroups} className="link">
                                                    <img src="/img/groupshistory.png" alt="" />
                                                    <span className="text">סיכום יומי</span>
                                                </Link>
                                            </li>
                                        </> : null
                                    }
                                    <li className="item">
                                        <Link to={urls.settings} className="link">
                                            <img src="/img/setting.png" alt="" />
                                            <span className="text">הגדרות</span>
                                        </Link>
                                    </li>
                                    <li className="item">
                                        <button type="button" className="link" onClick={this.logout}>
                                            <img src="/img/logout.png" alt="" />
                                            <span className="text">התנתקות</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </Modal>

                    </header>
                </div> : null}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        contentManagment: state.layout.contentManagment,
        gender: state.wizard.gender,
        currentStep: state.wizard.currentStep,
        isUserAuth: state.auth.isUserAuth,
        isCommanderAuth: state.auth.isCommanderAuth,
        isWizardHeader: state.layout.isWizardHeader,
        dateToUploadFilesAndDates: state.history.dateToUploadFiles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: (cb) => dispatch(authAction.logout(cb)),
    }
}
const HeaderithMsal = withMsal(Header)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderithMsal));