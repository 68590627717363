
import React, { Component } from 'react'

let globalInterval; 
class Timer extends Component {

    state = {
        timeToReport: null,
        timer: {
            total: null,
            hours: null,
            minutes: null,
            // seconds: null
        },
    }

    componentDidMount(){
        if(this.state.timeToReport){
            this.startTik();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // time to report is finished
        if( ( this.state.timer !== prevState.timer ) && this.props.timeToReport && this.state.timer && ((this.state.timer.hours != null || this.state.timer.minutes != null) && this.state.timer.total <= 0)) {
            this.props.goToFinish()           
        }
    }  

    static getDerivedStateFromProps(nextProps, prevState) {
       return {timeToReport:nextProps.timeToReport}
    }

    myCallback = (dataFromChild) => {
        this.setState({ listDataFromChild: dataFromChild });
    }

    startTik = () => {
        let timeToReport = (new Date(this.props.timeToReport)).getTime();
        let currentDate = Date.now();
        if (timeToReport - currentDate > 0) {
            this.updateClock(timeToReport - currentDate)
            this.initializeClock(timeToReport - currentDate)
        }
    }

    updateClock = (endTime) => {
        let time = this.getTimeRemaining(endTime);
        this.setState({
            timer: {
                total: time.total,
                hours: time.hours,
                minutes: time.minutes,
                // seconds: time.seconds
            }
        })
    }
    initializeClock = (endtime) => {
        globalInterval = setInterval(() => {
            let time = this.getTimeRemaining(endtime);
            endtime = endtime - 1000
            // console.log('globalInterval', globalInterval)
            this.setState({
                timer: {
                    total: time.total,
                    hours: time.hours,
                    minutes: time.minutes,
                    // seconds: time.seconds
                }
            })
            if (time.total <= 0) {
                clearInterval(globalInterval);
            }
        }, 1000);

    }
    getTimeRemaining = (time) => {
        // var seconds = Math.floor((time / 1000) % 60);
        var minutes = Math.floor((time / 1000 / 60) % 60);
        var hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        return {
            total: time,
            hours: hours,
            minutes: minutes,
            // seconds: seconds
        };
    }
    componentWillUnmount() {
        clearInterval(globalInterval);
        // console.log('componentWillUnmount', globalInterval)
    }
    render() {
        
        let timer = '00:00';
        if (this.state.timer.hours !== null || this.state.timer.minutes !== null) {
            // :${('0'+ this.state.timer.seconds).slice(-2)}   ;
            timer = `${('0' + this.state.timer.hours).slice(-2)}:${('0' + this.state.timer.minutes).slice(-2)}`;
            if (this.state.timer.total <= 0) { timer = ''; }
        }

        return (
            <span>{timer}</span>
        )
    }
}
export default Timer;