export {
    showLoader,
    setWizard,
    fetchWizardFailed,
    fetchWizard,
    saveSelection,
    storeSelection,
    setPrimarieSelection,
    fecthReportedData,
    fetchFutureMode,
    changeReport,
    storeFullSelection,
    uploadFiles,
    setChangeReport,
    addClickToCounter
  
} from './wizard';

export {
    auth,
    authCommander,
    getUserAuth,
    logout,
    logoutCommander,
    authFail,
    authDev,
    startingAuth,
    finishAuthError
} from './auth';

export {
    fetchCommander,
    postSelection,
    fetchOtherStatusesByMi,
    updateOtherStatus,
    toggleUser,
    fetchTabGroup,
    userToUpdate,
    searchUser,
    storeFilters,
    filterUsers,
    removeFilter,
    removeAllFilters,
    fetchStatistic,
    toggleFavorite,
    fetchGroupsAuthIndication,
    removeUserFromGroup,
    getAllStatuses,
    submitGroupNameForm,
    updateNote,
    setActiveTab,
    clearStateCommander,
    checkIsSubmittedToday,
    setIsValidTimeToReport,
    changeEmergencyPracticeMode,
} from './commander';

export {
    showWizardHeader,
    hideWizardHeader,
    loadIsMobile,
    getContentManagment,
} from './layout'

export{
    fetchHistoryPrat,
    setDateToUploadFiles,
    fetchHistoryGroup,
    fetchHistoryTabGroup,
    setPrevDay,
    setNextDay,
    setActiveUser,
    storeFilesHistory,
    updateFilesAndDates
} from './history'

export{
    fetchSetting,
    fetchCommaderSetting
} from './setting'

export{
    fetchFutureReports,
    deleteFutureReports
} from './future'