import React, { Component } from 'react';
import { getFullDate } from '../../shared/utility';
import Modal from 'react-bootstrap/Modal';

class DeleteFutureModal extends Component {

    state = {
        isModalShow: false,
        ios: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    }

    closeModal = () => {
        this.setState({
            isModalShow: false
        })
    }

    showModal = () => {
        this.setState({
            isModalShow: true
        })
    }

    deleteReport = (date) => {
        this.props.deleteReport(date);
        this.setState({
            isModalShow: false
        })
    }


    render() {
        let date = getFullDate(this.props.date);
        let item =
            <div className="futureDetails">
                <div className="title">האם אתה בטוח שברצונך למחוק את הדיווח המתוזמן לתאריך {date}? </div>
                <button type="button" className="btnGeneral send" onClick={() => this.deleteReport(date)} >אישור ומחיקה</button>
                <button type="button" className="link" onClick={this.closeModal}>ביטול וחזרה</button>
            </div>
        return (
                        
            <Modal className="mainModal" show={this.state.isModalShow} onHide={this.closeModal} centered>
                <div className="futureModalBox">
                    <button type="button" className="xBtn" onClick={this.closeModal}></button>    
                    {item}         
                </div>
            </Modal>
        )
    }
}
export default DeleteFutureModal;




