/**/
import axios from 'axios'
import * as actionTypes from './actionTypes';

export const showLoader = () => {
    return {
        type: actionTypes.SHOW_LOADER_AUTH
    }
}

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
}

export const authSuccess = (res) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        isUserAuth: res.data.isUserAuth,
        isCommanderAuth: res.data.isCommanderAuth,
        error: res.data.error,
    };
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const startingAuth = () => {
    return dispatch => {
        dispatch(showLoader());
    }
}

export const finishAuthError = () => {
    return dispatch => {
        dispatch(authFail());
    }
}

export const auth = (tz, token) => {
    return dispatch => {
        dispatch(authStart());
        axios.defaults.withCredentials = true;

        const request = axios.get('/api/account/login', {
            headers: {
                'Authorization': `${token}`
            }
        })
        return new Promise((resolve, reject) => {
            request
                .then(res => {
                    dispatch(resetStore());
                    dispatch(authSuccess(res));
                    resolve(res.data);
                })
                .catch(err => {
                    let errorText = err;
                    if(typeof err !== 'string'){
                        const {response, message} = err
                        errorText = response ? response.data : message
                    }
                    dispatch(authFail(errorText));
                    reject(err);
                })
        })

    };
}

export const authDev = (tz) => {
    return dispatch => {
        dispatch(authStart());
        axios.defaults.withCredentials = true;

        const request = axios.get(`/api/account/loginDev?tz=${tz}`)
        return new Promise((resolve, reject) => {
            request
                .then(res => {
                    dispatch(resetStore());
                    dispatch(authSuccess(res));
                    resolve(res.data);
                })
                .catch(err => {
                    let errorText = err;
                    if(typeof err !== 'string'){
                        const {response, message} = err
                        errorText = response && typeof response.data === 'string'? response.data : message
                    }
                    dispatch(authFail());
                    reject(err);
                })
        })

    };
}

export const authCommander = ( isUserAuth ) => {
    
    return dispatch => {
        dispatch(authStart());
        return new Promise((resolve, reject) => {
            axios.post('/api/account/loginCommander')
                .then(res => {
                    res.data.isUserAuth = isUserAuth
                    dispatch(authSuccess(res));
                    resolve(res.data);
                })
                .catch(err => {
                    dispatch(authFail(err));
                    reject(err);
                })
        })

    };
}

export const getUserAuth = () => {
    return dispatch => {
        axios.defaults.withCredentials = true;
        return new Promise((resolve, reject) => {
            axios.get('/api/account/getUser')
                .then(res => {
                    dispatch(getUserAuthSuccess(res));
                    resolve(res.data);
                })
                .catch(err => {
                    dispatch(getUserAuthFail(err));
                    reject(err);
                })
        })
    }
}

export const getUserAuthSuccess = (res) => {
    return {
        type: actionTypes.GET_USER_AUTH_SUCCESS,
        isUserAuth: res.data.isUserAuth,
        isCommanderAuth: res.data.isCommanderAuth
    }
}
export const getUserAuthFail = (err) => {
    return {
        type: actionTypes.GET_USER_AUTH_FAIL,
        loader: false
    }
}

export const logout = (cb) => {//cb => Call Back with promise
    return dispatch => {
        const errorLogout = reject => err => {
            dispatch(logoutFail(err));
            reject(err);
        }
        dispatch(showLoader());
        return new Promise((resolve, reject) => {
            axios.get('/api/account/logout')
                .then(res => {
                    cb()
                        .then(() => {
                            dispatch(logoutSuccess());
                            resolve(res.data);
                        })
                        .catch(errorLogout(reject))
                })
                .catch(errorLogout(reject))
        })
        
    }
}

export const resetStore = () => {
    return {
        type: actionTypes.RESET_STORE
    }
}

export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    }
}

export const logoutFail = () => {
    return {
        type: actionTypes.LOGOUT_FAIL
    }
}

export const logoutCommander = () => {
    return {
        type: actionTypes.LOGOUT_COMMANDER
    }
}