
import React from 'react'
import { Link } from 'react-router-dom'
const ServerError = () => {

    return (
        <section className="serverErrorSection">
            <div className="circleBox">
                <figure>
                    <img src="/img/b.png" alt="" />
                </figure>
                <div className="text">
                    <div>מצטערים,</div>
                    <div>יש לנו תקלה קטנה</div>
                </div>
            </div>
            <div className="linkContainer">
                <Link to="/" className="btnGeneral send">חזור להתחלה</Link>
            </div>
            
        </section>

    )
}

export default ServerError;